import React from "react";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { auth, cicassService } from "../../../_services";
import { withTranslation } from "react-i18next";
import "./profile.css";

class Profile extends React.Component {
	_isMounted = false;
	grid = null;

	constructor(props) {
		super(props);
		const { t } = this.props;
		this.position = { X: "Right", Y: "Bottom" };
		this.state = {
			myData: undefined,
			visibleOnGrid: false,
			didMountCompleted: false,
			isCicass: false,
			showConfirmDialog: false,
			oldPwd: "",
			newPwd: "",
			confirmPwd: "",
			toastInfo: {
				title: "",
				content: "Succesfully",
				cssClass: "e-toast-success",
				icon: "e-success toast-icons",
			},
		};
		this.confirmButton = [
			{
				click: () => {
					this.saveAndClosDialog();
				},
				buttonModel: { content: t("yes"), isPrimary: true },
			},
			{
				click: () => {
					this.setState({ showConfirmDialog: false });
				},
				buttonModel: { content: t("no") },
			},
		];
	}

	async saveAndClosDialog() {
		let { oldPwd, newPwd } = this.state;
		let result = await auth.AuthChangePassword(oldPwd, newPwd);
		document.getElementById("oldPwd").value = "";
		document.getElementById("newPwd").value = "";
		document.getElementById("confirmPwd").value = "";
		document.getElementById("resetBtn").disabled = true;
		this.setToastInfo("Success", this.props.t("toastSuccess"));
		this.setState({
			showConfirmDialog: false,
			oldPwd: "",
			newPwd: "",
			confirmPwd: "",
		});
	}

	closeDialog() {
		this.setState({ showConfirmDialog: false });
	}

	async componentDidMount() {
		this._isMounted = true;
		let { data: myData } = await auth.getAnaData();
		myData.userRole = auth.getUserRole();
		if (this._isMounted) {
			this.setState({ didMountCompleted: true, myData });
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	setToastInfo(title, errorMsg) {
		let { toastInfo } = this.state;
		switch (title) {
			case "Warning":
				toastInfo.title = "Warning";
				toastInfo.content = errorMsg;
				toastInfo.cssClass = "e-toast-warning";
				toastInfo.icon = "e-warning toast-icons";
				break;
			case "Success":
				toastInfo.title = "Success";
				toastInfo.content = errorMsg;
				toastInfo.cssClass = "e-toast-success";
				toastInfo.icon = "e-success toast-icons";
				break;
			case "Error":
				toastInfo.title = "Error";
				toastInfo.content = errorMsg;
				toastInfo.cssClass = "e-toast-danger";
				toastInfo.icon = "e-error toast-icons";
				break;
			case "Information":
				toastInfo.title = "Information";
				toastInfo.content = errorMsg;
				toastInfo.cssClass = "e-toast-info";
				toastInfo.icon = "e-info toast-icons";
				break;
		}
		this.setState({ toastInfo });
		this.toastObj.show();
		return;
	}

	confirmSave() {
		let { myData } = this.state;
		delete myData.userRole;
		myData.email = document.getElementById("email").value;
		myData.home = document.getElementById("home").value;
		myData.mobile = document.getElementById("mobile").value;
		auth
			.updateProfile(myData)
			.then((res) => {
				if (res.data)
					this.setToastInfo("Success", this.props.t("toastSuccess"));
				else this.setToastInfo("Error", "U: " + res.statusText);
			})
			.catch((err) => {
				this.setToastInfo("Error", "U: " + err.message);
			});
	}

	openDialog() {
		let { oldPwd, newPwd } = this.state;
		if (oldPwd === "" || newPwd === "") {
			//console.log("un nullo");
			document.getElementById("resetBtn").disabled = true;
		} else {
			this.setState({ showConfirmDialog: true });
		}
	}

	onPwdInputChange(args) {
		let oldPwd =
			document.getElementById("oldPwd") == null
				? ""
				: document.getElementById("oldPwd").value;
		let newPwd =
			document.getElementById("newPwd") == null
				? ""
				: document.getElementById("newPwd").value;
		let confirmPwd =
			document.getElementById("confirmPwd") == null
				? ""
				: document.getElementById("confirmPwd").value;
		//console.log("'"+oldPwd+"'", "'"+newPwd+"'", "'"+confirmPwd+"'");
		if (oldPwd.length > 8 && newPwd.length > 8 && confirmPwd === newPwd) {
			document.getElementById("resetBtn").disabled = false;
			this.setState({ oldPwd, newPwd, confirmPwd });
		} else {
			document.getElementById("resetBtn").disabled = true;
		}
	}

	render() {
		const { myData, didMountCompleted, toastInfo } = this.state;
		const { t } = this.props;
		return (
			<div
				className="control-pane"
				id="target"
			>
				{myData != undefined && (
					<div className="control-section input-content-wrapper">
						<div className="row custom-margin">
							<div className="col">
								<b>{t("lblAnaUserData")}</b>
							</div>
						</div>
						<div className="row custom-margin custom-padding-5">
							<div className="col">
								<TextBoxComponent
									placeholder={t("name")}
									floatLabelType="Auto"
									disabled
									value={myData.name}
								/>
							</div>
							<div className="col">
								<TextBoxComponent
									placeholder={t("surname")}
									floatLabelType="Auto"
									disabled
									value={myData.surname}
								/>
							</div>
							<div className="col">
								<TextBoxComponent
									placeholder={t("authRoleId")}
									floatLabelType="Auto"
									disabled
									value={myData.userRole}
								/>
							</div>
						</div>
						<div className="row custom-margin custom-padding-5">
							<div className="col">
								<TextBoxComponent
									id="email"
									type="email"
									placeholder={t("email")}
									floatLabelType="Auto"
									value={myData.email}
								/>
							</div>
							<div className="col">
								<TextBoxComponent
									id="home"
									placeholder={t("homeNr")}
									floatLabelType="Auto"
									value={myData.home}
								/>
							</div>
							<div className="col">
								<TextBoxComponent
									id="mobile"
									placeholder={t("mobile")}
									floatLabelType="Auto"
									value={myData.mobile}
								/>
							</div>
						</div>
						<div className="row custom-margin">
							<div className="col">
								<ButtonComponent
									cssClass="e-success e-outline"
									onClick={this.confirmSave.bind(this)}
								>
									{t("btnSave")}
								</ButtonComponent>
							</div>
						</div>
						<div className="row custom-margin">
							<div className="col">
								<b>{t("lblChangePwd")} *</b>
							</div>
						</div>
						<div className="row custom-margin">
							<div className="col">
								<TextBoxComponent
									id="oldPwd"
									type="password"
									placeholder={t("old")}
									onChange={this.onPwdInputChange.bind(this)}
									floatLabelType="Auto"
								/>
							</div>
							<div className="col">
								<TextBoxComponent
									id="newPwd"
									type="password"
									placeholder={t("new")}
									onChange={this.onPwdInputChange.bind(this)}
									floatLabelType="Auto"
								/>
							</div>
							<div className="col">
								<TextBoxComponent
									id="confirmPwd"
									type="password"
									placeholder={t("confirmNew")}
									onChange={this.onPwdInputChange.bind(this)}
									floatLabelType="Auto"
								/>
							</div>
						</div>
						<div className="row custom-margin">
							<div className="col">
								<cite>{t("lblPwdCriteria")}</cite>
							</div>
						</div>
						<div className="row custom-margin">
							<div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
								<ButtonComponent
									id="resetBtn"
									cssClass="e-warning e-outline"
									onClick={this.openDialog.bind(this)}
								>
									{t("btnChangePwd")}
								</ButtonComponent>
								<DialogComponent
									id="confirmDialog"
									isModal={true}
									header={t("lblChangePwd")}
									visible={this.state.showConfirmDialog}
									showCloseIcon={true}
									width="400px"
									height="150px"
									content={t("confirmQuestion")}
									ref={(dialog) => (this.confirmDialogInstance = dialog)}
									close={this.closeDialog.bind(this)}
									target="#target"
									buttons={this.confirmButton}
								></DialogComponent>
							</div>
						</div>
					</div>
				)}
				<ToastComponent
					ref={(toast) => {
						this.toastObj = toast;
					}}
					id="toast_default"
					position={this.position}
					title={toastInfo.title}
					content={toastInfo.content}
					cssClass={toastInfo.cssClass}
					icon={toastInfo.icon}
				></ToastComponent>
			</div>
		);
	}
}
export default withTranslation()(Profile);
