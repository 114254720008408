import React, { useState, useRef, useEffect } from "react";
import {
	GridComponent,
	ColumnDirective,
	ColumnsDirective,
	Page,
	Inject,
	Resize,
	Edit,
	CommandColumn,
	ForeignKey,
	Filter,
	Toolbar,
	ExcelExport,
	Sort,
} from "@syncfusion/ej2-react-grids";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import { cicassService } from "../../../_services";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";

const filterSettings = { type: "CheckBox" }; // Menu, Excel
const toolbarOptions = [
	"Search",
	"ExcelExport",
	"Print",
	{
		text: "Refresh",
		tooltipText: "Refresh",
		prefixIcon: "e-MT_reload",
		id: "forceRefresh",
	},
];
const apiEndpointAttendanceReport = "attendance/report";
const apiEndpointGetAllUsers = "authlogin";

const AttendanceReportGrid = (props) => {
	const { isVisible, selectedYear } = props;
	const gridRef = useRef(null);
	const toastRef = useRef(null);
	const { t } = useTranslation();

	const [attendanceReports, setAttendanceReports] = useState(null);
	const [users, setUsers] = useState(null);
	const [toast, setToast] = useState({
		message: "",
		severity: "",
		cssClass: "",
		icon: "",
	});

	useEffect(() => {
		fetchData();
	}, [selectedYear]);

	async function fetchData() {
		refreshGrid();
		const { data } = await cicassService.getAll(apiEndpointGetAllUsers);
		// console.log(data);
		setUsers(data);
	}

	async function refreshGrid() {
		const { data } = await cicassService.getAll(
			`${apiEndpointAttendanceReport}/${selectedYear}`
		);
		setAttendanceReports(data);
		// console.log(attendanceReport.data);
	}

	function toolbarClick(args) {
		const gridId = gridRef.current.htmlattributes.id;
		switch (args.item.properties.id) {
			case `${gridId}_excelexport`:
				gridRef.current.excelExport();
				break;
			default:
				break;
		}
		if (args.item.id === "forceRefresh") {
			refreshGrid();
		}
	}

	return (
		<Box>
			{attendanceReports && isVisible && users && (
				<GridComponent
					id={"attendanceReportGrid"}
					ref={(g) => (gridRef.current = g)}
					dataSource={attendanceReports}
					toolbar={toolbarOptions}
					allowResizing={true}
					allowFiltering={true}
					filterSettings={filterSettings}
					allowSorting={true}
					pageSettings={{ pageSize: 30 }}
					allowExcelExport={true}
					locale={localStorage.getItem("weblang")}
					toolbarClick={(e) => toolbarClick(e)}
				>
					<ColumnsDirective>
						<ColumnDirective
							field="anaUserId"
							headerText={t("name")}
							foreignKeyValue="name"
							foreignKeyField="id"
							dataSource={users}
							allowEditing={false}
							width="150"
						/>

						<ColumnDirective
							field="hhmmss"
							headerText={t("totalTimeSpent")}
							allowEditing={false}
							width="90"
						/>
						<ColumnDirective
							field="hHmmss_Learn"
							headerText={t("totalTimeSpentLearn")}
							allowEditing={false}
							width="90"
						/>
					</ColumnsDirective>
					<Inject
						services={[
							Resize,
							Sort,
							Filter,
							Page,
							Toolbar,
							Edit,
							CommandColumn,
							ForeignKey,
							ExcelExport,
						]}
					/>
				</GridComponent>
			)}
			<ToastComponent
				id="toast_default"
				ref={toastRef}
				position={{ X: "Right", Y: "Bottom" }}
				title={toast.title}
				content={toast.content}
				cssClass={toast.cssClass}
				icon={toast.icon}
			/>
		</Box>
	);
};

export default AttendanceReportGrid;
