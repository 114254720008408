import { useRef, useState, useEffect, useContext } from "react";
import { withTranslation } from "react-i18next";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import itLocale from "date-fns/locale/it";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import { cicassService, auth } from "../../../_services";
import {
  Paper,
  TextField,
  Grid,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ChangeShiftStepper from "./changeshift";
import {
  createSpinner,
  showSpinner,
  hideSpinner,
} from "@syncfusion/ej2-popups";

import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Page,
  Filter,
  Inject as InjectGC,
  InfiniteScroll,
} from "@syncfusion/ej2-react-grids";
import TokenContext from "components/TokenContext";
import AssignShiftDialog from "./AssignShiftDialog";
import SentAndRecievedRequestsDialog from "./SentAndRecievedRequestsDialog";
import ChangeShiftRequestConfirmDialog from "./ChangeShiftRequestConfirmDialog";
import "./calendar.css";
import "add-to-calendar-button";

const apiUrl = "shift";
const apiUrlCalShift = "ana_calendar_shift/calendar/";
const apiUrlShift = "shift/calendarxls";
const apiUrlLastUpdate = "shift/lastupdate";
const apiUrDayCombo = "ana_day/combo";
const apiUrlAvailability = "ana_calendar/availability/";
const apiUrlAnaCalendar = "ana_calendar";
const apiUrlLogin = "authLogin/all";

const actualYear = new Date().getFullYear() + 1;
const maxDate = new Date(actualYear + "-12-31");

function CalXlsPage(props) {
  const useCounterContext = () => useContext(TokenContext);
  let { jwt } = useCounterContext();
  const toastRef = useRef(null);
  const stepperRef = useRef(null);
  const [eventCalendarList, setEventCalendarList] = useState([]); // ToDo: aggiungere secondo il formato nell'attuale esempio in "dates"
  const [selectedCaldendarId, setSelectedCalendarId] = useState(null); // 1; // poi lo leggo da url
  const [calendarSel, setCalendarSel] = useState(null);
  const [shiftSel, setShiftSel] = useState(null);
  const [dayName, setDayName] = useState(null);
  const [availabilitySel, setAvailabilitySel] = useState(null);
  const [availabilityForceSel, setAvailabilityForceSel] = useState(null);
  const [monthlyCheckData, setMonthlycheckData] = useState(null);
  const [defaultDate, setDefaultDate] = useState(new Date());
  const [toastObj, setToastObj] = useState(new ToastComponent());
  const [isPublished, setIsPublished] = useState(null);
  const [isEditEnabled, setEditEnabled] = useState(false);
  const [editor, setEditor] = useState(false);
  const [open, setOpen] = useState(false);
  const [openCL, setOpenCL] = useState(false);
  const [showForceDialog, setShowForceDialog] = useState(false);
  const [calData, setCalData] = useState(null);
  const [forceShiftSel, setForceShiftSel] = useState("");
  const [htmlKeyForced, setHtmlKeyForced] = useState("");
  const [forcedAnaUserId, setForcedAnaUserId] = useState(0);
  const [userList, setUserList] = useState(null);
  // shift managment
  const [isChangeShiftSidebarVisible, setIsChangeShiftSidebarVisible] =
    useState(false);
  const [fromUserSel, setFromUserSel] = useState(null);
  const [toUserSel, setToUserSel] = useState(null);
  const [shiftUserSelection, setShiftUserSelection] = useState({});
  const [stepSel, setStepSel] = useState(1); // 1 from , 2 to, 3 complete
  const [stepperAdditionalInfo, setStepperAdditionalInfo] = useState(null);
  const [lastUpdate, setLastUpdate] = useState("");
  const [inRequestList, setInRequestList] = useState([]);
  const [outRequestList, setOutRequestList] = useState([]);
  const [isFetchingCompleted, setIsFetchingCompleted] = useState(false);
  const [isShiftChangeConfirmDialogOpen, setIsShiftChangeConfirmDialogOpen] =
    useState(false);
  const [
    isChangeShiftRequestConfirmDialogOpen,
    setIsChangeShiftRequestConfirmDialogOpen,
  ] = useState(false);
  const [
    availabilitySubsetForRequestChange,
    setAvailabilitySubsetForRequestChange,
  ] = useState([]);
  const [isPersonManuallySelected, setIsPersonManuallySelected] =
    useState(false);

  const [changeShiftRequestData, setChangeShiftRequestData] = useState({});
  const [htmlKeyForChangeShift, setHtmlKeyForChangeShift] = useState("");
  const [toastInfo, setToastInfo] = useState({
    message: "",
    severity: "",
    cssClass: "",
    icon: "",
  });
  const myFields = { value: "anaUserId", text: "nameSurname" };

  useEffect(() => {
    fetchData();
  }, []);

  async function refreshCalendar() {
    const calendarId = parseInt(
      props.location.pathname.toString().replace("/app/calendarxls/", "")
    );
    setSelectedCalendarId(calendarId);
    const { data: allUser } = await cicassService.getAll(apiUrlLogin);
    setUserList(allUser);
    const { data: anaShift } = await cicassService.getAll(
      apiUrlCalShift + calendarId
    );
    anaShift.sort(function (a, b) {
      return a.viewOrder - b.viewOrder;
    });
    for (let i = 0; i < anaShift.length; i++) {
      anaShift[i].anaUserRoleList.sort(function (x, y) {
        return x.viewOrder - y.viewOrder;
      });
    }
    setShiftSel(anaShift);
    await loadCalendar(
      calendarId,
      calendarSel.year,
      calendarSel.month,
      anaShift,
      allUser
    );

    const calData = (
      await cicassService.getAll(apiUrlAnaCalendar + "/" + calendarId)
    ).data;
    setCalData(calData);
    fetchShiftRequestList();
  }

  async function fetchData() {
    if (dayName === null) {
      createSpinner({
        // Specify the target for the spinner to show
        target: document.getElementById("containerSpin"),
      });

      showSpinner(document.getElementById("containerSpin"));

      let calendarId = parseInt(
        props.location.pathname.toString().replace("/app/calendarxls/", "")
      );
      setSelectedCalendarId(calendarId);
      let { data: listDay } = await cicassService.getAll(
        apiUrDayCombo + "/" + localStorage.getItem("weblang")
      );
      setDayName(listDay);
      let { data: allUser } = await cicassService.getAll(apiUrlLogin);
      setUserList(allUser);
      let { data: anaShift } = await cicassService.getAll(
        apiUrlCalShift + calendarId
      );
      anaShift.sort(function (a, b) {
        return a.viewOrder - b.viewOrder;
      });
      for (let i = 0; i < anaShift.length; i++) {
        anaShift[i].anaUserRoleList.sort(function (x, y) {
          return x.viewOrder - y.viewOrder;
        });
      }
      setShiftSel(anaShift);

      await loadCalendar(
        calendarId,
        defaultDate.getFullYear(),
        defaultDate.getMonth() + 1,
        anaShift,
        allUser
      );
      const myrole = auth.getUserRole();
      let iAmManagerOfCalendar = false;
      if (myrole === "Manager") {
        iAmManagerOfCalendar = (
          await cicassService.getAll(
            apiUrlAnaCalendar + "/ismanager/" + calendarId
          )
        ).data;
      }
      let roleEdit =
        myrole === "Admin" ||
        myrole === "SuperUser" ||
        (myrole === "Manager" && iAmManagerOfCalendar)
          ? true
          : false;
      setEditor(roleEdit);

      let calData = (
        await cicassService.getAll(apiUrlAnaCalendar + "/" + calendarId)
      ).data;
      setCalData(calData);
      fetchShiftRequestList();
      setIsFetchingCompleted(true);
      hideSpinner(document.getElementById("containerSpin"));
    }
  }

  async function fetchShiftRequestList(newYear, newMonth) {
    let year = newYear;
    let month = newMonth;
    if (!newYear && !newMonth) {
      year = calendarSel ? calendarSel.year : defaultDate.getFullYear();
      month = calendarSel ? calendarSel.month : defaultDate.getUTCMonth() + 1;
    }

    let { data: inRequestList } = await cicassService.getAll(
      apiUrl + "/allrequest/3/" + year + "/" + month
    );
    let { data: outRequestList } = await cicassService.getAll(
      apiUrl + "/allrequest/2/" + year + "/" + month
    );
    setInRequestList(inRequestList);
    setOutRequestList(outRequestList);
  }

  // user-shift selection changed
  const onUserChanged = async (e) => {
    let copyData = calendarSel;
    let idxArray = e.element.id.replace("hk_", "").split("_");

    // B) Save data
    let forcedBefore =
      calendarSel.days[parseInt(idxArray[0])].shifts[parseInt(idxArray[1])]
        .users[parseInt(idxArray[2])].forced;
    let dataToSave = {
      id: -1,
      shiftId:
        calendarSel.days[parseInt(idxArray[0])].shifts[parseInt(idxArray[1])]
          .shiftId,
      anaCalendarId: calendarSel.anaCalendarId,
      year: calendarSel.year,
      month: calendarSel.month,
      day: calendarSel.days[parseInt(idxArray[0])].day,
      anaShiftId:
        calendarSel.days[parseInt(idxArray[0])].shifts[parseInt(idxArray[1])]
          .anaShiftId,
      shiftUserId:
        calendarSel.days[parseInt(idxArray[0])].shifts[parseInt(idxArray[1])]
          .users[parseInt(idxArray[2])].shiftUserId,
      anaUserId: e.value == null ? 0 : e.value,
      anaUseRoleId:
        calendarSel.days[parseInt(idxArray[0])].shifts[parseInt(idxArray[1])]
          .users[parseInt(idxArray[2])].anaUserRoleId,
      anaShiftStatusId: isPublished,
      forced: e.value == null ? 0 : forcedBefore,
    };
    //C) Check: I prevent the insert on writing incorrect username
    if (e.value !== null) {
      const mDay = parseInt(idxArray[0]);
      const mShift = parseInt(idxArray[1]);
      const mUser = parseInt(idxArray[2]);
      //console.log("Save this user = ", forcedAnaUserId,",position=",mUser);
      for (
        let u = 0;
        u < calendarSel.days[mDay].shifts[mShift].users.length;
        u++
      ) {
        let anaUserId =
          calendarSel.days[mDay].shifts[mShift].users[u].anaUserId;
        if (mUser != u) {
          if (anaUserId === (e.value === null ? 0 : e.value)) {
            // console.log("STANDARD: find person, incorrect selection", e);
            e.cancel = true;
          }
        }
      }
    }

    // SAVE ALL
    let { data: savedData } = await cicassService.saveOp(
      apiUrl + "/saveshift",
      dataToSave
    );
    let prevAnaUserId =
      copyData.days[parseInt(idxArray[0])].shifts[parseInt(idxArray[1])].users[
        parseInt(idxArray[2])
      ].anaUserId;
    copyData.days[parseInt(idxArray[0])].shifts[parseInt(idxArray[1])].shiftId =
      savedData.updatedData.shiftId;
    copyData.days[parseInt(idxArray[0])].shifts[parseInt(idxArray[1])].users[
      parseInt(idxArray[2])
    ].shiftUserId = savedData.updatedData.shiftUserId;
    copyData.days[parseInt(idxArray[0])].shifts[parseInt(idxArray[1])].users[
      parseInt(idxArray[2])
    ].anaUserId = e.value == null ? 0 : e.value;
    // remove forced data
    if (dataToSave.anaUserId == 0 && forcedBefore == 1) {
      let availability = availabilitySel;
      let toRemove = availability.findIndex(
        (q) =>
          q.day == dataToSave.day &&
          q.anaShiftId == dataToSave.anaShiftId &&
          q.forced == 1 &&
          q.anaUserRoleId == dataToSave.anaUseRoleId &&
          q.anaUserId == prevAnaUserId
      );
      if (toRemove != -1) {
        //console.log(availability[toRemove]);
        availability.splice(toRemove, 1);
      }
    }
    setMonthlycheckData(savedData.monthlyCheck);
    setCalendarSel(copyData);
    //console.log(calendarSel.days[parseInt(idxArray[0])].shifts[parseInt(idxArray[1])], calendarSel.days[parseInt(idxArray[0])].shifts[parseInt(idxArray[1])].users[parseInt(idxArray[2])]);
  };

  // user-shift open
  const onUserOpen = (e) => {
    let idx = 0;
    let idxArray = e.popup.element.id.replace("hk_", "").split("_");
    let myComboList = e.popup.element.querySelectorAll(".e-list-item");
    myComboList.forEach((element) => {
      e.popup.element
        .querySelectorAll(".e-list-item")
        [idx].classList.remove("e-disabled");
      e.popup.element
        .querySelectorAll(".e-list-item")
        [idx].classList.remove("e-overlay");
      for (
        let x = 0;
        x <
        calendarSel.days[parseInt(idxArray[0])].shifts[parseInt(idxArray[1])]
          .users.length;
        x++
      ) {
        if (x != parseInt(idxArray[2])) {
          if (
            calendarSel.days[parseInt(idxArray[0])].shifts[
              parseInt(idxArray[1])
            ].users[x].anaUserId == Number(element.dataset.value)
          ) {
            e.popup.element
              .querySelectorAll(".e-list-item")
              [idx].classList.add("e-disabled");
            e.popup.element
              .querySelectorAll(".e-list-item")
              [idx].classList.add("e-overlay");
          }
        }
      }
      idx = idx + 1;
    });
  };

  // year-month selecton changed
  const onMonthChange = async (e) => {
    const year = e.getFullYear();
    const month = e.getUTCMonth() + 1;
    let calIdSel = selectedCaldendarId;
    setDefaultDate(e);
    setCalendarSel(null);
    setMonthlycheckData(null);
    setIsChangeShiftSidebarVisible(false);

    fetchShiftRequestList(year, month);
    if (e == null) return;
    loadCalendar(calIdSel, year, month, shiftSel, userList);
  };

  const loadCalendar = async (
    calIdSel,
    yearSel,
    monthSel,
    anaShift,
    anaUserList
  ) => {
    showSpinner(document.getElementById("containerSpin"));
    try {
      let calendar = {
        id: 0, // force post
        anaCalendarId: calIdSel, // from url
        year: yearSel,
        month: monthSel,
        daysInMonth: new Date(yearSel, monthSel, 0).getDate(),
        days: [],
      };

      let { data: mc } = await cicassService.getAll(
        apiUrl +
          "/monthlycheck/" +
          calendar.anaCalendarId +
          "," +
          calendar.year +
          "," +
          calendar.month +
          "," +
          localStorage.getItem("weblang")
      );
      setMonthlycheckData(mc);

      let { data: availability } = await cicassService.getAll(
        apiUrlAvailability +
          calendar.anaCalendarId +
          "," +
          calendar.year +
          "," +
          calendar.month
      );
      //console.log(availability);

      // shifts and users
      let shiftsOfDay = [];
      for (let x = 0; x < anaShift.length; x++) {
        let anaUsers = [];
        for (let y = 0; y < anaShift[x].anaUserRoleList.length; y++) {
          anaUsers.push({
            anaUserRoleId: anaShift[x].anaUserRoleList[y].anaUserRoleId,
            anaUserId: 0, // from db
            shiftUserId: 0, // from db
            forced: 0,
            isSelected: false,
            htmlKey: "-", // [day_idx]_[shift_idx]_[user_idx]
          });
        }
        shiftsOfDay.push({
          shiftId: 0, // from db
          anaShiftStatusId: 0, // from db
          anaShiftId: anaShift[x].id,
          users: anaUsers,
        });
      }

      // days
      for (let index = 0; index < calendar.daysInMonth; index++) {
        let ana_day_id = new Date(
          calendar.year,
          calendar.month - 1,
          index + 1
        ).getDay();
        calendar.days.push({
          day: index + 1,
          anaDayId: ana_day_id == 0 ? 7 : ana_day_id,
          shifts: JSON.parse(JSON.stringify(shiftsOfDay)),
        });
      }
      let { data: lastUpdateDt } = await cicassService.getAll(
        apiUrlLastUpdate +
          "/" +
          calendar.anaCalendarId +
          "," +
          calendar.year +
          "," +
          calendar.month
      );
      setLastUpdate(lastUpdateDt);
      let { data: dbData } = await cicassService.getAll(
        apiUrlShift +
          "/" +
          calendar.anaCalendarId +
          "," +
          calendar.year +
          "," +
          calendar.month
      );
      let published = 0;
      let calendarListToAdd = new Array();
      const loggedUserId = auth.getUserId(jwt);

      for (let z = 0; z < calendar.days.length; z++) {
        for (let w = 0; w < calendar.days[z].shifts.length; w++) {
          for (let t = 0; t < calendar.days[z].shifts[w].users.length; t++) {
            let dbValue = dbData.filter(
              (q) =>
                q.anaShiftId == calendar.days[z].shifts[w].anaShiftId &&
                (q.anaUserRoleId ==
                  calendar.days[z].shifts[w].users[t].anaUserRoleId ||
                  q.anaUserRoleId == 0) &&
                q.dayFrom == calendar.days[z].day
            );
            // [day_idx]_[shift_idx]_[user_idx]
            calendar.days[z].shifts[w].users[t].htmlKey = z + "_" + w + "_" + t;
            calendar.days[z].shifts[w].users[t].showChangeRequest = false;
            if (dbValue.length == 1) {
              //console.log("TROVATO=",calendar.days[z].day ,dbValue);
              calendar.days[z].shifts[w].users[t].anaUserId =
                dbValue[0].anaUserId;
              calendar.days[z].shifts[w].users[t].shiftUserId = dbValue[0].id;
              calendar.days[z].shifts[w].users[t].forced = dbValue[0].forced;
              calendar.days[z].shifts[w].shiftId = dbValue[0].shiftId;
              calendar.days[z].shifts[w].anaShiftStatusId =
                dbValue[0].anaShiftStatusId;
              if (dbValue[0].anaShiftStatusId === 1) published = 1;
              if (dbValue[0].forced) {
                //Update Availability
                let volunteer = anaUserList.filter(
                  (q) => q.id == dbValue[0].anaUserId
                );
                if (volunteer == null && dbValue[0].anaUserId != 0)
                  console.log("User", dbValue[0].anaUserId);
                const toForce = {
                  anaDayId: calendar.days[z].anaDayId,
                  anaShiftId: calendar.days[z].shifts[w].anaShiftId,
                  anaUserId: dbValue[0].anaUserId,
                  anaUserRoleId:
                    calendar.days[z].shifts[w].users[t].anaUserRoleId,
                  day: calendar.days[z].day,
                  forced: 1,
                  nameSurname:
                    dbValue[0].anaUserId == 0 ? "" : volunteer[0].name,
                };

                availability.push(toForce);
              }

              if (
                loggedUserId == calendar.days[z].shifts[w].users[t].anaUserId
              ) {
                let shiftToAdd = {
                  name: "Turno 112",
                  description:
                    anaShift
                      .filter((q) => q.id == dbValue[0].anaShiftId)[0]
                      .anaUserRoleList.filter(
                        (w) => w.anaUserRoleId == dbValue[0].anaUserRoleId
                      )[0].roleName +
                    " - " +
                    anaShift.filter((q) => q.id == dbValue[0].anaShiftId)[0]
                      .name,
                  startDate:
                    String(calendar.year) +
                    "-" +
                    (calendar.month < 10 ? "0" : "") +
                    String(calendar.month) +
                    "-" +
                    (calendar.days[z].day < 10 ? "0" : "") +
                    String(calendar.days[z].day),
                  startTime: dbValue[0].shiftFrom.substring(11, 16),
                  endTime: dbValue[0].shiftTo.substring(11, 16),
                };
                calendarListToAdd.push(shiftToAdd);
              }
            }
          }
        }
      }
      /*if (calendarListToAdd.length == 0)
				setEventCalendarToAdd(null);
			else*/
      setEventCalendarList(calendarListToAdd);
      setAvailabilitySel(availability);
      // setIsPublished(published);
      // console.log("Is published: ", published);
      setIsPublished(published);
      setCalendarSel(calendar);
    } catch (exception) {
      console.log("Error", exception);
    }
    hideSpinner(document.getElementById("containerSpin"));
  };

  function getNumberOfPendingShiftRequests() {
    const numberOfPendingShiftRequests = inRequestList.filter(
      (request) => !request.confirmedAt && !request.refusedAt
    ).length;
    return numberOfPendingShiftRequests;
  }

  function hasDateExpired() {
    const currentMonth = defaultDate.getUTCMonth() + 1;
    const currentYear = defaultDate.getUTCFullYear();
    return (
      calendarSel.year < currentYear ||
      (calendarSel.year === currentYear && calendarSel.month < currentMonth)
    );
  }

  // Header
  const printHeaderDays = () => {
    const day = props.t("day");
    return (
      <Grid
        key={"HEADER_DAY"}
        item
        xs={1}
        style={{ paddingLeft: "2px", paddingRight: "2px" }}
      >
        <Paper className="headerColCalendar hideHeaderDescription">{day}</Paper>
      </Grid>
    );
  };

  const printHeaderRoles = () => {
    return (
      <Grid
        key={"R_Header"}
        item
        xs={1}
        style={{ paddingLeft: "4px", paddingRight: "4px" }}
      >
        <Paper
          className="headerColCalendar hideHeaderDescription"
          style={{ color: "#2c5282" }}
        >
          {props.t("Roles")}
        </Paper>
      </Grid>
    );
  };

  const printHeaderShifts = () => {
    let rows = [];
    let xsValue = (12 / shiftSel.length) >> 0;
    for (let x = 0; x < shiftSel.length; x++) {
      rows.push(
        <Grid
          key={"SHeader_" + x}
          style={{ paddingLeft: "4px", paddingRight: "4px" }}
          item
          xs={xsValue}
        >
          <Paper className="headerColCalendar">
            {shiftSel[x].timeFrom.substring(0, 5) +
              " - " +
              shiftSel[x].timeTo.substring(0, 5)}
          </Paper>
        </Grid>
      );
    }
    return (
      <Grid key={"ShiftHeader"} container item xs={10}>
        {rows}
      </Grid>
    );
  };

  // Content
  const printDays = (singleDay) => {
    return (
      <Grid key={"D_" + singleDay.day} item xs={1}>
        <Paper className="headerRowDayNr">
          {dayName
            .filter((q) => q.id == singleDay.anaDayId)[0]
            .name.substring(0, 3)}{" "}
          {singleDay.day}
        </Paper>
      </Grid>
    );
  };

  const printRoles = (day) => {
    let rows = [];
    //console.log(shiftSel);
    for (let index = 0; index < shiftSel[0].anaUserRoleList.length; index++) {
      //console.log(shiftSel[0].anaUserRoleList[index]);
      rows.push(
        <Grid
          key={
            "R_" + day + "_" + shiftSel[0].anaUserRoleList[index].anaUserRoleId
          }
          item
          xs={12}
        >
          <Paper
            className="headerRowRoleDescription"
            style={{ color: "#2c5282" }}
          >
            {shiftSel[0].anaUserRoleList[index].roleName}
          </Paper>
        </Grid>
      );
    }
    return (
      <Grid key={"RH_" + day} item container xs={1}>
        {rows}
      </Grid>
    );
  };
  // Generate Shifts
  const printShifts = (avoidSelection, singleDay) => {
    const loggedUser = auth.getUserId(jwt);
    let rows = [];
    let subSet = availabilitySel.filter((q) => q.day == singleDay.day);
    let xsValue = (12 / singleDay.shifts.length) >> 0;
    for (
      let shiftIndex = 0;
      shiftIndex < singleDay.shifts.length;
      shiftIndex++
    ) {
      let shifts = [];
      for (
        let userIndex = 0;
        userIndex < singleDay.shifts[shiftIndex].users.length;
        userIndex++
      ) {
        let subAvail = subSet.filter(
          (q) =>
            q.anaShiftId == singleDay.shifts[shiftIndex].anaShiftId &&
            q.anaUserRoleId ==
              singleDay.shifts[shiftIndex].users[userIndex].anaUserRoleId
        );
        subAvail.sort(function (a, b) {
          if (a.nameSurname < b.nameSurname) {
            return -1;
          }
          if (a.nameSurname > b.nameSurname) {
            return 1;
          }
          return 0;
        });
        let checkOnId = subAvail.filter(
          (i) =>
            i.anaUserId ==
            singleDay.shifts[shiftIndex].users[userIndex].anaUserId
        )[0];
        let checkOnUserName =
          checkOnId == undefined
            ? false
            : checkOnId.anaUserId == loggedUser
            ? true
            : false;
        let same =
          singleDay.shifts[shiftIndex].users[userIndex].anaUserId == 0
            ? false
            : checkOnUserName;
        // Alex: ho sistemato questa anomalia che di fatto dipende da turni assegnati fuori disponibilità, ho lasciato la nota perchè è qui che devo modificare per gestire assegnamenti extra
        // devo aggiungere l'utente forzato alla combo !!!
        let nameSurnameSelected =
          subAvail.filter(
            (i) =>
              i.anaUserId ==
              singleDay.shifts[shiftIndex].users[userIndex].anaUserId
          )[0] == undefined
            ? ""
            : subAvail.filter(
                (i) =>
                  i.anaUserId ==
                  singleDay.shifts[shiftIndex].users[userIndex].anaUserId
              )[0].nameSurname;
        shifts.push(
          <Grid
            key={"key_" + singleDay.shifts[shiftIndex].users[userIndex].htmlKey}
            item
            xs={12}
          >
            {isEditEnabled && (
              <div style={{ display: "flex", flexDirection: "row" }}>
                <ComboBoxComponent
                  id={
                    "hk_" +
                    singleDay.shifts[shiftIndex].users[userIndex].htmlKey
                  }
                  dataSource={subAvail}
                  fields={myFields}
                  value={
                    singleDay.shifts[shiftIndex].users[userIndex].anaUserId == 0
                      ? null
                      : singleDay.shifts[shiftIndex].users[userIndex].anaUserId
                  }
                  placeholder="..."
                  autofill={true}
                  readOnly={isEditEnabled ? false : true}
                  allowFiltering={true}
                  change={onUserChanged}
                  open={onUserOpen}
                  style={{
                    fontWeight: same ? "bold" : null,
                    backgroundColor: same ? "#ABEBC6" : null,
                  }}
                />
                <div style={{ padding: "5px" }}>
                  <ButtonComponent
                    style={{ fontSize: "smaller" }}
                    cssClass="e-info e-outline e-small e-round"
                    iconCss="fas fa-user-plus"
                    title={props.t("forceShift")}
                    onClick={() => {
                      forceShift(
                        singleDay.shifts[shiftIndex].users[userIndex].htmlKey
                      );
                    }}
                  />
                </div>
              </div>
            )}
            {!isEditEnabled && (
              <div
                className="e-input-group"
                style={{
                  marginBottom: "0px !important",
                }}
              >
                <input
                  readOnly={true}
                  className="e-input"
                  type="text"
                  value={
                    singleDay.shifts[shiftIndex].users[userIndex].anaUserId ===
                    0
                      ? ""
                      : nameSurnameSelected
                  }
                  style={{
                    fontWeight: same ? "bold" : null,
                    backgroundColor: same ? "#ABEBC6" : null,
                    border: singleDay.shifts[shiftIndex].users[userIndex]
                      .isSelected
                      ? "2px solid #006400"
                      : "2px solid transparent",
                    borderRadius: singleDay.shifts[shiftIndex].users[userIndex]
                      .isSelected
                      ? "10px"
                      : "0",
                    paddingLeft: "4px",
                  }}
                  onClick={(e) =>
                    selectFromOrToUser(
                      avoidSelection,
                      singleDay.day,
                      shiftIndex,
                      userIndex,
                      nameSurnameSelected
                    )
                  }
                />
                {singleDay.shifts[shiftIndex].users[userIndex]
                  .showChangeRequest && (
                  <ButtonComponent
                    style={{ marginLeft: "1rem" }}
                    cssClass="e-info e-outline e-small e-round button-margin-5"
                    iconCss="fas fa-user-plus"
                    title={props.t("peopleAvailableForShiftRequest")}
                    onClick={() =>
                      changeOnAvailabilityForShift(
                        subAvail,
                        shiftIndex,
                        userIndex
                      )
                    }
                    disabled={isPersonManuallySelected}
                  />
                )}
              </div>
            )}
          </Grid>
        );
      }
      rows.push(
        <Grid
          key={"S" + singleDay.day + "_" + shiftIndex}
          style={{ paddingLeft: "5px", paddingRight: "5px" }}
          item
          xs={xsValue}
        >
          {shifts}
        </Grid>
      );
    }
    return (
      <Grid key={"SH_" + singleDay.day} container item xs={10}>
        {rows}
      </Grid>
    );
  };

  // Force the shift: save + add availability row
  const forceActualShift = async () => {
    let copyData = calendarSel;
    let idxArray = htmlKeyForced.replace("hk_", "").split("_");
    let forced = forcedAnaUserId == 0 ? 0 : 1;
    let dataToSave = {
      id: -1,
      shiftId:
        calendarSel.days[parseInt(idxArray[0])].shifts[parseInt(idxArray[1])]
          .shiftId,
      anaCalendarId: calendarSel.anaCalendarId,
      year: calendarSel.year,
      month: calendarSel.month,
      day: calendarSel.days[parseInt(idxArray[0])].day,
      anaShiftId:
        calendarSel.days[parseInt(idxArray[0])].shifts[parseInt(idxArray[1])]
          .anaShiftId,
      shiftUserId:
        calendarSel.days[parseInt(idxArray[0])].shifts[parseInt(idxArray[1])]
          .users[parseInt(idxArray[2])].shiftUserId,
      anaUserId: forcedAnaUserId,
      anaUseRoleId:
        calendarSel.days[parseInt(idxArray[0])].shifts[parseInt(idxArray[1])]
          .users[parseInt(idxArray[2])].anaUserRoleId,
      anaShiftStatusId: isPublished,
      forced: forced,
    };

    // check user in the same shift
    if (forced == 1) {
      const myArray = htmlKeyForced.split("_");
      const mDay = parseInt(myArray[0]);
      const mShift = parseInt(myArray[1]);
      const mUser = parseInt(myArray[2]);
      //console.log("Save this user = ", forcedAnaUserId,",position=",mUser) ;
      for (
        let u = 0;
        u < calendarSel.days[mDay].shifts[mShift].users.length;
        u++
      ) {
        let anaUserId =
          calendarSel.days[mDay].shifts[mShift].users[u].anaUserId;
        if (mUser != u) {
          if (anaUserId == forcedAnaUserId) {
            console.log("FORCED: trovato in altra posizione annulla tutto");
            return;
          }
        }
      }
    }
    // save

    //console.log("save", dataToSave);
    let { data: savedData } = await cicassService.saveOp(
      apiUrl + "/saveshift",
      dataToSave
    );
    // update user to local data
    copyData.days[parseInt(idxArray[0])].shifts[parseInt(idxArray[1])].shiftId =
      savedData.updatedData.shiftId;
    copyData.days[parseInt(idxArray[0])].shifts[parseInt(idxArray[1])].users[
      parseInt(idxArray[2])
    ].shiftUserId = savedData.updatedData.shiftUserId;
    copyData.days[parseInt(idxArray[0])].shifts[parseInt(idxArray[1])].users[
      parseInt(idxArray[2])
    ].forced = forced;
    copyData.days[parseInt(idxArray[0])].shifts[parseInt(idxArray[1])].users[
      parseInt(idxArray[2])
    ].anaUserId = forcedAnaUserId;
    setMonthlycheckData(savedData.monthlyCheck);
    setCalendarSel(copyData);
    // update availability if forced
    if (forced == 1) {
      let filterSel = availabilityForceSel.filter(
        (q) => q.anaUserId == forcedAnaUserId
      );
      filterSel[0].forced = 1;
      availabilitySel.push(filterSel[0]);
      //console.log(availabilityForceSel,availabilitySel,filterSel);
    }
    setShowForceDialog(false);
  };

  // Open modali with people available for that shift
  const changeOnAvailabilityForShift = async (
    shiftAvailability,
    shiftIndex,
    userIndex
  ) => {
    const loggedUserId = auth.getUserId(jwt);
    const filteredShiftAvailability = shiftAvailability.filter(
      (item) => item.anaUserId !== loggedUserId
    );

    const data = {
      dataSource: filteredShiftAvailability,
      shiftIndex,
      userIndex,
    };

    setAvailabilitySubsetForRequestChange(data);
    setIsChangeShiftRequestConfirmDialogOpen(true);
  };

  // open modal for forcing shift
  const forceShift = async (htmlKey) => {
    const myArray = htmlKey.split("_");
    const mDay = parseInt(myArray[0]);
    const mShift = parseInt(myArray[1]);
    const mUser = parseInt(myArray[2]);
    let subShift = shiftSel.filter(
      (q) => q.id == calendarSel.days[mDay].shifts[mShift].anaShiftId
    )[0];
    let subRole = shiftSel[0].anaUserRoleList.filter(
      (q) =>
        q.anaUserRoleId ==
        calendarSel.days[mDay].shifts[mShift].users[mUser].anaUserRoleId
    )[0];
    let mSelection =
      dayName
        .filter((q) => q.id == calendarSel.days[mDay].anaDayId)[0]
        .name.substring(0, 3) +
      " " +
      calendarSel.days[mDay].day;
    mSelection =
      mSelection +
      " - " +
      subShift.name +
      " " +
      subShift.timeFrom.substring(0, 5) +
      " - " +
      subShift.timeTo.substring(0, 5);
    mSelection = mSelection + " - " + subRole.roleName;
    setForceShiftSel(mSelection);
    setShowForceDialog(true);
    let url =
      apiUrlAvailability +
      "force/" +
      calendarSel.anaCalendarId +
      "," +
      calendarSel.days[mDay].shifts[mShift].anaShiftId +
      "," +
      calendarSel.days[mDay].shifts[mShift].users[mUser].anaUserRoleId +
      "," +
      calendarSel.year +
      "," +
      calendarSel.month +
      "," +
      calendarSel.days[mDay].day +
      "," +
      calendarSel.days[mDay].anaDayId;
    let { data: availability } = await cicassService.getAll(url);
    availability.sort(function (a, b) {
      if (a.nameSurname < b.nameSurname) {
        return -1;
      }
      if (a.nameSurname > b.nameSurname) {
        return 1;
      }
      return 0;
    });
    // remove user assigned to the same shift
    /*
        for(let u = 0; u < calendarSel.days[mDay].shifts[mShift].users.length; u ++){
            let anaUserId = calendarSel.days[mDay].shifts[mShift].users[u].anaUserId;
            if (anaUserId > 0 && mUser != u)
            {
                let check = availability.findIndex(q => q.anaUserId == anaUserId);
                if (check != -1)
                    availability.splice(check,1);
                //console.log("trovato", check);// anaUserId, availability,mUser)
            }
        }*/

    setHtmlKeyForced(htmlKey);
    setAvailabilityForceSel(availability);
  };

  // user-shift-forced open
  const onUserForcedOpen = (e) => {
    let idx = 0;
    let idxArray = htmlKeyForced.split("_");
    let myComboList = e.popup.element.querySelectorAll(".e-list-item");
    myComboList.forEach((element) => {
      e.popup.element
        .querySelectorAll(".e-list-item")
        [idx].classList.remove("e-disabled");
      e.popup.element
        .querySelectorAll(".e-list-item")
        [idx].classList.remove("e-overlay");
      for (
        let x = 0;
        x <
        calendarSel.days[parseInt(idxArray[0])].shifts[parseInt(idxArray[1])]
          .users.length;
        x++
      ) {
        if (x != parseInt(idxArray[2])) {
          if (
            calendarSel.days[parseInt(idxArray[0])].shifts[
              parseInt(idxArray[1])
            ].users[x].anaUserId == Number(element.dataset.value)
          ) {
            e.popup.element
              .querySelectorAll(".e-list-item")
              [idx].classList.add("e-disabled");
            e.popup.element
              .querySelectorAll(".e-list-item")
              [idx].classList.add("e-overlay");
          }
        }
      }
      idx = idx + 1;
    });
  };

  // user-shift-forced selection changed
  const onForcedUserChanged = (e) => {
    setForcedAnaUserId(e.value == null ? 0 : e.value);
  };
  //Change the mobile view
  const printCalendar = () => {
    // console.log(shiftSel)
    let rows = [];
    if (!isEditEnabled && !isPublished) {
      return (
        <div className="calendarXlsContent">{props.t("calendarSuspend")}</div>
      );
    }
    // header
    // rows.push(
    const FirstLine = ({ index }) => {
      const className = index === null ? "sticky headerLine" : "firstLine";
      return (
        <Grid key={"cal_header"} container className={className}>
          {printHeaderDays()}
          {printHeaderRoles()}
          {printHeaderShifts()}
        </Grid>
      );
    };
    // data
    let today = new Date();
    let avoidSelectionForChange = true;
    rows.push(<FirstLine index={null} />);
    for (let index = 0; index < calendarSel.daysInMonth; index++) {
      if (
        today.getTime() >
        new Date(calendarSel.year, calendarSel.month - 1, index + 1).getTime()
      )
        avoidSelectionForChange = true;
      else avoidSelectionForChange = false;
      rows.push(
        <Grid
          key={"cal_" + index}
          container
          className={index % 2 == 0 ? "roundWhite" : "roundGray"}
        >
          <FirstLine index={index} />
          {printDays(calendarSel.days[index])}
          {printRoles(index + 1)}
          {printShifts(avoidSelectionForChange, calendarSel.days[index])}
        </Grid>
      );
    }
    return <div className="calendarXlsContent">{rows}</div>;
  };

  // change shift status
  const changeShiftStatus = async (statusId) => {
    // console.log(statusId);
    let { data: scheduleData } = await cicassService.getAll(
      apiUrl +
        "/changestatus/" +
        calendarSel.anaCalendarId +
        "," +
        calendarSel.year +
        "," +
        calendarSel.month +
        "," +
        statusId
    );
    let msgTitle = props.t("calendarPublication");
    let msgContent =
      statusId == 0 ? props.t("calendarPubS1") : props.t("calendarPubA1");
    showToastInfo(
      statusId,
      statusId == 0 ? "Warning" : "Information",
      msgTitle,
      msgContent
    );
  };

  const onCommandClick = async (args) => {
    if (
      args.rowData.confirmedAt !== null ||
      args.rowData.refusedAt !== null ||
      hasDateExpired()
    )
      return;

    setIsShiftChangeConfirmDialogOpen(true);

    setChangeShiftRequestData({
      requestType: args.commandColumn.type,
      id: args.rowData.id,
    });
  };

  async function handleChangeShiftRequest() {
    let apiUrlCmd;
    let toastMessage;
    let toastSeverity = "Success";
    switch (changeShiftRequestData.requestType) {
      case "Delete":
        apiUrlCmd = {
          endpoint: "shift/removerequest",
          method: "deleteOp",
        };
        toastMessage = props.t("toastShiftRequestDeletedSuccess");
        break;
      case "Yes":
        apiUrlCmd = {
          endpoint: "shift/confirmrequest",
          method: "getById",
        };
        toastMessage = props.t("toastShiftRequestAcceptedSuccess");
        break;
      case "No":
        apiUrlCmd = {
          endpoint: "shift/refuserequest",
          method: "getById",
        };
        toastMessage = props.t("toastShiftRequestDeniedSuccess");
        break;
      default:
        return;
    }
    // API Call
    const response = await cicassService[apiUrlCmd.method](
      apiUrlCmd.endpoint,
      changeShiftRequestData.id
    );
    if (response.status !== 200) {
      toastSeverity = "Error";
      toastMessage = props.t("toastShiftRequestFailureMessage");
    }

    showToast(toastSeverity, toastMessage);
    setChangeShiftRequestData({});
    setIsShiftChangeConfirmDialogOpen(false);
    if (changeShiftRequestData.requestType === "Yes") return refreshCalendar();
    fetchShiftRequestList();
  }

  function senderRowDataBound(args) {
    if (
      args.data.confirmedAt !== null ||
      args.data.refusedAt !== null ||
      hasDateExpired()
    ) {
      args.row
        .querySelectorAll(".e-icons.e-delete")[0]
        .classList.add("e-disabled");
      args.row.querySelectorAll(".e-deletebutton")[0].style.cursor = "auto";
    }
  }

  function recieverRowDataBound(args) {
    if (
      args.data.confirmedAt !== null ||
      args.data.refusedAt !== null ||
      hasDateExpired()
    ) {
      args.row
        .querySelectorAll(".e-icons.e-check-mark-01")[0]
        .classList.add("e-disabled");
      args.row.querySelectorAll(".e-check-mark-01")[0].style.cursor = "auto";

      args.row
        .querySelectorAll(".e-icons.e-close")[0]
        .classList.add("e-disabled");
      args.row.querySelectorAll(".e-close")[0].style.cursor = "auto";
    }
  }

  const showToastInfo = (newShiftStatusId, type, title, content) => {
    let copyData = calendarSel;
    toastObj.hide();
    switch (type) {
      case "Warning":
        toastObj.title = title;
        toastObj.content = content;
        toastObj.cssClass = "e-toast-warning";
        toastObj.icon = "e-warning toast-icons";
        break;
      case "Success":
        toastObj.title = title;
        toastObj.content = content;
        toastObj.cssClass = "e-toast-success";
        toastObj.icon = "e-success toast-icons";
        break;
      case "Error":
        toastObj.title = title;
        toastObj.content = content;
        toastObj.cssClass = "e-toast-danger";
        toastObj.icon = "e-error toast-icons";
        break;
      case "Information":
        toastObj.title = title;
        toastObj.content = content;
        toastObj.cssClass = "e-toast-info";
        toastObj.icon = "e-info toast-icons";
        break;
      default:
        break;
    }

    for (let d = 0; d < copyData.days.length; d++) {
      for (let s = 0; s < copyData.days[d].shifts[s].length; s++) {
        copyData.days[d].shifts[s].anaShiftStatusId = newShiftStatusId;
      }
    }
    setCalendarSel(copyData);
    setIsPublished(newShiftStatusId);
    toastObj.show();
    return;
  };

  function showToast(title, errorMsg) {
    const toast = {};
    switch (title) {
      case "Warning":
        toast.title = "Warning";
        toast.content = errorMsg;
        toast.cssClass = "e-toast-warning";
        toast.icon = "e-warning toast-icons";
        break;
      case "Success":
        toast.title = "Success";
        toast.content = errorMsg;
        toast.cssClass = "e-toast-success";
        toast.icon = "e-success toast-icons";
        break;
      case "Error":
        toast.title = "Error";
        toast.content = errorMsg;
        toast.cssClass = "e-toast-danger";
        toast.icon = "e-error toast-icons";
        break;
      case "Information":
        toast.title = "Information";
        toast.content = errorMsg;
        toast.cssClass = "e-toast-info";
        toast.icon = "e-info toast-icons";
        break;
      default:
        break;
    }
    setToastInfo(toast);
    toastRef.current.show();
  }

  const printOnFile = async () => {
    showSpinner(document.getElementById("containerSpin"));
    let roleList = shiftSel[0].anaUserRoleList;
    let calandarString = [];
    // pre-header => Selection
    let preHead = [];
    preHead.push("");
    preHead.push("");
    const today = new Date(calendarSel.year, calendarSel.month - 1, 1);
    const lang = localStorage.getItem("weblang").toLowerCase();
    preHead.push(
      today
        .toLocaleString(lang + "-" + lang.toUpperCase(), { month: "long" })
        .toUpperCase()
    );
    preHead.push(calendarSel.year.toString());
    for (let y = 0; y < shiftSel.length - 2; y++) {
      preHead.push("");
    }
    calandarString.push(preHead);
    // header
    let head = [];
    head.push(props.t("day")); // da sostituire in lingua
    head.push(props.t("Roles")); // idem sopra
    for (let x = 0; x < shiftSel.length; x++) {
      head.push(
        shiftSel[x].timeFrom.substring(0, 5) +
          " - " +
          shiftSel[x].timeTo.substring(0, 5)
      );
      /*head.push(
				(shiftSel[x].timeFrom.hours < 10 ? "0" : "") +
					shiftSel[x].timeFrom.hours +
					":" +
					(shiftSel[x].timeFrom.minutes < 10 ? "0" : "") +
					shiftSel[x].timeFrom.minutes +
					" - " +
					(shiftSel[x].timeTo.hours < 10 ? "0" : "") +
					shiftSel[x].timeTo.hours +
					":" +
					(shiftSel[x].timeTo.minutes < 10 ? "0" : "") +
					shiftSel[x].timeTo.minutes
			);*/
    }
    calandarString.push(head);
    // rows
    for (let d = 0; d < calendarSel.days.length; d++) {
      let subSet = availabilitySel.filter(
        (q) => q.day == calendarSel.days[d].day
      );
      for (let index = 0; index < roleList.length; index++) {
        let row = [];
        if (index == 0)
          row.push(
            dayName
              .filter((q) => q.id == calendarSel.days[d].anaDayId)[0]
              .name.substring(0, 3) +
              " " +
              calendarSel.days[d].day
          );
        else row.push("");
        row.push(roleList[index].roleName);
        for (let sh = 0; sh < calendarSel.days[d].shifts.length; sh++) {
          let userName = "";
          if (calendarSel.days[d].shifts[sh].users[index].anaUserId != 0)
            userName = subSet.filter(
              (q) =>
                q.anaUserId ==
                calendarSel.days[d].shifts[sh].users[index].anaUserId
            )[0].nameSurname;
          row.push(userName);
        }
        calandarString.push(row);
      }
    }
    //console.log(calandarString);
    let sendData = { id: -1, allRows: calandarString };
    await cicassService.downloadFilePost(
      apiUrl + "/download/" + calendarSel.anaCalendarId,
      sendData,
      "XLSX"
    );
    hideSpinner(document.getElementById("containerSpin"));
  };

  const resetChangeShift = () => {
    if (fromUserSel != null) {
      calendarSel.days[fromUserSel.day - 1].shifts[fromUserSel.shift].users[
        fromUserSel.user
      ].isSelected = false;
      setFromUserSel(null);
    }
    if (toUserSel != null) {
      calendarSel.days[toUserSel.day - 1].shifts[toUserSel.shift].users[
        toUserSel.user
      ].isSelected = false;
      setToUserSel(null);
    }
    setStepSel(1);
  };

  const changeShiftWithOtherUser = async () => {
    setIsChangeShiftSidebarVisible(!isChangeShiftSidebarVisible);
    toggleChangeShiftIcon(false);
    resetChangeShift();
  };

  // select From user
  const selectFromOrToUser = (
    skipSelectionForChange,
    day,
    shiftIdx,
    userIdy,
    nameSurname
  ) => {
    if (skipSelectionForChange) return;
    if (!isChangeShiftSidebarVisible) return;
    const loggedUser = auth.getUserId(jwt);
    if (stepSel === 1) {
      // From
      if (
        loggedUser !=
        calendarSel.days[day - 1].shifts[shiftIdx].users[userIdy].anaUserId
      )
        return;
      if (fromUserSel != null) {
        calendarSel.days[fromUserSel.day - 1].shifts[fromUserSel.shift].users[
          fromUserSel.user
        ].isSelected = false;
        if (
          day == fromUserSel.day &&
          shiftIdx == fromUserSel.shift &&
          userIdy == fromUserSel.user
        ) {
          setFromUserSel(null);
          setHtmlKeyForChangeShift("");
          return;
        }
      }

      // console.log(
      //   "SELECTION",
      //   calendarSel.days[day - 1].shifts[shiftIdx].users[userIdy]
      // );
      calendarSel.days[day - 1].shifts[shiftIdx].users[
        userIdy
      ].isSelected = true;
      let mySel = {
        nameSurname: nameSurname,
        shiftUserId:
          calendarSel.days[day - 1].shifts[shiftIdx].users[userIdy].shiftUserId,
        anaUserId:
          calendarSel.days[day - 1].shifts[shiftIdx].users[userIdy].anaUserId,
        anaUserRoleId:
          calendarSel.days[day - 1].shifts[shiftIdx].users[userIdy]
            .anaUserRoleId,
        day: day,
        shift: shiftIdx,
        user: userIdy,
      };
      setFromUserSel(mySel);
      setHtmlKeyForChangeShift(
        "hk_" + (day - 1) + "_" + shiftIdx + "_" + userIdy
      );
      setCalendarSel(calendarSel);
    }
    if (stepSel === 2) {
      // To
      if (
        loggedUser ==
          calendarSel.days[day - 1].shifts[shiftIdx].users[userIdy].anaUserId ||
        userIdy != fromUserSel.user
      ) {
        return;
      }

      if (toUserSel !== null) {
        calendarSel.days[toUserSel.day - 1].shifts[toUserSel.shift].users[
          toUserSel.user
        ].isSelected = false;

        if (
          day === toUserSel.day &&
          shiftIdx === toUserSel.shift &&
          userIdy === toUserSel.user
        ) {
          setToUserSel(null);
          setIsPersonManuallySelected(false);
          return;
        }
      }
      calendarSel.days[day - 1].shifts[shiftIdx].users[
        userIdy
      ].isSelected = true;

      let mySel = {
        nameSurname: nameSurname,
        shiftUserId:
          calendarSel.days[day - 1].shifts[shiftIdx].users[userIdy].shiftUserId,
        anaUserId:
          calendarSel.days[day - 1].shifts[shiftIdx].users[userIdy].anaUserId,
        anaUserRoleId:
          calendarSel.days[day - 1].shifts[shiftIdx].users[userIdy]
            .anaUserRoleId,
        day: day,
        shift: shiftIdx,
        user: userIdy,
      };
      setIsPersonManuallySelected(true);
      setToUserSel(mySel);
      setCalendarSel(calendarSel);
    }
  };

  // Change Shift Zone
  const saveChangeShift = async () => {
    let roleList = shiftSel[0].anaUserRoleList;

    let FromDescr =
      dayName
        .filter(
          (q) => q.id == calendarSel.days[fromUserSel.day - 1].anaDayId
        )[0]
        .name.substring(0, 3) +
      " " +
      fromUserSel.day;
    FromDescr =
      FromDescr +
      " " +
      (shiftSel[fromUserSel.shift].timeFrom.hours < 10 ? "0" : "") +
      shiftSel[fromUserSel.shift].timeFrom.hours +
      ":" +
      (shiftSel[fromUserSel.shift].timeFrom.minutes < 10 ? "0" : "") +
      shiftSel[fromUserSel.shift].timeFrom.minutes +
      " - " +
      (shiftSel[fromUserSel.shift].timeTo.hours < 10 ? "0" : "") +
      shiftSel[fromUserSel.shift].timeTo.hours +
      ":" +
      (shiftSel[fromUserSel.shift].timeTo.minutes < 10 ? "0" : "") +
      shiftSel[fromUserSel.shift].timeTo.minutes;
    FromDescr =
      FromDescr +
      " " +
      roleList.filter(function (q) {
        return q.anaUserRoleId == fromUserSel.anaUserRoleId;
      })[0].roleName;

    let ToDescr =
      dayName
        .filter((q) => q.id == calendarSel.days[toUserSel.day - 1].anaDayId)[0]
        .name.substring(0, 3) +
      " " +
      toUserSel.day;
    ToDescr =
      ToDescr +
      " " +
      (shiftSel[toUserSel.shift].timeFrom.hours < 10 ? "0" : "") +
      shiftSel[toUserSel.shift].timeFrom.hours +
      ":" +
      (shiftSel[toUserSel.shift].timeFrom.minutes < 10 ? "0" : "") +
      shiftSel[toUserSel.shift].timeFrom.minutes +
      " - " +
      (shiftSel[toUserSel.shift].timeTo.hours < 10 ? "0" : "") +
      shiftSel[toUserSel.shift].timeTo.hours +
      ":" +
      (shiftSel[toUserSel.shift].timeTo.minutes < 10 ? "0" : "") +
      shiftSel[toUserSel.shift].timeTo.minutes;
    ToDescr =
      ToDescr +
      " " +
      roleList.filter((q) => q.anaUserRoleId == toUserSel.anaUserRoleId)[0]
        .roleName;

    let dataToSave = {
      id: -1,
      fromShiftUserId: fromUserSel.shiftUserId,
      fromAnaUserId: fromUserSel.anaUserId,
      toShiftUserId: toUserSel.shiftUserId,
      toAnaUserId: toUserSel.anaUserId,
      requestTypeId: fromUserSel.shiftUserId === toUserSel.shiftUserId ? 2 : 1,
      ResponseTypeId: 0,
      CreatedAt: new Date(),
      fromDescription: FromDescr + " " + fromUserSel.nameSurname,
      toDescription: ToDescr + " " + toUserSel.nameSurname,
      yearSel: calendarSel.year,
      monthSel: calendarSel.month,
    };
    // console.log("Trying to change shift", dataToSave);
    await cicassService.saveOp(apiUrl + "/changerequest", dataToSave);

    toastObj.title = props.t("calendarChangeShiftTitle"); // CAMBIO TURNO
    toastObj.content = props.t("calendarChangeShiftContent"); // Richiesta inviata
    toastObj.cssClass = "e-toast-info";
    toastObj.icon = "e-info toast-icons";
    toastObj.show();
    setIsPersonManuallySelected(false);
    resetChangeShift();
    stepperRef?.current?.reset();
    toggleChangeShiftIcon(false);
    fetchShiftRequestList();
  };

  function handleShiftChangeDropdown(event) {
    if (!event) return;
    const { itemData } = event;

    let userSelection = {
      nameSurname: itemData.nameSurname,
      shiftUserId: fromUserSel.shiftUserId,
      anaUserId: itemData.anaUserId,
      anaUserRoleId: itemData.anaUserRoleId,
      day: itemData.day,
      shift: availabilitySubsetForRequestChange.shiftIndex,
      user: availabilitySubsetForRequestChange.userIndex,
    };

    setShiftUserSelection(userSelection);
  }

  function toggleChangeShiftIcon(bool) {
    let idxArray = htmlKeyForChangeShift.replace("hk_", "").split("_");
    if (!calendarSel.days[idxArray[0]]) return;

    let copyData = calendarSel;
    copyData.days[idxArray[0]].shifts[idxArray[1]].users[
      idxArray[2]
    ].showChangeRequest = bool;
    setCalendarSel(copyData);
  }

  function handleStepperPrevStep() {
    setStepSel(stepSel - 1);
    if (stepSel === 2) {
      setIsPersonManuallySelected(false);
      if (toUserSel) {
        calendarSel.days[toUserSel.day - 1].shifts[toUserSel.shift].users[
          toUserSel.user
        ].isSelected = false;
        setCalendarSel(calendarSel);
      }

      setToUserSel(null);
      toggleChangeShiftIcon(false);
      setStepperAdditionalInfo(null);
    }
  }

  function handleConfirmShiftChange() {
    if (!shiftUserSelection.nameSurname) return;
    setIsChangeShiftRequestConfirmDialogOpen(false);
    setToUserSel(shiftUserSelection);
    setStepperAdditionalInfo(shiftUserSelection.nameSurname);
  }

  return (
    <>
      {isFetchingCompleted && (
        <div className="flex-container-cal" style={{ marginTop: "1%" }}>
          <div className="flex-row-cal">
            <h2 className="text-gray-800 text-center font-semibold">
              {calData && calData.name}{" "}
            </h2>
            {calendarSel && isEditEnabled && (
              <small>
                ({props.t("calendarAct")}{" "}
                {isPublished === 0
                  ? props.t("calendarPubS0")
                  : props.t("calendarPubA0")}
                )
              </small>
            )}
            {calendarSel && isPublished !== null && isEditEnabled === false && (
              <>
                {isPublished === 1 && (
                  <>
                    {!hasDateExpired() && (
                      <div style={{ padding: "5px" }}>
                        <ButtonComponent
                          style={{ fontSize: "smaller" }}
                          cssClass="e-info e-outline e-small e-round"
                          iconCss="fas fa-users"
                          title={props.t("calendarChaTool")}
                          onClick={() => changeShiftWithOtherUser()}
                        />
                      </div>
                    )}
                    <div style={{ padding: "5px" }}>
                      <ButtonComponent
                        style={{ fontSize: "smaller" }}
                        cssClass="e-info e-outline e-small e-round"
                        title={props.t("pendingShiftRequests")}
                        onClick={() => setOpenCL(true)}
                      >
                        <i className="fas fa-inbox" />
                        <span>{getNumberOfPendingShiftRequests() || ""}</span>
                      </ButtonComponent>
                    </div>
                    <div style={{ padding: "5px" }}>
                      <ButtonComponent
                        style={{ fontSize: "smaller" }}
                        cssClass="e-info e-outline e-small e-round"
                        iconCss="fas fa-sync-alt"
                        title={props.t("refreshCalendar")}
                        onClick={() => refreshCalendar()}
                      />
                    </div>
                    {eventCalendarList.length > 0 && (
                      <add-to-calendar-button
                        name="Turno 112"
                        dates={JSON.stringify(eventCalendarList)}
                        timeZone="Europe/Rome"
                        options="'Apple','Google','iCal'"
                        hideTextLabelButton
                        buttonStyle="round"
                        size="1"
                        language="it"
                        lightMode="bodyScheme"
                        styleLight="--btn-background: transparent;--btn-border:#0378d5;--btn-text:#0378d5;--btn-background-hover: #0378d5;--btn-text-hover:#fff;--btn-shadow: none;"
                        inline="true"
                      />
                    )}
                  </>
                )}
              </>
            )}
            {calendarSel && isPublished !== null && isEditEnabled === true && (
              <>
                {isPublished === 0 && (
                  <div style={{ padding: "5px" }}>
                    <ButtonComponent
                      style={{ fontSize: "smaller" }}
                      cssClass="e-success e-outline e-small e-round"
                      iconCss="fas fa-save"
                      title={props.t("calendarPubTool")}
                      onClick={() => changeShiftStatus(1)}
                    />
                  </div>
                )}
                {isPublished === 1 && (
                  <div style={{ padding: "5px" }}>
                    <ButtonComponent
                      style={{ fontSize: "smaller" }}
                      cssClass="e-danger e-outline e-small e-round"
                      iconCss="fas fa-pause"
                      title={props.t("calendarSusTool")}
                      onClick={() => changeShiftStatus(0)}
                    />
                  </div>
                )}
              </>
            )}
            {calendarSel && isEditEnabled === true && (
              <>
                <div style={{ padding: "5px" }}>
                  <ButtonComponent
                    style={{ fontSize: "smaller" }}
                    cssClass="e-info e-outline e-small e-round"
                    iconCss="fas fa-user-tag"
                    title={props.t("calendarUserList")}
                    onClick={() => setOpen(true)}
                  />
                </div>
                <div style={{ padding: "5px" }}>
                  <ButtonComponent
                    style={{ fontSize: "smaller" }}
                    cssClass="e-secondary e-outline e-small e-round"
                    iconCss="fas fa-print"
                    title={props.t("calendarPrint")}
                    onClick={() => printOnFile()}
                  />
                </div>
              </>
            )}
            {calendarSel && editor && (
              <div style={{ padding: "5px" }} className="headerLine">
                <ButtonComponent
                  style={{ fontSize: "smaller" }}
                  cssClass="e-warning e-outline e-small e-round"
                  iconCss="fas fa-edit"
                  title={
                    isEditEnabled
                      ? props.t("calendarEditorR")
                      : props.t("calendarEditorW")
                  }
                  onClick={() => {
                    setEditEnabled(!isEditEnabled);
                  }}
                />
              </div>
            )}
            <div className="flex-data-picker-cal" style={{ padding: "5px" }}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={itLocale}
              >
                <DatePicker
                  views={["year", "month"]}
                  minDate={new Date("2022-01-01")}
                  maxDate={maxDate}
                  value={defaultDate}
                  onChange={onMonthChange}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
              </LocalizationProvider>
            </div>
          </div>

          <div className="flex-row-cal" id="flexRowUpdate">
            <cite style={{ fontSize: "x-small" }}>
              {props.t("lastUpdate")}: {lastUpdate}
            </cite>
          </div>
          {/* Shift change stepper */}
          <div className="flex-row-cal" id="printCal">
            {isChangeShiftSidebarVisible && (
              <ChangeShiftStepper
                ref={stepperRef}
                fromUser={fromUserSel}
                toUser={toUserSel}
                additionalInfo={stepperAdditionalInfo}
                nextStep={() => {
                  setStepSel(stepSel + 1);
                  if (stepSel === 1) {
                    toggleChangeShiftIcon(true);
                  }
                }}
                prevStep={() => handleStepperPrevStep()}
                resetStep={() => resetChangeShift()}
                finalStep={() => saveChangeShift()}
              />
            )}
            {calendarSel && shiftSel && availabilitySel && printCalendar()}
          </div>

          <ToastComponent
            id="toast_default"
            ref={toastRef}
            position={{ X: "Right", Y: "Bottom" }}
            title={toastInfo.title}
            content={toastInfo.content}
            cssClass={toastInfo.cssClass}
            icon={toastInfo.icon}
          />
          <ToastComponent
            ref={(toast) => {
              setToastObj(toast);
            }}
            id="toast_default"
            position={{ X: "Right", Y: "Bottom" }}
          />
        </div>
      )}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {props.t("calendarUserList")}
        </DialogTitle>
        <DialogContent>
          {monthlyCheckData && isEditEnabled && (
            <div>
              <GridComponent
                id="gridcomp"
                dataSource={monthlyCheckData}
                height={550}
                pageSettings={{ pageSize: 50 }}
                allowFiltering={true}
                enableInfiniteScrolling={true}
                infiniteScrollSettings={{ initialBlocks: 5 }}
                locale="it"
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="nameSurname"
                    headerText={props.t("calendarNameSurname")}
                  />
                  <ColumnDirective
                    field="monthProgressive"
                    headerText={props.t("calendarMonthProgressive")}
                  />
                  <ColumnDirective
                    field="monthMaxDescr"
                    headerText={props.t("calendarMaxDescr")}
                  />
                </ColumnsDirective>
                <InjectGC services={[Filter, Page, InfiniteScroll]} />
              </GridComponent>
            </div>
          )}
        </DialogContent>
      </Dialog>
      {/* Force shift dialog */}
      <AssignShiftDialog
        dataSource={availabilityForceSel}
        titleContent={forceShiftSel}
        onChange={onForcedUserChanged}
        onOpen={onUserForcedOpen}
        onSubmit={forceActualShift}
        translate={props.t}
        isOpen={showForceDialog}
        handleClose={() => setShowForceDialog(false)}
      />
      {/* Sent/recieved shift requests dialog*/}
      <SentAndRecievedRequestsDialog
        outRequestDataSource={outRequestList}
        inRequestDataSource={inRequestList}
        onCommandClick={onCommandClick}
        senderRowDataBound={senderRowDataBound}
        recieverRowDataBound={recieverRowDataBound}
        handleClose={() => setOpenCL(false)}
        isOpen={openCL}
        translate={props.t}
      />
      {/* Shift change confirmation dialog */}
      <Dialog
        open={isShiftChangeConfirmDialogOpen}
        onClose={() => setIsShiftChangeConfirmDialogOpen(false)}
      >
        <DialogTitle>
          {props.t("shiftChangeConfirmationDialogTitle")}
        </DialogTitle>
        <DialogContent>
          <ButtonComponent
            style={{ marginLeft: "1rem" }}
            cssClass="e-control e-btn e-lib e-primary e-flat"
            onClick={() => handleChangeShiftRequest()}
          >
            {props.t("okBtn")}
          </ButtonComponent>
          <ButtonComponent
            style={{ marginLeft: "1rem" }}
            cssClass="e-control e-btn e-lib e-flat"
            onClick={() => setIsShiftChangeConfirmDialogOpen(false)}
          >
            {props.t("cancelBtn")}
          </ButtonComponent>
        </DialogContent>
      </Dialog>
      {/* Shift change => Request on selected shift, role, day*/}
      <ChangeShiftRequestConfirmDialog
        dataSource={availabilitySubsetForRequestChange.dataSource}
        isOpen={isChangeShiftRequestConfirmDialogOpen}
        isEditEnabled={isEditEnabled}
        handleDropdownChange={handleShiftChangeDropdown}
        handleConfirmation={handleConfirmShiftChange}
        handleClose={() => setIsChangeShiftRequestConfirmDialogOpen(false)}
        translate={props.t}
      />
      &nbsp;
      <div
        id="containerSpin"
        className="control-section col-sm-12 spinner-target"
        style={{ position: "absolute", left: "50%", top: "50%" }}
      />
    </>
  );
}

export default withTranslation()(CalXlsPage);
