import React, { useLayoutEffect, useState } from "react";
import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { cicassService } from "../../../_services";

import "./home.css";

const fields = { value: "id", text: "name" };
const apiEndpointGetAttendanceType = "ana_attendance_type";
const apiEndpointGetLastAttendance = "attendance/last";

const AttendanceDialog = props => {
  const { isOpen, handleClose, onSubmit, translate, isSubmitDisabled } = props;

  const [attendanceCauseOptions, setAttendanceCauseOptions] = useState([]);
  const [tempAttendanceCause, setTempAttendanceCause] = useState("");
  const [tempAttendanceNote, setTempAttendanceNote] = useState("");
  const [lastAttendance, setLastAttendance] = useState({
    cause: "",
    note: "",
  });

  useLayoutEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const { data: attendanceCauseOptns } = await cicassService.getAll(
      apiEndpointGetAttendanceType
    );
    setAttendanceCauseOptions(attendanceCauseOptns);

    let lastAttendanceResponse = await cicassService.getAll(
      apiEndpointGetLastAttendance
    );
    lastAttendanceResponse = lastAttendanceResponse.data;
    // console.log(lastAttendanceResponse);

    if (lastAttendanceResponse.toDt === null) {
      const lastAttendanceId = lastAttendanceResponse.anaAttendanceTypeId;

      const lastAttendanceCause = attendanceCauseOptns.find(
        item => item.id === lastAttendanceId
      ).name;

      setLastAttendance({
        cause: lastAttendanceCause,
        note: lastAttendanceResponse.attendanceNote,
      });

      setTempAttendanceNote(lastAttendanceResponse.attendanceNote);
      setTempAttendanceCause({
        cause: lastAttendanceCause,
        id: lastAttendanceId,
      });
    } else {
      setLastAttendance({
        cause: "",
        note: "",
      });
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}>
      <DialogTitle
        component="h3"
        sx={{
          color: "black",
          fontWeight: "bold",
          fontSize: "1.2rem",
        }}>
        Timbra{" ("}
        {lastAttendance.cause === ""
          ? translate("attendanceEntrance")
          : translate("attendanceExit")}
        {")"}
      </DialogTitle>
      <DialogContent
        sx={{
          padding: "2rem",
        }}>
        <div className="wrapper-div">
          <Typography>{translate("anaAttendanceTypeId")}</Typography>
          <ComboBoxComponent
            dataSource={attendanceCauseOptions}
            fields={fields}
            placeholder="..."
            autofill={true}
            value={lastAttendance.cause ?? "112"}
            allowFiltering={true}
            change={event => setTempAttendanceCause(event.itemData)}
            required
          />
        </div>

        <div className="wrapper-div">
          <Typography>{translate("attendanceNote")}</Typography>
          <TextBoxComponent
            onChange={event => setTempAttendanceNote(event.value)}
            multiline
            value={lastAttendance.note}
          />
        </div>
        <ButtonComponent
          cssClass="e-control e-btn e-lib e-primary e-flat"
          disabled={isSubmitDisabled}
          onClick={() => {
            onSubmit(tempAttendanceCause.id, tempAttendanceNote);
            fetchData();
          }}>
          {translate("okBtn")}
        </ButtonComponent>
        <ButtonComponent
          cssClass="e-control e-btn e-lib e-flat"
          onClick={handleClose}>
          {translate("cancelBtn")}
        </ButtonComponent>
      </DialogContent>
    </Dialog>
  );
};

export default AttendanceDialog;
