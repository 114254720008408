import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Page,
  Inject,
  Resize,
  Edit,
  Sort,
  CommandColumn,
  ForeignKey,
  Filter,
  Toolbar,
  ExcelExport,
} from "@syncfusion/ej2-react-grids";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import { auth, cicassService } from "../../../_services";
import React from "react";
import { withTranslation } from "react-i18next";
import TokenContext from "components/TokenContext";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import TextField from "@mui/material/TextField";
import {
  AccordionComponent,
  AccordionItemDirective,
  AccordionItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import "./availability.css";

const editSettings = {
  allowEditing: false,
  allowAdding: true,
  allowDeleting: true,
  newRowPosition: "Top",
  mode: "Dialog",
  dialog: { enableResize: true },
  showDeleteConfirmDialog: true,
};
const editSettingsDisabled = {
  allowEditing: false,
  allowAdding: false,
  allowDeleting: false,
  newRowPosition: "Top",
  showDeleteConfirmDialog: true,
};
const filterSettings = { type: "CheckBox" }; // Menu, Excel
const commands = [
  {
    type: "Delete",
    buttonOption: { iconCss: "e-icons e-delete", cssClass: "e-flat" },
  },
  {
    type: "Save",
    buttonOption: { iconCss: "e-icons e-update", cssClass: "e-flat" },
  },
  {
    type: "Cancel",
    buttonOption: { iconCss: "e-icons e-cancel-icon", cssClass: "e-flat" },
  },
];
const toolbarOptions = [
  "Search",
  "Add",
  "ExcelExport",
  "Print",
  {
    text: "Refresh",
    tooltipText: "Refresh",
    prefixIcon: "e-MT_reload",
    id: "forceRefresh",
  },
];
const toolbarHOptions = [
  "Search",
  "ExcelExport",
  "Print",
  {
    text: "Refresh",
    tooltipText: "Refresh",
    prefixIcon: "e-MT_reload",
    id: "forceRefresh",
  },
];
const toolbarAllOptions = ["Search", "ExcelExport", "Print"];
const apiUrl = "availability";
const apiUrShiftCombo = "ana_shift/combo";
const apiUrDayCombo = "ana_day/combo";
const apiUrlUserCombo = "authlogin";
const apiUrlSetting = "setting";

class Availability extends React.Component {
  _isMounted = false;
  grid = null;
  gridH = null;
  gridAll = null;
  static contextType = TokenContext;
  constructor(props) {
    super(props);
    this.position = { X: "Right", Y: "Bottom" };
    this.state = {
      myOriginalData: [],
      myData: [],
      myDataHistory: [],
      visibleOnGrid: false,
      didMountCompleted: false,
      isCicass: false,
      isAdmin: false,
      agencyData: [],
      shiftData: [],
      dayData: [],
      userData: [],
      allUser: [],
      availabilityDescr: "Personali",
      myAvailabilty: true,
      toastInfo: {
        title: "",
        content: "Succesfully",
        cssClass: "e-toast-success",
        icon: "e-success toast-icons",
      },
      disableMan: false,
      disableFrom: 0,
      disableAuto: false,
      disableTo: 0,
      disableOperation: false,
      enableHistory: false,
    };
    this.validationRules = { required: true };
    this.actionComplete = this.actionComplete.bind(this);
    this.actionBegin = this.actionBegin.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.settingContent = this.settingContent.bind(this);
    this.switchMan = this.switchMan.bind(this);
    this.switchAuto = this.switchAuto.bind(this);
    this.switchHistory = this.switchHistory.bind(this);
    this.switchAvailability = this.switchAvailability.bind(this);
    this.testRef = React.createRef();
  }

  async componentDidMount() {
    this._isMounted = true;
    const myrole = auth.getUserRole();
    const weblang = localStorage.getItem("weblang");
    const token = this.context;
    const id = auth.getUserId(token.jwt);
    let { data: myData } = await cicassService.getAll(apiUrl);
    let { data: shiftData } = await cicassService.getAll(apiUrShiftCombo);
    let { data: dayData } = await cicassService.getAll(
      apiUrDayCombo + "/" + weblang
    );
    let { data: userData } = await cicassService.getAll(
      apiUrlUserCombo + "/all"
    );
    let { data: disableManObj } = await cicassService.getAll(
      apiUrlSetting + "/DisableAvailability"
    );
    let { data: disableFromObj } = await cicassService.getAll(
      apiUrlSetting + "/DisableAvailabilityFromDay"
    );
    let { data: disableToObj } = await cicassService.getAll(
      apiUrlSetting + "/DisableAvailabilityToDay"
    );
    let myOriginalData = myData.slice();
    let filterData = myOriginalData.filter((q) => q.anaUserId == id);
    myData = filterData.slice();

    if (this._isMounted) {
      let disableMan = disableManObj.value == "1" ? true : false;
      let disableFrom = parseInt(disableFromObj.value);
      let disableTo = parseInt(disableToObj.value);
      let disableAuto = disableFrom > 0 ? true : false;
      let objToday = new Date();
      let actDay = objToday.getDate();
      let disableOperation = this.checkDisableOperation(
        disableMan,
        disableAuto,
        disableFrom,
        disableTo,
        actDay
      );

      myData.sort(function (a, b) {
        return a.anaDayId - b.anaDayId;
      });
      this.setState({
        didMountCompleted: true,
        myOriginalData,
        myData,
        shiftData,
        dayData,
        userData,
        disableMan,
        disableFrom,
        disableAuto,
        disableTo,
        disableManObj,
        disableFromObj,
        disableToObj,
        disableOperation,
        isCicass: myrole == "SuperUser" ? true : false,
        isAdmin:
          myrole == "Admin" || myrole == "SuperUser" || myrole == "Manager"
            ? true
            : false,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toolbarClick(args) {
    switch (args.item.properties.id) {
      case "gridcomp_excelexport":
        this.gridInstance.excelExport();
        break;
      default:
        break;
    }
    if (args.item.id === "forceRefresh") {
      this.refreshGrid();
    }
  }

  toolbarHClick(args) {
    switch (args.item.properties.id) {
      case "gridcomph_excelexport":
        this.gridInstance.excelExport();
        break;
      default:
        break;
    }
    if (args.item.id === "forceRefresh") {
      this.refreshGrid();
    }
  }

  toolbarAllClick(args) {
    switch (args.item.properties.id) {
      case "gridcompall_excelexport":
        this.gridInstance.excelExport();
        break;
      default:
        break;
    }
  }
  async refreshGrid() {
    await cicassService
      .getAll(apiUrl)
      .then((response) => {
        const token = this.context;
        const id = auth.getUserId(token.jwt);
        let myOriginalData = response.data.slice();
        let myData = myOriginalData.filter((q) => q.anaUserId == id);
        this.setState({ myData, myOriginalData });
      })
      .catch((error) => {
        console.error("Error on refresh grid: ", error);
      });
  }

  actionBegin(state) {
    if (state.requestType === "beginEdit" || state.requestType === "add") {
      if (state.requestType === "add") {
        state.id = -1;
        state.data.anaAgencyId = auth.getAgencyId();
        const token = this.context;
        state.data.anaUserId = auth.getUserId(token.jwt);
      }
      this.setState({ visibleOnGrid: true });
    }
  }

  actionComplete(state) {
    /*if (state.requestType === "add" || state.requestType === "beginEdit") {
      state.dialog.enableResize = true;
      //state.dialog.element.style.maxHeight = 900;
      state.dialog.width = 600;
      state.dialog.height = 600;
    }*/
    const { t } = this.props;
    if (state.action === "add") {
      state.data.id = -1;
      state.data.createdAt = new Date();
      delete state.data.anaAgencyId;
      delete state.data.userRoleList;
      delete state.data.mobile;
      cicassService
        .saveOp(apiUrl, state.data)
        .then(() => {
          this.setToastInfo("Success", t("addToastOK"));
        })
        .catch((err) => {
          console.error("Error on Add: ", err);
          this.setToastInfo("Error", "I: " + err.message);
        })
        .finally(() => {
          this.refreshGrid();
        });
    }
    if (state.action === "edit") {
      delete state.data.anaAgencyId;
      delete state.data.userRoleList;
      delete state.data.mobile;
      cicassService
        .saveOp(apiUrl, state.data)
        .then(() => {
          this.setToastInfo("Information", t("editToastOK"));
        })
        .catch((err) => {
          console.error("Error on Edit: ", err);
          this.setToastInfo("Error", "U: " + err.message);
        })
        .finally(() => {
          this.refreshGrid();
        });
    }
    if (state.requestType === "delete") {
      cicassService
        .deleteOp(apiUrl, state.data[0].id)
        .then(() => {
          this.setToastInfo("Warning", t("deleteToastOK"));
        })
        .catch((err) => {
          console.error("Error on Delete: ", err);
          this.setToastInfo("Error", "D:" + err.message);
        })
        .finally(() => {
          this.refreshGrid();
        });
    }

    this.setState({ visibleOnGrid: false });
  }

  setToastInfo(title, errorMsg) {
    let { toastInfo } = this.state;
    switch (title) {
      case "Warning":
        toastInfo.title = "Warning";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-warning";
        toastInfo.icon = "e-warning toast-icons";
        break;
      case "Success":
        toastInfo.title = "Success";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-success";
        toastInfo.icon = "e-success toast-icons";
        break;
      case "Error":
        toastInfo.title = "Error";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-danger";
        toastInfo.icon = "e-error toast-icons";
        break;
      case "Information":
        toastInfo.title = "Information";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-info";
        toastInfo.icon = "e-info toast-icons";
        break;

      default:
        break;
    }
    this.setState({ toastInfo });
    this.toastObj.show();
    return;
  }

  switchAvailability() {
    const token = this.context;
    const id = auth.getUserId(token.jwt);
    let { myAvailabilty } = this.state;
    myAvailabilty = !myAvailabilty;
    let availabilityDescr = myAvailabilty ? "Personali" : "Tutte";
    this.setState({ myAvailabilty, availabilityDescr });
  }

  async switchHistory() {
    const { enableHistory } = this.state;

    try {
      const response = await cicassService.getAll(
        apiUrl + (enableHistory ? "" : "/history")
      );
      if (this._isMounted) {
        if (!enableHistory) {
          this.setState({ myDataHistory: response.data });
        } else {
          this.setState({ myData: response.data });
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      this.setState({ enableHistory: !enableHistory });
    }
  }

  switchMan(e) {
    let { disableMan, disableAuto, disableFrom, disableTo, disableManObj } =
      this.state;
    let objToday = new Date();
    let actDay = objToday.getDate();
    disableMan = !disableMan;
    disableManObj.value = disableMan ? "1" : "0";
    cicassService
      .saveOp(apiUrlSetting, disableManObj)
      .then(() => {
        this.setToastInfo("Success", this.props.t("toastSuccess"));
      })
      .catch((err) => {
        this.setToastInfo("Error", "U: " + err.message);
      });
    let disableOperation = this.checkDisableOperation(
      disableMan,
      disableAuto,
      disableFrom,
      disableTo,
      actDay
    );
    this.setState({ disableMan, disableOperation });
  }

  switchAuto(e) {
    let {
      disableMan,
      disableAuto,
      disableFrom,
      disableTo,
      disableFromObj,
      disableToObj,
    } = this.state;
    let objToday = new Date();
    let actDay = objToday.getDate();
    disableAuto = !disableAuto;
    disableFrom = disableAuto ? 1 : 0;
    disableFromObj.value = disableFrom.toString();
    disableTo = disableAuto ? 1 : 0;
    disableToObj.value = disableTo.toString();

    cicassService.saveOp(apiUrlSetting, disableFromObj).catch((err) => {
      this.setToastInfo("Error", "U: " + err.message);
    });

    cicassService
      .saveOp(apiUrlSetting, disableToObj)
      .then(() => {
        this.setToastInfo("Success", this.props.t("toastSuccess"));
      })
      .catch((err) => {
        this.setToastInfo("Error", "U: " + err.message);
      });

    let disableOperation = this.checkDisableOperation(
      disableMan,
      disableAuto,
      disableFrom,
      disableTo,
      actDay
    );
    this.setState({ disableAuto, disableFrom, disableTo, disableOperation });
  }

  onTxtChange(event, from) {
    let stateCopy = structuredClone(this.state);
    let {
      disableMan,
      disableAuto,
      disableFrom,
      disableTo,
      disableFromObj,
      disableToObj,
      disableOperation,
    } = stateCopy;
    let currentValue = parseInt(event.target.value);
    let actDay = new Date().getDate();
    if (currentValue < 0 || currentValue > 31) return;

    if (currentValue === 0) {
      disableAuto = false;
      disableFrom = 0;
      disableTo = 0;
    } else {
      disableAuto = true;
      if (from === "first") {
        disableFrom = currentValue;
        if (disableTo < disableFrom) disableTo = disableFrom;
        if (disableTo === 0) disableTo = currentValue;
      } else {
        disableTo = currentValue;
        if (disableTo < disableFrom) disableFrom = disableTo;
        if (disableFrom === 0) disableFrom = 1;
      }
    }
    disableToObj.value = disableTo.toString();
    disableFromObj.value = disableFrom.toString();

    cicassService
      .saveOp(apiUrlSetting, disableFromObj)
      .catch((err) => this.setToastInfo("Error", "U: " + err.message));

    cicassService.saveOp(apiUrlSetting, disableToObj).catch((err) => {
      this.setToastInfo("Error", "U: " + err.message);
    });
    disableOperation = this.checkDisableOperation(
      disableMan,
      disableAuto,
      disableFrom,
      disableTo,
      actDay
    );
    this.setState({ disableAuto, disableTo, disableFrom, disableOperation });
  }

  checkDisableOperation(
    disableMan,
    disableAuto,
    disableFrom,
    disableTo,
    actDay
  ) {
    if (disableMan) return true;
    else {
      if (disableAuto) {
        if (disableFrom <= actDay && disableTo >= actDay) return true;
      } else return false;
    }
  }

  settingContent(man, auto, from, to, history) {
    let { disableMan, disableFrom, disableTo, enableHistory } = this.state;
    let disableAuto = false;
    if (disableFrom > 0 || disableTo > 0) disableAuto = true;
    return (
      <div className="control-section input-content-wrapper">
        <div className="row custom-margin">
          <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
            <SwitchComponent checked={disableMan} onChange={this.switchMan} />
          </div>
          <div className="col-xs-10 col-sm-10 col-lg-10 col-md-10">
            &nbsp;{man}
          </div>
        </div>
        <div className="row custom-margin">
          <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 mt-3">
            <SwitchComponent checked={disableAuto} onChange={this.switchAuto} />
          </div>
          <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4 mt-3">
            &nbsp;{auto}&nbsp;
          </div>
          <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1 mt-3">
            , {from}&nbsp;
          </div>
          <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
            <TextField
              type="number"
              value={disableFrom}
              onChange={(e) => this.onTxtChange(e, "first")}
              variant="standard"
            />
          </div>
          <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1 mt-3">
            &nbsp;{to}&nbsp;
          </div>
          <div className="col-xs-3 col-sm-2 col-lg-2 col-md-2">
            <TextField
              type="number"
              value={disableTo}
              onChange={(e) => this.onTxtChange(e, "second")}
              variant="standard"
            />
          </div>
        </div>
        <div className="row custom-margin">
          <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
            <SwitchComponent
              defaultValue={false}
              checked={enableHistory}
              onChange={this.switchHistory}
            />
          </div>
          <div className="col-xs-10 col-sm-10 col-lg-10 col-md-10">
            &nbsp;{history}
          </div>
        </div>
      </div>
    );
  }

  render() {
    const {
      myAvailabilty,
      myData,
      myOriginalData,
      didMountCompleted,
      dayData,
      shiftData,
      userData,
      isCicass,
      toastInfo,
      isAdmin,
      disableOperation,
      disableFrom,
      disableTo,
      disableMan,
      enableHistory,
      myDataHistory,
      availabilityDescr,
    } = this.state;
    const { t } = this.props;
    return (
      <>
        <div style={{ margin: "1.2rem 0 .5rem 1.2rem" }}>
          <h2 className="text-gray-800 text-xl font-semibold">
            {t("titleAvailabilityJs")}
            {didMountCompleted &&
              !disableMan &&
              " (" +
                t("lockInsert") +
                " " +
                disableFrom +
                " " +
                t("lockTo") +
                " " +
                disableTo +
                " " +
                t("lockEveryMonth") +
                ")"}
            {didMountCompleted && disableMan && " (" + t("lockNow") + ")"}
          </h2>
          {!isAdmin && (
            <div style={{ paddingLeft: ".1rem" }}>
              <label htmlFor="myAva" style={{ padding: "10px 10px 10px 0" }}>
                - {t("personalOnly")}
              </label>
              <SwitchComponent
                id="myAva"
                checked={myAvailabilty}
                onChange={this.switchAvailability}
              />
            </div>
          )}
        </div>
        <div className="p-4 flex-auto">
          {didMountCompleted && isAdmin && (
            <AccordionComponent>
              <AccordionItemsDirective>
                <AccordionItemDirective
                  header={t("settingAvailabilityJs")}
                  content={() =>
                    this.settingContent(
                      t("disableAvailabilityManJs"),
                      t("disableAvailabilityAutoJs"),
                      t("fromDay"),
                      t("toDay"),
                      t("showHistory")
                    )
                  }
                />
              </AccordionItemsDirective>
            </AccordionComponent>
          )}
          <div>
            {didMountCompleted &&
              !enableHistory &&
              myAvailabilty &&
              !isAdmin && (
                <GridComponent
                  id="gridcomp"
                  ref={(grid) => (this.gridInstance = grid)}
                  dataSource={myData}
                  toolbar={toolbarOptions}
                  height="100%"
                  editSettings={
                    disableOperation ? editSettingsDisabled : editSettings
                  }
                  allowResizing={true}
                  allowSorting={true}
                  allowFiltering={true}
                  filterSettings={filterSettings}
                  pageSettings={{ pageSize: 10, pageCount: 5 }}
                  allowExcelExport={true}
                  actionComplete={this.actionComplete}
                  actionBegin={this.actionBegin}
                  toolbarClick={this.toolbarClick.bind(this)}
                  locale="it"
                  enableInfiniteScrolling={false}
                  allowPaging={true}
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      field="id"
                      headerText={t("id")}
                      textAlign="Right"
                      isPrimaryKey={true}
                      defaultValue="-1"
                      allowEditing={false}
                      visible={isCicass}
                    />
                    <ColumnDirective
                      field="anaDayId"
                      headerText={t("anaDayId")}
                      foreignKeyValue="name"
                      foreignKeyField="id"
                      dataSource={dayData}
                      width="150"
                      validationRules={this.validationRules}
                    />
                    <ColumnDirective
                      field="anaShiftId"
                      headerText={t("anaShiftId")}
                      foreignKeyValue="name"
                      foreignKeyField="id"
                      dataSource={shiftData}
                      width="150"
                      validationRules={this.validationRules}
                    />
                    <ColumnDirective
                      field="anaUserId"
                      headerText={t("anaUserId")}
                      foreignKeyValue="name"
                      foreignKeyField="id"
                      dataSource={userData}
                      width="300"
                      visible={isAdmin}
                      validationRules={this.validationRules}
                    />
                    <ColumnDirective
                      field="userRoleList"
                      headerText={t("userRoleList")}
                      allowEditing={false}
                    />
                    <ColumnDirective
                      field="anaAgencyId"
                      headerText={t("anaAgencyId")}
                      allowEditing={false}
                      visible={isCicass}
                    />
                    <ColumnDirective
                      field="createdAt"
                      headerText={t("createdAt")}
                      type="date"
                      format={"dd/MM/yyyy HH:mm:ss"}
                      visible={isAdmin}
                      allowEditing={false}
                    />
                    <ColumnDirective
                      headerText={t("operationOnGrid")}
                      commands={commands}
                    />
                  </ColumnsDirective>
                  <Inject
                    services={[
                      Resize,
                      Filter,
                      Page,
                      Toolbar,
                      Edit,
                      ForeignKey,
                      CommandColumn,
                      ExcelExport,
                      Sort,
                    ]}
                  />
                </GridComponent>
              )}
            {didMountCompleted &&
              !enableHistory &&
              myAvailabilty &&
              isAdmin && (
                <GridComponent
                  id="gridcomp"
                  ref={(grid) => (this.gridInstance = grid)}
                  dataSource={myOriginalData}
                  toolbar={toolbarOptions}
                  height="100%"
                  editSettings={
                    disableOperation ? editSettingsDisabled : editSettings
                  }
                  allowResizing={true}
                  allowSorting={true}
                  allowFiltering={true}
                  filterSettings={filterSettings}
                  pageSettings={{ pageSize: 10, pageCount: 5 }}
                  allowExcelExport={true}
                  actionComplete={this.actionComplete}
                  actionBegin={this.actionBegin}
                  toolbarClick={this.toolbarClick.bind(this)}
                  locale="it"
                  enableInfiniteScrolling={false}
                  allowPaging={true}
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      field="id"
                      headerText={t("id")}
                      textAlign="Right"
                      isPrimaryKey={true}
                      defaultValue="-1"
                      allowEditing={false}
                      visible={isCicass}
                    />
                    <ColumnDirective
                      field="anaDayId"
                      headerText={t("anaDayId")}
                      foreignKeyValue="name"
                      foreignKeyField="id"
                      dataSource={dayData}
                      width="150"
                      validationRules={this.validationRules}
                    />
                    <ColumnDirective
                      field="anaShiftId"
                      headerText={t("anaShiftId")}
                      foreignKeyValue="name"
                      foreignKeyField="id"
                      dataSource={shiftData}
                      width="150"
                      validationRules={this.validationRules}
                    />
                    <ColumnDirective
                      field="anaUserId"
                      headerText={t("anaUserId")}
                      foreignKeyValue="name"
                      foreignKeyField="id"
                      dataSource={userData}
                      width="300"
                      visible={isAdmin}
                      validationRules={this.validationRules}
                    />
                    <ColumnDirective
                      field="userRoleList"
                      headerText={t("userRoleList")}
                      allowEditing={false}
                    />
                    <ColumnDirective
                      field="anaAgencyId"
                      headerText={t("anaAgencyId")}
                      allowEditing={false}
                      visible={isCicass}
                    />
                    <ColumnDirective
                      field="createdAt"
                      headerText={t("createdAt")}
                      visible={isAdmin}
                      allowEditing={false}
                      type="date"
                      format={"dd/MM/yyyy"}
                    />
                    <ColumnDirective
                      headerText={t("operationOnGrid")}
                      textAlign="left"
                      width="100%"
                      commands={commands}
                    />
                  </ColumnsDirective>
                  <Inject
                    services={[
                      Resize,
                      Filter,
                      Page,
                      Toolbar,
                      Edit,
                      ForeignKey,
                      CommandColumn,
                      ExcelExport,
                      Sort,
                    ]}
                  />
                </GridComponent>
              )}
            {didMountCompleted && enableHistory && (
              <GridComponent
                id="gridcomph"
                ref={(gridH) => (this.gridInstance = gridH)}
                dataSource={myDataHistory}
                toolbar={toolbarHOptions}
                editSettings={editSettingsDisabled}
                allowResizing={true}
                allowSorting={true}
                allowFiltering={true}
                filterSettings={filterSettings}
                pageSettings={{ pageSize: 10, pageCount: 5 }}
                allowExcelExport={true}
                actionComplete={this.actionComplete}
                actionBegin={this.actionBegin}
                toolbarClick={this.toolbarHClick.bind(this)}
                locale="it"
                enableInfiniteScrolling={false}
                allowPaging={true}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="id"
                    headerText={t("id")}
                    textAlign="Right"
                    isPrimaryKey={true}
                    defaultValue="-1"
                    allowEditing={false}
                    visible={isCicass}
                  />
                  <ColumnDirective
                    field="anaDayId"
                    headerText={t("anaDayId")}
                    foreignKeyValue="name"
                    foreignKeyField="id"
                    dataSource={dayData}
                    width="150"
                    validationRules={this.validationRules}
                  />
                  <ColumnDirective
                    field="anaShiftId"
                    headerText={t("anaShiftId")}
                    foreignKeyValue="name"
                    foreignKeyField="id"
                    dataSource={shiftData}
                    width="150"
                    validationRules={this.validationRules}
                  />
                  <ColumnDirective
                    field="anaUserId"
                    headerText={t("anaUserId")}
                    foreignKeyValue="name"
                    foreignKeyField="id"
                    dataSource={userData}
                    width="300"
                    visible={isAdmin}
                    validationRules={this.validationRules}
                  />
                  <ColumnDirective
                    field="userRoleList"
                    headerText={t("userRoleList")}
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="anaAgencyId"
                    headerText={t("anaAgencyId")}
                    allowEditing={false}
                    visible={isCicass}
                  />
                  <ColumnDirective
                    field="createdAt"
                    headerText={t("createdAt")}
                    visible={isAdmin}
                    allowEditing={false}
                    type="date"
                    format={"dd/MM/yyyy"}
                  />
                  <ColumnDirective
                    field="updatedAt"
                    headerText={t("updatedAt")}
                    visible={isAdmin}
                    allowEditing={false}
                    type="date"
                    format={"dd/MM/yyyy"}
                  />
                  <ColumnDirective
                    field="deletedAt"
                    headerText={t("deletedAt")}
                    visible={isAdmin}
                    allowEditing={false}
                    type="date"
                    format={"dd/MM/yyyy"}
                  />
                </ColumnsDirective>
                <Inject
                  services={[
                    Resize,
                    Filter,
                    Page,
                    Toolbar,
                    Edit,
                    ForeignKey,
                    CommandColumn,
                    ExcelExport,
                    Sort,
                  ]}
                />
              </GridComponent>
            )}
          </div>

          <ToastComponent
            ref={(toast) => {
              this.toastObj = toast;
            }}
            id="toast_default"
            position={this.position}
            title={toastInfo.title}
            content={toastInfo.content}
            cssClass={toastInfo.cssClass}
            icon={toastInfo.icon}
          />
          {!isAdmin && !myAvailabilty && (
            <GridComponent
              id="gridcompall"
              ref={(gridAll) => (this.gridInstance = gridAll)}
              dataSource={myOriginalData}
              toolbar={toolbarAllOptions}
              allowResizing={true}
              allowSorting={true}
              allowFiltering={true}
              filterSettings={filterSettings}
              pageSettings={{ pageSize: 10, pageCount: 5 }}
              allowExcelExport={true}
              toolbarClick={this.toolbarAllClick.bind(this)}
              locale="it"
              enableInfiniteScrolling={false}
              allowPaging={true}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="anaUserId"
                  headerText={t("anaUserId")}
                  foreignKeyValue="name"
                  foreignKeyField="id"
                  dataSource={userData}
                  width="300"
                />
                <ColumnDirective
                  field="anaDayId"
                  headerText={t("anaDayId")}
                  foreignKeyValue="name"
                  foreignKeyField="id"
                  dataSource={dayData}
                  width="150"
                />
                <ColumnDirective
                  field="anaShiftId"
                  headerText={t("anaShiftId")}
                  foreignKeyValue="name"
                  foreignKeyField="id"
                  dataSource={shiftData}
                  width="150"
                />
                <ColumnDirective
                  field="userRoleList"
                  headerText={t("userRoleList")}
                  allowEditing={false}
                />
                <ColumnDirective
                  field="mobile"
                  headerText={t("mobile")+' ; '+ t("email")}
                  allowEditing={false}
                />
              </ColumnsDirective>
              <Inject
                services={[
                  Resize,
                  Filter,
                  Page,
                  Toolbar,
                  Edit,
                  ForeignKey,
                  ExcelExport,
                  Sort,
                ]}
              />
            </GridComponent>
          )}
        </div>
      </>
    );
  }
}
export default withTranslation()(Availability);
