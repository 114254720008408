import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Page,
  Inject,
  Resize,
  Edit,
  CommandColumn,
  ForeignKey,
  Filter,
  Toolbar,
  ExcelExport,
  Sort,
  getStateEventArgument,
} from "@syncfusion/ej2-react-grids";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import { DropDownList } from "@syncfusion/ej2-dropdowns";
import { DataManager, Query } from "@syncfusion/ej2-data";
import { auth, cicassService } from "../../../_services";
import React from "react";
import { withTranslation } from "react-i18next";
import TokenContext from "components/TokenContext";
import {
  AccordionComponent,
  AccordionItemDirective,
  AccordionItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";

const editSettings = {
  allowEditing: false,
  allowAdding: true,
  allowDeleting: true,
  newRowPosition: "Top",
  mode: "Dialog",
  dialog: { enableResize: true },
  showDeleteConfirmDialog: true,
};
const editSettingsDisabled = {
  allowEditing: false,
  allowAdding: false,
  allowDeleting: false,
  newRowPosition: "Top",
  showDeleteConfirmDialog: true,
};
const filterSettings = { type: "CheckBox" }; // Menu, Excel
const commands = [
  {
    type: "Delete",
    buttonOption: { iconCss: "e-icons e-delete", cssClass: "e-flat" },
  },
  {
    type: "Save",
    buttonOption: { iconCss: "e-icons e-update", cssClass: "e-flat" },
  },
  {
    type: "Cancel",
    buttonOption: { iconCss: "e-icons e-cancel-icon", cssClass: "e-flat" },
  },
];
const toolbarOptions = [
  "Search",
  "Add",
  "ExcelExport",
  "Print",
  {
    text: "Refresh",
    tooltipText: "Refresh",
    prefixIcon: "e-MT_reload",
    id: "forceRefresh",
  },
];
const toolbarHOptions = [
  "Search",
  "ExcelExport",
  "Print",
  {
    text: "Refresh",
    tooltipText: "Refresh",
    prefixIcon: "e-MT_reload",
    id: "forceRefresh",
  },
];
const apiUrl = "availabilityfrequency";
const apiUrAvailabilityCombo = "availability/combo";
const apiUrAnaFrequencyTypeCombo = "ana_frequency_type/combo";
const apiUrlUserCombo = "authlogin";
const apiUrlSetting = "setting";

class Frequency extends React.Component {
  _isMounted = false;
  grid = null;
  static contextType = TokenContext;
  constructor(props) {
    super(props);
    this.position = { X: "Right", Y: "Bottom" };
    this.state = {
      myData: [],
      myDataHistory: [],
      visibleOnGrid: false,
      didMountCompleted: false,
      isCicass: false,
      isAdmin: false,
      availabilityData: [],
      frequencyTypeData: [],
      userData: [],
      selectedUserId: 0,
      selecteAvailabityId: 0,
      toastInfo: {
        title: "",
        content: "Succesfully",
        cssClass: "e-toast-success",
        icon: "e-success toast-icons",
      },
      disableMan: false,
      disableFrom: 0,
      disableTo: 0,
      disableOperation: false,
      enableHistory: false,
    };
    this.validationRules = { required: true };
    this.actionComplete = this.actionComplete.bind(this);
    this.actionBegin = this.actionBegin.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.settingContent = this.settingContent.bind(this);
    this.switchHistory = this.switchHistory.bind(this);
    // selezioni
    this.ddlOneElem = null;
    this.ddlOneObj = null;
    this.ddlTwoElem = null;
    this.ddlTwoObj = null;

    this.ddlOneParams = {
      create: () => {
        this.ddlOneElem = document.createElement("input");
        this.ddlOneElem.value = this.state.selectedUserId;
        return this.ddlOneElem;
      },
      destroy: () => {
        this.ddlOneObj.destroy();
      },
      read: () => {
        return this.ddlOneObj.value;
      },
      write: () => {
        this.ddlOneObj = new DropDownList({
          change: () => {
            this.ddlTwoObj.enabled = true;
            // devo fare la uery per recuperare l'id
            const tempQuery = new Query().where(
              "ana_user_id",
              "equal",
              this.ddlOneObj.value
            );
            this.ddlTwoObj.query = tempQuery;
            this.ddlTwoObj.text = "";
            this.ddlTwoObj.dataBind();
          },
          dataSource: new DataManager(this.state.userData),
          fields: { value: "id", text: "name" },
          floatLabelType: "Never",
          placeholder: "Utente",
          value: this.state.selectedUserId,
        });
        this.ddlOneObj.appendTo(this.ddlOneElem);
      },
    };
    this.ddlTwoParams = {
      create: () => {
        this.ddlTwoElem = document.createElement("input");
        return this.ddlTwoElem;
      },
      destroy: () => {
        this.ddlTwoObj.destroy();
      },
      read: () => {
        return this.ddlTwoObj.value;
      },
      write: () => {
        this.ddlTwoObj = new DropDownList({
          dataSource: new DataManager(this.state.availabilityData),
          enabled: false,
          fields: { value: "id", text: "name", anaUserId: "ana_user_id" },
          floatLabelType: "Never",
          placeholder: "Disponibilità",
          value: this.state.selecteAvailabityId,
        });
        this.ddlTwoObj.appendTo(this.ddlTwoElem);
      },
    };
  }

  async componentDidMount() {
    this._isMounted = true;
    const myrole = auth.getUserRole();
    const weblang = localStorage.getItem("weblang");
    let { data: myData } = await cicassService.getAll(apiUrl);
    let { data: availabilityData } = await cicassService.getAll(
      apiUrAvailabilityCombo + "/" + weblang
    );
    let { data: frequencyTypeData } = await cicassService.getAll(
      apiUrAnaFrequencyTypeCombo + "/" + weblang
    );
    let { data: userData } = await cicassService.getAll(apiUrlUserCombo);
    let { data: disableManObj } = await cicassService.getAll(
      apiUrlSetting + "/DisableAvailability"
    );
    let { data: disableFromObj } = await cicassService.getAll(
      apiUrlSetting + "/DisableAvailabilityFromDay"
    );
    let { data: disableToObj } = await cicassService.getAll(
      apiUrlSetting + "/DisableAvailabilityToDay"
    );
    if (this._isMounted) {
      let disableMan = disableManObj.value == "1" ? true : false;
      let disableFrom = parseInt(disableFromObj.value);
      let disableTo = parseInt(disableToObj.value);
      let disableAuto = disableFrom > 0 ? true : false;
      let objToday = new Date();
      let actDay = objToday.getDate();
      let disableOperation = this.checkDisableOperation(
        disableMan,
        disableAuto,
        disableFrom,
        disableTo,
        actDay
      );
      this.setState({
        didMountCompleted: true,
        myData,
        disableMan,
        disableFrom,
        disableTo,
        disableManObj,
        disableFromObj,
        disableToObj,
        disableOperation,
        availabilityData,
        frequencyTypeData,
        userData,
        isCicass: myrole == "SuperUser" ? true : false,
        isAdmin:
          myrole == "Admin" || myrole == "SuperUser" || myrole == "Manager"
            ? true
            : false,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toolbarClick(args) {
    switch (args.item.properties.id) {
      case "gridcomp_excelexport":
        this.gridInstance.excelExport();
        break;
    }
    if (args.item.id === "forceRefresh") {
      this.refreshGrid();
    }
  }

  toolbarHClick(args) {
    switch (args.item.properties.id) {
      case "gridcompH_excelexport":
        this.gridInstance.excelExport();
        break;
    }
    if (args.item.id === "forceRefresh") {
      this.refreshGrid();
    }
  }

  refreshGrid() {
    cicassService.getAll(apiUrl).then((response) => {
      this.setState({ myData: response.data });
    });
  }

  actionBegin(state) {
    if (state.requestType == "beginEdit" || state.requestType == "add") {
      let { selectedUserId, selecteAvailabityId } = this.state;
      if (state.requestType == "add") {
        state.id = -1;
        state.data.anaAgencyId = auth.getAgencyId();
        const token = this.context;
        state.data.anaUserId = auth.getUserId(token.jwt);
        selectedUserId = state.data.anaUserId;
        state.data.userNote = "";
        state.data.viewOrder = 1;
        state.data.frequencyValue = 0;
        state.data.anaFrequencyTypeId = 3; // monthly
      }
      if (state.requestType == "beginEdit") {
        selectedUserId = state.rowData.anaUserId;
        selecteAvailabityId = state.rowData.availabilityId;
      }
      this.setState({
        visibleOnGrid: true,
        selectedUserId,
        selecteAvailabityId,
      });
    }
  }

  actionComplete(state) {
    const { t } = this.props;
    if (state.action === "add") {
      state.data.id = -1;
      state.data.createdAt = this.objToday;
      delete state.data.anaAgencyId;
      cicassService
        .saveOp(apiUrl, state.data)
        .then(() => {
          this.setToastInfo("Success", t("addToastOK"));
        })
        .catch((err) => {
          console.error("Error on Add: ", err);
          this.setToastInfo("Error", "I: " + err.message);
        })
        .finally(() => {
          this.refreshGrid();
        });
    }
    if (state.action === "edit") {
      delete state.data.anaAgencyId;
      cicassService
        .saveOp(apiUrl, state.data)
        .then(() => {
          this.setToastInfo("Information", t("editToastOK"));
        })
        .catch((err) => {
          console.error("Error on Edit: ", err);
          this.setToastInfo("Error", "U: " + err.message);
        })
        .finally(() => {
          this.refreshGrid();
        });
    }
    if (state.requestType === "delete") {
      cicassService
        .deleteOp(apiUrl, state.data[0].id)
        .then(() => {
          this.setToastInfo("Warning", t("deleteToastOK"));
        })
        .catch((err) => {
          console.error("Error on Delete: ", err);
          this.setToastInfo("Error", "D:" + err.message);
        })
        .finally(() => {
          this.refreshGrid();
        });
    }

    this.setState({ visibleOnGrid: false });
  }

  setToastInfo(title, errorMsg) {
    let { toastInfo } = this.state;
    switch (title) {
      case "Warning":
        toastInfo.title = "Warning";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-warning";
        toastInfo.icon = "e-warning toast-icons";
        break;
      case "Success":
        toastInfo.title = "Success";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-success";
        toastInfo.icon = "e-success toast-icons";
        break;
      case "Error":
        toastInfo.title = "Error";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-danger";
        toastInfo.icon = "e-error toast-icons";
        break;
      case "Information":
        toastInfo.title = "Information";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-info";
        toastInfo.icon = "e-info toast-icons";
        break;
    }
    this.setState({ toastInfo });
    this.toastObj.show();
    return;
  }
  checkDisableOperation(
    disableMan,
    disableAuto,
    disableFrom,
    disableTo,
    actDay
  ) {
    if (disableMan) return true;
    else {
      if (disableAuto) {
        if (disableFrom <= actDay && disableTo >= actDay) return true;
      } else return false;
    }
  }

  settingContent(history) {
    let { enableHistory } = this.state;
    return (
      <div className="control-section input-content-wrapper">
        <div className="row custom-margin">
          <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
            <SwitchComponent
              checked={enableHistory}
              onChange={this.switchHistory}
            />
          </div>
          <div className="col-xs-10 col-sm-10 col-lg-10 col-md-10">
            &nbsp;{history}
          </div>
        </div>
      </div>
    );
  }

  async switchHistory(e) {
    let { enableHistory } = this.state;
    if (!enableHistory) {
      let { data: myDataHistory } = await cicassService.getAll(
        apiUrl + (enableHistory ? "" : "/history")
      );
      this.setState({ enableHistory: true, myDataHistory });
    } else {
      let { data: myData } = await cicassService.getAll(
        apiUrl + (enableHistory ? "" : "/history")
      );
      this.setState({ enableHistory: false, myData });
    }
  }

  render() {
    const {
      myData,
      didMountCompleted,
      availabilityData,
      frequencyTypeData,
      userData,
      myDataHistory,
      isCicass,
      toastInfo,
      isAdmin,
      disableOperation,
      disableMan,
      disableFrom,
      disableTo,
      enableHistory,
    } = this.state;
    const { t } = this.props;
    return (
      <>
        <div style={{ margin: "1.2rem 0 .5rem 1.2rem" }}>
          <h2 className="text-gray-800 text-xl font-semibold">
            {t("titleFrequencyJs")}
            {didMountCompleted &&
              !disableMan &&
              " (" +
                t("lockInsert") +
                " " +
                disableFrom +
                " " +
                t("lockTo") +
                " " +
                disableTo +
                " " +
                t("lockEveryMonth") +
                ")"}
            {didMountCompleted && disableMan && " (" + t("lockNow") + ")"}
          </h2>
        </div>
        <div className="p-4 flex-auto">
          {didMountCompleted && isAdmin && (
            <AccordionComponent>
              <AccordionItemsDirective>
                <AccordionItemDirective
                  header={t("settingAvailabilityJs")}
                  content={() => this.settingContent(t("showHistory"))}
                />
              </AccordionItemsDirective>
            </AccordionComponent>
          )}
          <div>
            {didMountCompleted && !enableHistory && (
              <GridComponent
                id="gridcomp"
                ref={(grid) => (this.gridInstance = grid)}
                dataSource={myData}
                toolbar={toolbarOptions}
                allowResizing={true}
                editSettings={
                  disableOperation ? editSettingsDisabled : editSettings
                }
                allowFiltering={true}
                filterSettings={filterSettings}
                allowPaging={true}
                pageSettings={{ pageSize: 10, pageCount: 5 }}
                allowExcelExport={true}
                allowSorting={true}
                actionComplete={this.actionComplete}
                actionBegin={this.actionBegin}
                toolbarClick={this.toolbarClick.bind(this)}
                locale="it"
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="id"
                    headerText={t("id")}
                    textAlign="Right"
                    isPrimaryKey={true}
                    defaultValue="-1"
                    allowEditing={false}
                    visible={isCicass}
                  />
                  <ColumnDirective
                    field="anaUserId"
                    headerText={t("anaUserId")}
                    foreignKeyValue="name"
                    foreignKeyField="id"
                    dataSource={userData}
                    allowAdding={isAdmin}
                    allowEditing={isAdmin}
                    defaultValue={87}
                    width="150"
                    validationRules={this.validationRules}
                    edit={this.ddlOneParams}
                  />
                  <ColumnDirective
                    field="availabilityId"
                    headerText={t("availabilityId")}
                    foreignKeyValue="name"
                    foreignKeyField="id"
                    dataSource={availabilityData}
                    width="150"
                    validationRules={this.validationRules}
                    edit={this.ddlTwoParams}
                  />
                  <ColumnDirective
                    field="anaFrequencyTypeId"
                    headerText={t("anaFrequencyTypeId")}
                    foreignKeyValue="name"
                    foreignKeyField="id"
                    dataSource={frequencyTypeData}
                    width="150"
                    validationRules={this.validationRules}
                  />
                  <ColumnDirective
                    field="frequencyValue"
                    headerText={t("frequencyValue")}
                    validationRules={this.validationRules}
                  />
                  <ColumnDirective
                    field="viewOrder"
                    headerText={t("viewOrder")}
                    visible={isCicass}
                    validationRules={this.validationRules}
                  />
                  <ColumnDirective
                    field="userNote"
                    headerText={t("userNote")}
                  />
                  <ColumnDirective
                    field="anaAgencyId"
                    headerText={t("anaAgencyId")}
                    allowEditing={false}
                    visible={isCicass}
                  />
                  <ColumnDirective
                    field="createdAt"
                    headerText={t("createdAt")}
                    visible={isAdmin}
                    allowEditing={false}
                    type="date"
                    format={"dd/MM/yyyy"}
                  />
                  <ColumnDirective
                    field="updatedAt"
                    headerText={t("updatedAt")}
                    visible={isCicass}
                    allowEditing={false}
                    type="date"
                    format={"dd/MM/yyyy"}
                  />
                  <ColumnDirective
                    headerText={t("operationOnGrid")}
                    width="160"
                    textAlign="left"
                    commands={commands}
                  />
                </ColumnsDirective>
                <Inject
                  services={[
                    Resize,
                    Filter,
                    Page,
                    Toolbar,
                    Edit,
                    ForeignKey,
                    CommandColumn,
                    ExcelExport,
                    Sort,
                  ]}
                />
              </GridComponent>
            )}
            {didMountCompleted && enableHistory && (
              <GridComponent
                id="gridcompH"
                ref={(gridH) => (this.gridInstance = gridH)}
                dataSource={myDataHistory}
                toolbar={toolbarHOptions}
                editSettings={editSettingsDisabled}
                allowResizing={true}
                allowSorting={true}
                allowFiltering={true}
                filterSettings={filterSettings}
                pageSettings={{ pageSize: 10, pageCount: 5 }}
                allowExcelExport={true}
                actionComplete={this.actionComplete}
                actionBegin={this.actionBegin}
                toolbarClick={this.toolbarHClick.bind(this)}
                locale="it"
                enableInfiniteScrolling={false}
                allowPaging={true}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="id"
                    headerText={t("id")}
                    textAlign="Right"
                    isPrimaryKey={true}
                    defaultValue="-1"
                    allowEditing={false}
                    visible={isCicass}
                  />
                  <ColumnDirective
                    field="anaUserId"
                    headerText={t("anaUserId")}
                    foreignKeyValue="name"
                    foreignKeyField="id"
                    dataSource={userData}
                    allowAdding={isAdmin}
                    allowEditing={isAdmin}
                    defaultValue={87}
                    width="150"
                    validationRules={this.validationRules}
                    edit={this.ddlOneParams}
                  />
                  <ColumnDirective
                    field="availabilityId"
                    headerText={t("availabilityId")}
                    foreignKeyValue="name"
                    foreignKeyField="id"
                    dataSource={availabilityData}
                    width="150"
                    validationRules={this.validationRules}
                    edit={this.ddlTwoParams}
                  />
                  <ColumnDirective
                    field="anaFrequencyTypeId"
                    headerText={t("anaFrequencyTypeId")}
                    foreignKeyValue="name"
                    foreignKeyField="id"
                    dataSource={frequencyTypeData}
                    width="150"
                    validationRules={this.validationRules}
                  />
                  <ColumnDirective
                    field="frequencyValue"
                    headerText={t("frequencyValue")}
                    validationRules={this.validationRules}
                  />
                  <ColumnDirective
                    field="viewOrder"
                    headerText={t("viewOrder")}
                    visible={isCicass}
                    validationRules={this.validationRules}
                  />
                  <ColumnDirective
                    field="userNote"
                    headerText={t("userNote")}
                  />
                  <ColumnDirective
                    field="anaAgencyId"
                    headerText={t("anaAgencyId")}
                    allowEditing={false}
                    visible={isCicass}
                  />
                  <ColumnDirective
                    field="createdAt"
                    headerText={t("createdAt")}
                    type="date"
                    format={"dd/MM/yyyy HH:mm:ss"}
                    visible={isAdmin}
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="updatedAt"
                    headerText={t("updatedAt")}
                    type="date"
                    format={"dd/MM/yyyy HH:mm:ss"}
                    visible={isCicass}
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="deletedAt"
                    headerText={t("deletedAt")}
                    type="date"
                    format={"dd/MM/yyyy HH:mm:ss"}
                    visible={isAdmin}
                    allowEditing={false}
                  />
                </ColumnsDirective>
                <Inject
                  services={[
                    Resize,
                    Filter,
                    Page,
                    Toolbar,
                    Edit,
                    ForeignKey,
                    CommandColumn,
                    ExcelExport,
                    Sort,
                  ]}
                />
              </GridComponent>
            )}
          </div>

          <ToastComponent
            ref={(toast) => {
              this.toastObj = toast;
            }}
            id="toast_default"
            position={this.position}
            title={toastInfo.title}
            content={toastInfo.content}
            cssClass={toastInfo.cssClass}
            icon={toastInfo.icon}
          ></ToastComponent>
        </div>
      </>
    );
  }
}
export default withTranslation()(Frequency);
