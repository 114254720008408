import React from 'react'
import { GridComponent, ColumnDirective, ColumnsDirective, Page, Inject, Group, CommandColumn, Edit, ForeignKey, Filter, Toolbar, ExcelExport } from '@syncfusion/ej2-react-grids';
import { withTranslation } from 'react-i18next';
import { cicassService, auth } from "../../../_services";

const editSettings = { allowEditing: false };
const filterSettings = { type: 'CheckBox' }; // Menu, Excel
const commands = [{ type: 'Change', buttonOption: { iconCss: 'e-icons e-M_CheckBox', cssClass: 'e-flat' } }];
const toolbarOptions = ['Search', 'ExcelExport'];
const apiUrl = "vauthorize";
const apiUpdate = "authauthorize";

class AuthControl extends React.Component {
  _isMounted = false;
  grid = null;
  constructor(props) {
    super(props);
    this.state = {
      myData: {},
      visibleOnGrid: false,
      didMountCompleted: false,
      toastInfo: { title: '', content: 'Succesfully', cssClass: 'e-toast-success', icon: 'e-success toast-icons' }
    };
    this.validationRules = { required: true };
  }

  async componentDidMount() {
    this._isMounted = true;
    let { data: myData } = await cicassService.getAll(apiUrl);
    if (this._isMounted) {
      this.setState({ didMountCompleted: true, myData });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  toolbarClick(args) {
    switch (args.item.properties.id) {
      case 'gridcomp_excelexport':
        this.gridInstance.excelExport();
        break;
    }
  }

  enabledTemplate(props) {
    const red = { color: 'red' }
    const green = { color: 'green' }
    if (props.enabled == 0) return (<i className="far fa-square" style={red}></i>);
    return (<i className="far fa-check-square" style={green}></i>);
  }

  setToastInfo(title, errorMsg) {
    let { toastInfo } = this.state;
    switch (title) {
      case 'Warning':
        toastInfo.title = "Warning"; toastInfo.content = errorMsg; toastInfo.cssClass = 'e-toast-warning'; toastInfo.icon = 'e-warning toast-icons';
        break;
      case 'Success':
        toastInfo.title = "Success"; toastInfo.content = errorMsg; toastInfo.cssClass = 'e-toast-success'; toastInfo.icon = 'e-success toast-icons';
        break;
      case 'Error':
        toastInfo.title = "Error"; toastInfo.content = errorMsg; toastInfo.cssClass = 'e-toast-danger'; toastInfo.icon = 'e-error toast-icons';
        break;
      case 'Information':
        toastInfo.title = "Information"; toastInfo.content = errorMsg; toastInfo.cssClass = 'e-toast-info'; toastInfo.icon = 'e-info toast-icons';
        break;
    }
    this.setState({ toastInfo });
    this.toastObj.show();
    return;
  }

  async commandClick(args) {
    //console.log(args.rowData);
    await cicassService.getAll(apiUpdate + "/enabled/" + args.rowData.id).catch(() => { throw 'Impossibile abilitare/disabilitare' });
    let { data: myData } = await cicassService.getAll(apiUrl).catch((err) => { throw 'Impossibile caricare dati' });
    this.setState({ myData });
  }
  render() {
    const { myData, didMountCompleted } = this.state;
    const { t } = this.props;
    return (
      <>
        <div style={{ margin: '1%', marginTop: '1%' }}>
          <h2 className="text-gray-800 text-xl font-semibold">{t('titleControlJs')}</h2>
        </div>
        <div className="p-4 flex-auto">
          {didMountCompleted && (
            <GridComponent id='gridcomp' ref={grid => this.gridInstance = grid} dataSource={myData} toolbar={toolbarOptions} editSettings={editSettings}
              allowFiltering={true} filterSettings={filterSettings} allowPaging={true} pageSettings={{ pageSize: 10, pageCount: 15 }} allowExcelExport={true}
              toolbarClick={this.toolbarClick.bind(this)} locale='it' commandClick={this.commandClick.bind(this)}
            >
              <ColumnsDirective>
                <ColumnDirective field='name' headerText={t('nameAPI')} textAlign='Right' isPrimaryKey={true} defaultValue='-1' />
                <ColumnDirective field='rolename' headerText={t('rolename')} width='100' />
                <ColumnDirective field='action' headerText={t('action')} />
                <ColumnDirective field='description' headerText={t('description')} width='250' />
                <ColumnDirective field='enabled' headerText={t('enabled')} template={this.enabledTemplate.bind(this)} />
                <ColumnDirective headerText={t('operationOnGrid')} width='120' commands={commands}></ColumnDirective>
              </ColumnsDirective>
              <Inject services={[Filter, Page, Toolbar, ForeignKey, CommandColumn, Edit, ExcelExport]} />
            </GridComponent>
          )}
        </div>
      </>
    );
  }

};
export default withTranslation()(AuthControl);