import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Page,
  Inject,
  Resize,
  Edit,
  Sort,
  CommandColumn,
  ForeignKey,
  Filter,
  Toolbar,
  ExcelExport,
  PdfExport,
} from "@syncfusion/ej2-react-grids";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import { getValue } from "@syncfusion/ej2-base";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { auth, cicassService } from "../../../_services";
import React from "react";
import { DateTime } from "luxon";
import { withTranslation } from "react-i18next";
import TokenContext from "components/TokenContext";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  AccordionComponent,
  AccordionItemDirective,
  AccordionItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import itLocale from "date-fns/locale/it";
import { TextField } from "@mui/material";

const editSettings = {
  allowEditing: true,
  allowAdding: true,
  allowDeleting: true,
  newRowPosition: "Top",
  mode: "Dialog",
  dialog: { enableResize: true },
  showDeleteConfirmDialog: true,
};
const editSettingsDisabled = {
  allowEditing: false,
  allowAdding: false,
  allowDeleting: false,
  newRowPosition: "Top",
  showDeleteConfirmDialog: true,
};
const filterSettings = { type: "CheckBox" }; // Menu, Excel
const commands = [
  {
    type: "Edit",
    buttonOption: { iconCss: " e-icons e-edit", cssClass: "e-flat" },
  },
  {
    type: "Delete",
    buttonOption: { iconCss: "e-icons e-delete", cssClass: "e-flat" },
  },
  {
    type: "Save",
    buttonOption: { iconCss: "e-icons e-update", cssClass: "e-flat" },
  },
  {
    type: "Cancel",
    buttonOption: { iconCss: "e-icons e-cancel-icon", cssClass: "e-flat" },
  },
];
const toolbarOptions = [
  "Search",
  "Add",
  "ExcelExport",
  "Print",
  {
    text: "Refresh",
    tooltipText: "Refresh",
    prefixIcon: "e-MT_reload",
    id: "forceRefresh",
  },
];
const apiUrl = "vacation";
// const apiUrlAgencyCombo = "ana_agency/combo";
const apiUrlUserCombo = "authlogin";
const apiUrlSetting = "setting";

const actualYear = new Date().getFullYear() + 1;
const maxDate = new Date(actualYear + "-12-31");

class Vacation extends React.Component {
  _isMounted = false;
  grid = null;
  static contextType = TokenContext;

  constructor(props) {
    super(props);
    this.position = { X: "Right", Y: "Bottom" };
    this.state = {
      myData: [],
      myOriginalData: [],
      didMountCompleted: false,
      isCicass: false,
      isAdmin: false,
      userData: [],
      defaultDate: new Date(),
      toastInfo: {
        title: "",
        content: "Succesfully",
        cssClass: "e-toast-success",
        icon: "e-success toast-icons",
      },
      disableMan: false,
      disableFrom: 0,
      disableAuto: false,
      disableTo: 0,
      disableOperation: false,
      selectedMonth: false,
      hideOldVacation: true,
    };
    this.validationUser = {
      required: [this.notBlank.bind(this), "Questo campo è obbligatorio"],
    };
    this.validationFrom = {
      required: true,
      min: [
        this.gtToday.bind(this),
        '"Data Da" deve essere superiore alla data di Oggi e inferiore di "Data A"',
      ],
    };
    this.validationTo = {
      required: true,
      min: [
        this.gtFrom.bind(this),
        '"Data A" deve essere maggiore o uguale a "Data Da" e superiore alla data di Oggi',
      ],
    };
    this.actionComplete = this.actionComplete.bind(this);
    this.actionBegin = this.actionBegin.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.settingContent = this.settingContent.bind(this);
    this.switchMan = this.switchMan.bind(this);
    this.switchAuto = this.switchAuto.bind(this);
    this.onSelectedMonthChange = this.onSelectedMonthChange.bind(this);
    this.onHideOldVacation = this.onHideOldVacation.bind(this);
    this.onMonthChange = this.onMonthChange.bind(this);
  }
  notBlank(args) {
    return args.value != "1"; // blank user
  }
  gtToday(args) {
    const tomorrow = DateTime.now().plus({ day: 1 }).toFormat("yyyy-MM-dd");
    let valueRead = getValue("value", args);
    let insertDate =
      valueRead.substring(6, 10) +
      "-" +
      valueRead.substring(3, 5) +
      "-" +
      valueRead.substring(0, 2);
    let valueReadTo = document.getElementById("vacationTo").value;
    let toDate =
      valueReadTo.substring(6, 10) +
      "-" +
      valueReadTo.substring(3, 5) +
      "-" +
      valueReadTo.substring(0, 2);
    if (toDate == "--") return insertDate >= tomorrow;
    else return insertDate >= tomorrow && insertDate <= toDate;
  }

  gtFrom(args) {
    const tomorrow = DateTime.now().plus({ day: 1 }).toFormat("yyyy-MM-dd");
    let valueReadFrom = document.getElementById("vacationFrom").value;
    let vacationFrom =
      valueReadFrom.substring(6, 10) +
      "-" +
      valueReadFrom.substring(3, 5) +
      "-" +
      valueReadFrom.substring(0, 2);
    let valueRead = getValue("value", args);
    let insertDate =
      valueRead.substring(6, 10) +
      "-" +
      valueRead.substring(3, 5) +
      "-" +
      valueRead.substring(0, 2);
    return insertDate >= vacationFrom && insertDate >= tomorrow;
  }

  async componentDidMount() {
    this._isMounted = true;
    // const tomorrow = DateTime.now().plus({ day: 1 }).toFormat("yyyy-MM-dd");
    const myrole = auth.getUserRole();
    const weblang = localStorage.getItem("weblang");
    let myData;
    let { data: myOriginalData } = await cicassService.getAll(apiUrl);
    let { data: userData } = await cicassService.getAll(apiUrlUserCombo);
    let { data: disableManObj } = await cicassService.getAll(
      apiUrlSetting + "/DisableVacation"
    );
    let { data: disableFromObj } = await cicassService.getAll(
      apiUrlSetting + "/DisableVacationFromDay"
    );
    let { data: disableToObj } = await cicassService.getAll(
      apiUrlSetting + "/DisableVacationToDay"
    );

    if (this._isMounted) {
      if (this.state.hideOldVacation) {
        myData = myOriginalData.filter(function (el) {
          let to = el.vacationTo.toString().substring(0, 10); //(el.vacationTo).getFullYear().toString() + ((el.vacationTo).getMonth() + 1 < 9 ? "0" : "") + ((el.vacationTo).getMonth() + 1).toString()
          let today = new Date();
          let B = today.toISOString().split("T")[0];
          return el.vacationTo.toString().substring(0, 10) > B;
        });
      } else {
        myData = myOriginalData;
      }

      let disableMan = disableManObj.value == "1" ? true : false;
      let disableFrom = parseInt(disableFromObj.value);
      let disableTo = parseInt(disableToObj.value);
      let disableAuto = disableFrom > 0 ? true : false;
      let objToday = new Date();
      let actDay = objToday.getDate();
      let disableOperation = this.checkDisableOperation(
        disableMan,
        disableAuto,
        disableFrom,
        disableTo,
        actDay
      );
      this.setState({
        didMountCompleted: true,
        myData,
        myOriginalData,
        disableMan,
        disableFrom,
        disableAuto,
        disableTo,
        disableManObj,
        disableFromObj,
        disableToObj,
        disableOperation,
        userData,
        isCicass: myrole == "SuperUser" ? true : false,
        isAdmin:
          myrole == "Admin" || myrole == "SuperUser" || myrole == "Manager"
            ? true
            : false,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toolbarClick(args) {
    switch (args.item.properties.id) {
      case "gridcomp_excelexport":
        this.gridInstance.excelExport();
        break;
      case "gridcomp_pdfexport":
        const exportProperties = {
          pageOrientation: "Landscape",
        };

        this.gridInstance.pdfExport(exportProperties);
        break;
      default:
        break;
      /*  case 'gridcomp_print':
          this.gridInstance.print();
          break;*/
    }
    if (args.item.id === "forceRefresh") {
      this.refreshGrid(true);
    }
  }

  refreshGrid(resetFilter) {
    cicassService.getAll(apiUrl).then((response) => {
      this.setState({
        myOriginalData: response.data,
        myData: response.data,
        selectedMonth: false,
        hideOldVacation: false,
      });
      //if (resetFilter)
      //  this.setState({ });
    });
  }

  actionComplete(state) {
    const { t } = this.props;
    if (state.action === "edit") {
      delete state.data.anaAgencyId;
      let dataToSave = {
        id: state.data.id,
        anaUserId: state.data.anaUserId,
        FromAAAA: state.data.vacationFrom.getFullYear(),
        FromMM: state.data.vacationFrom.getMonth() + 1,
        FromDD: state.data.vacationFrom.getDate(),
        ToAAAA: state.data.vacationTo.getFullYear(),
        ToMM: state.data.vacationFrom.getMonth() + 1,
        ToDD: state.data.vacationTo.getDate(),
        VacationNote: state.data.vacationNote,
      };
      cicassService
        .saveOp(apiUrl, dataToSave)
        .then(() => {
          this.refreshGrid(false);
          this.setToastInfo("Information", t("editToastOK"));
        })
        .catch((err) => {
          this.setToastInfo("Error", "U: " + err.message);
          this.refreshGrid(false);
        });
    }
    if (state.requestType === "delete") {
      cicassService
        .deleteOp(apiUrl, state.data[0].id)
        .then(() => {
          this.refreshGrid(false);
          this.setToastInfo("Warning", t("deleteToastOK"));
        })
        .catch((err) => {
          this.setToastInfo("Error", "D:" + err.message);
          this.refreshGrid(false);
        });
    }
  }

  setToastInfo(title, errorMsg) {
    let { toastInfo } = this.state;
    switch (title) {
      case "Warning":
        toastInfo.title = "Warning";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-warning";
        toastInfo.icon = "e-warning toast-icons";
        break;
      case "Success":
        toastInfo.title = "Success";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-success";
        toastInfo.icon = "e-success toast-icons";
        break;
      case "Error":
        toastInfo.title = "Error";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-danger";
        toastInfo.icon = "e-error toast-icons";
        break;
      case "Information":
        toastInfo.title = "Information";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-info";
        toastInfo.icon = "e-info toast-icons";
        break;
      default:
        break;
    }
    this.setState({ toastInfo });
    this.toastObj.show();
    return;
  }

  viewTemplateFrom(args) {
    try {
      let vacationFromPrint =
        args.vacationFrom.substring(8, 10) +
        "/" +
        args.vacationFrom.substring(5, 7) +
        "/" +
        args.vacationFrom.substring(0, 4);
      return <div>{vacationFromPrint}</div>;
    } catch (err) {
      return "";
    }
  }
  viewTemplateTo(args) {
    try {
      let vacationToPrint =
        args.vacationTo.substring(8, 10) +
        "/" +
        args.vacationTo.substring(5, 7) +
        "/" +
        args.vacationTo.substring(0, 4);
      return vacationToPrint;
    } catch (err) {
      return "";
    }
  }
  editTemplateFrom(args) {
    const { t } = this.props;
    const tomorrow = DateTime.now().plus({ day: 1 }).toISO();
    return (
      <DatePickerComponent
        value={getValue("vacationFrom", args)}
        id="vacationFrom"
        placeholder={t("vacationFrom")}
        floatLabelType="Always"
        min={tomorrow}
        format="dd/MM/yyyy"
        onChange={(e) => {
          const toDateElement = document.getElementById("vacationTo");
          toDateElement.ej2_instances[0].value = "";
          if (!e.value) {
            toDateElement.ej2_instances[0].enabled = false;
          } else {
            toDateElement.ej2_instances[0].enabled = true;
            toDateElement.ej2_instances[0].min = e.value;
            // console.log(toDateElement.ej2_instances[0].min)
          }
        }}
        locale="it"
      />
    );
  }
  editTemplateTo(args) {
    const { t } = this.props;
    return (
      <DatePickerComponent
        value={getValue("vacationTo", args)}
        id="vacationTo"
        placeholder={t("vacationTo")}
        floatLabelType="Always"
        format="dd/MM/yyyy"
        enabled={false}
        locale="it"
      />
    );
  }

  onSelectedMonthChange(e) {
    // applico filtro
    let {
      selectedMonth,
      myData,
      myOriginalData,
      defaultDate,
      hideOldVacation,
    } = this.state;
    selectedMonth = !selectedMonth;
    hideOldVacation = false;
    let thisMonth =
      defaultDate.getFullYear().toString() +
      "-" +
      (defaultDate.getMonth() + 1 < 10 ? "0" : "") +
      (defaultDate.getMonth() + 1).toString();
    if (selectedMonth) {
      myData = myOriginalData.filter(function (el) {
        let from = el.vacationFrom.toString().substring(0, 7); // (el.vacationFrom).getFullYear().toString() + ((el.vacationFrom).getMonth() + 1 < 9 ? "0" : "") + ((el.vacationFrom).getMonth() + 1).toString()
        let to = el.vacationTo.toString().substring(0, 7); //(el.vacationTo).getFullYear().toString() + ((el.vacationTo).getMonth() + 1 < 9 ? "0" : "") + ((el.vacationTo).getMonth() + 1).toString()
        return from <= thisMonth && thisMonth <= to;
      });
    } else {
      myData = myOriginalData;
    }
    this.setState({ selectedMonth, myData, hideOldVacation });
  }

  onHideOldVacation(e) {
    let { hideOldVacation, myData, myOriginalData, selectedMonth } = this.state;
    selectedMonth = false;
    hideOldVacation = !hideOldVacation;
    if (hideOldVacation) {
      myData = myOriginalData.filter(function (el) {
        let to = el.vacationTo.toString().substring(0, 10); //(el.vacationTo).getFullYear().toString() + ((el.vacationTo).getMonth() + 1 < 9 ? "0" : "") + ((el.vacationTo).getMonth() + 1).toString()
        let today = new Date();
        let B = today.toISOString().split("T")[0];
        return el.vacationTo.toString().substring(0, 10) > B;
      });
    } else {
      myData = myOriginalData;
    }

    this.setState({ hideOldVacation, selectedMonth, myData });
  }

  switchMan(e) {
    let { disableMan, disableAuto, disableFrom, disableTo, disableManObj } =
      this.state;
    let objToday = new Date();
    let actDay = objToday.getDate();
    disableMan = !disableMan;
    disableManObj.value = disableMan ? "1" : "0";
    cicassService
      .saveOp(apiUrlSetting, disableManObj)
      .then(() => {
        this.setToastInfo("Success", this.props.t("toastSuccess"));
      })
      .catch((err) => {
        this.setToastInfo("Error", "U: " + err.message);
      });
    let disableOperation = this.checkDisableOperation(
      disableMan,
      disableAuto,
      disableFrom,
      disableTo,
      actDay
    );
    this.setState({ disableMan, disableOperation });
  }

  switchAuto(e) {
    let {
      disableMan,
      disableAuto,
      disableFrom,
      disableTo,
      disableFromObj,
      disableToObj,
    } = this.state;
    let objToday = new Date();
    let actDay = objToday.getDate();
    disableAuto = !disableAuto;
    disableFrom = disableAuto ? 1 : 0;
    disableFromObj.value = disableFrom.toString();
    disableTo = disableAuto ? 1 : 0;
    disableToObj.value = disableTo.toString();

    cicassService.saveOp(apiUrlSetting, disableFromObj).catch((err) => {
      this.setToastInfo("Error", "U: " + err.message);
    });
    cicassService
      .saveOp(apiUrlSetting, disableToObj)
      .then(() => {
        this.setToastInfo("Success", this.props.t("toastSuccess"));
      })
      .catch((err) => {
        this.setToastInfo("Error", "U: " + err.message);
      });

    let disableOperation = this.checkDisableOperation(
      disableMan,
      disableAuto,
      disableFrom,
      disableTo,
      actDay
    );
    this.setState({ disableAuto, disableFrom, disableTo, disableOperation });
  }

  onTxtChange(event, from) {
    let {
      disableMan,
      disableAuto,
      disableFrom,
      disableTo,
      disableFromObj,
      disableToObj,
      disableOperation,
    } = this.state;
    let currentValue = parseInt(event.target.value);
    let actDay = new Date().getDate();
    if (currentValue < 0 || currentValue > 31) return;

    if (currentValue === 0) {
      disableAuto = false;
      disableFrom = 0;
      disableTo = 0;
    } else {
      disableAuto = true;
      if (from === 1) {
        disableFrom = currentValue;
        if (disableTo < disableFrom) disableTo = disableFrom;
        if (disableTo === 0) disableTo = currentValue;
      } else {
        disableTo = currentValue;
        if (disableTo < disableFrom) disableFrom = disableTo;
        if (disableFrom === 0) disableFrom = 1;
      }
    }
    disableToObj.value = disableTo.toString();
    disableFromObj.value = disableFrom.toString();

    cicassService.saveOp(apiUrlSetting, disableFromObj).catch((err) => {
      this.setToastInfo("Error", "U: " + err.message);
    });

    cicassService.saveOp(apiUrlSetting, disableToObj).catch((err) => {
      this.setToastInfo("Error", "U: " + err.message);
    });

    disableOperation = this.checkDisableOperation(
      disableMan,
      disableAuto,
      disableFrom,
      disableTo,
      actDay
    );
    this.setState({ disableAuto, disableFrom, disableTo, disableOperation });
  }

  checkDisableOperation(
    disableMan,
    disableAuto,
    disableFrom,
    disableTo,
    actDay
  ) {
    if (disableMan) return true;
    else {
      if (disableAuto) {
        if (disableFrom <= actDay && disableTo >= actDay) return true;
      } else return false;
    }
  }

  onMonthChange = async (e) => {
    let { selectedMonth, myOriginalData, myData, defaultDate } = this.state;
    defaultDate = e;
    let thisMonth =
      defaultDate.getFullYear().toString() +
      "-" +
      (defaultDate.getMonth() + 1 < 9 ? "0" : "") +
      (defaultDate.getMonth() + 1).toString();
    if (selectedMonth) {
      myData = myOriginalData.filter(function (el) {
        let from = el.vacationFrom.toString().substring(0, 7); // (el.vacationFrom).getFullYear().toString() + ((el.vacationFrom).getMonth() + 1 < 9 ? "0" : "") + ((el.vacationFrom).getMonth() + 1).toString()
        let to = el.vacationTo.toString().substring(0, 7); //(el.vacationTo).getFullYear().toString() + ((el.vacationTo).getMonth() + 1 < 9 ? "0" : "") + ((el.vacationTo).getMonth() + 1).toString()
        return from <= thisMonth && thisMonth <= to;
      });
    }
    this.setState({ defaultDate, myData });
  };

  beforePrint(args) {
    //beforePrint={this.beforePrint.bind(this)};
    let tbodyEle = this.gridInstance.element
      .querySelector(".e-gridcontent .e-content tbody")
      .cloneNode(true);
    let arrOfChildren = tbodyEle.children;
    args.element
      .querySelector(".e-gridcontent .e-content tbody")
      .replaceChildren(...[].slice.call(arrOfChildren));
  }

  onRowDataBound(args) {
    try {
      let today = new Date();
      let A = args.data.vTdt;
      let B = today
        .toISOString()
        .split("T")[0]
        .replace("-", "")
        .replace("-", "");
      //console.log(A,B);
      if (A < B) {
        //console.log("minore");
        args.row
          .querySelectorAll(".e-icons.e-delete")[0]
          .classList.add("e-disabled");
        args.row
          .querySelectorAll(".e-icons.e-edit")[0]
          .classList.add("e-disabled");
        //args.row.querySelectorAll('.e-icons.e-edit')[0].style.display = "none";
      }
    } catch (error) {
      console.error("error:", error);
    }
  }

  actionBegin(args) {
    const { t } = this.props;
    // ADD
    if (args.requestType == "add") {
      args.id = -1;
      args.data.anaAgencyId = auth.getAgencyId();
      const token = this.context;
      args.data.anaUserId = auth.getUserId(token.jwt);
    }
    // SAVE
    if (args.requestType === "save") {
      switch (args.action) {
        case "add": {
          args.data.id = -1;
          delete args.data.anaAgencyId;
          let dataToSave = {
            id: -1,
            anaUserId: args.data.anaUserId,
            FromAAAA: args.data.vacationFrom.getFullYear(),
            FromMM: args.data.vacationFrom.getMonth() + 1,
            FromDD: args.data.vacationFrom.getDate(),
            ToAAAA: args.data.vacationTo.getFullYear(),
            ToMM: args.data.vacationTo.getMonth() + 1,
            ToDD: args.data.vacationTo.getDate(),
            VacationNote: args.data.vacationNote,
          };
          cicassService
            .saveOp(apiUrl, dataToSave)
            .then(() => {
              this.refreshGrid(false);
              this.setToastInfo("Success", t("addToastOK"));
            })
            .catch((err) => {
              this.setToastInfo("Error", "I: " + err.message);
              this.refreshGrid(false);
            });
          break;
        }
        default:
          break;
      }
    }

    // DELETE and EDIT of DISABLED ELEMENT
    if (
      (args.requestType === "delete" &&
        args.tr[0]
          .querySelectorAll(".e-btn-icon.e-delete")[0]
          .classList.contains("e-disabled")) ||
      (args.requestType === "beginEdit" &&
        args.row
          .querySelectorAll(".e-btn-icon.e-edit")[0]
          .classList.contains("e-disabled"))
    )
      args.cancel = true;
  }

  settingContent(man, auto, from, to, actmonth) {
    let {
      disableMan,
      disableFrom,
      disableTo,
      selectedMonth,
      defaultDate,
      hideOldVacation,
    } = this.state;
    let disableAuto = false;
    if (disableFrom > 0 || disableTo > 0) disableAuto = true;
    return (
      <div className="control-section input-content-wrapper">
        <div className="row custom-margin">
          <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
            <SwitchComponent checked={disableMan} onChange={this.switchMan} />
          </div>
          <div className="col-xs-10 col-sm-10 col-lg-10 col-md-10">
            &nbsp;{man}
          </div>
        </div>
        <div className="row custom-margin">
          <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 mt-3">
            <SwitchComponent checked={disableAuto} onChange={this.switchAuto} />
          </div>
          <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4 mt-3">
            &nbsp;{auto}&nbsp;
          </div>
          <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1 mt-3">
            , {from}&nbsp;
          </div>
          <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
            <TextField
              type="number"
              value={disableFrom}
              onChange={(e) => this.onTxtChange(e, 1)}
              variant="standard"
            />
          </div>
          <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1 mt-3">
            &nbsp;{to}&nbsp;
          </div>
          <div className="col-xs-3 col-sm-2 col-lg-2 col-md-2">
            <TextField
              type="number"
              value={disableTo}
              onChange={(e) => this.onTxtChange(e, 2)}
              variant="standard"
            />
          </div>
        </div>
        <div className="row custom-margin">
          <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 mt-5">
            <SwitchComponent
              checked={selectedMonth}
              onChange={this.onSelectedMonthChange}
            />
          </div>
          <div className="col-xs-8 col-sm-8 col-lg-8 col-md-8 mt-5">
            &nbsp;{actmonth}
          </div>
          <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
            &nbsp;
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={itLocale}
            >
              <DatePicker
                views={["year", "month"]}
                label=""
                minDate={new Date("2022-01-01")}
                maxDate={maxDate}
                value={defaultDate}
                onChange={this.onMonthChange}
                renderInput={(params) => (
                  <TextField {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div className="row custom-margin">
          <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 mt-5">
            <SwitchComponent
              checked={hideOldVacation}
              onChange={this.onHideOldVacation}
            />
          </div>
          <div className="col-xs-8 col-sm-8 col-lg-8 col-md-8 mt-5">
            &nbsp;{"Escludi ferie già trascorse"}
          </div>
        </div>
      </div>
    );
  }
  render() {
    const {
      myData,
      didMountCompleted,
      userData,
      isCicass,
      toastInfo,
      isAdmin,
      disableOperation,
      disableFrom,
      disableTo,
      disableMan,
    } = this.state;
    const { t } = this.props;
    return (
      <>
        <div style={{ margin: "1.2rem 0 .5rem 1.2rem" }}>
          <h2 className="text-gray-800 text-xl font-semibold">
            {t("titleVacationJs")}
            {didMountCompleted && !disableMan && disableFrom && disableTo
              ? " (" +
                t("lockInsert") +
                " " +
                disableFrom +
                " " +
                t("lockTo") +
                " " +
                disableTo +
                " " +
                t("lockEveryMonth") +
                ")"
              : ""}
            {didMountCompleted && disableMan && " (" + t("lockNow") + ")"}
          </h2>
        </div>
        <div className="p-4 flex-auto">
          {didMountCompleted && isAdmin && (
            <AccordionComponent>
              <AccordionItemsDirective>
                <AccordionItemDirective
                  header={t("settingVacationJs")}
                  content={() =>
                    this.settingContent(
                      t("disableVacationManJs"),
                      t("disableVacationAutoJs"),
                      t("fromDay"),
                      t("toDay"),
                      t("currentMonthJs")
                    )
                  }
                />
              </AccordionItemsDirective>
            </AccordionComponent>
          )}

          {didMountCompleted && (
            <GridComponent
              id="gridcomp"
              ref={(grid) => (this.gridInstance = grid)}
              dataSource={myData}
              toolbar={toolbarOptions}
              allowResizing={true}
              editSettings={
                disableOperation ? editSettingsDisabled : editSettings
              }
              allowSorting={true}
              allowPdfExport={true}
              allowFiltering={true}
              filterSettings={filterSettings}
              allowExcelExport={true}
              allowPaging={true}
              pageSettings={{ pageSize: 15, pageCount: 5 }}
              actionBegin={this.actionBegin}
              actionComplete={this.actionComplete}
              toolbarClick={this.toolbarClick.bind(this)}
              locale="it"
              beforePrint={this.beforePrint.bind(this)}
              rowDataBound={this.onRowDataBound.bind(this)}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="id"
                  headerText={t("id")}
                  textAlign="Right"
                  isPrimaryKey={true}
                  defaultValue="-1"
                  allowEditing={false}
                  visible={isCicass}
                  width="80"
                />
                <ColumnDirective
                  field="anaUserId"
                  headerText={t("anaUserId")}
                  foreignKeyValue="name"
                  foreignKeyField="id"
                  dataSource={userData}
                  visible={isAdmin}
                  width="160"
                  validationRules={this.validationUser}
                />
                <ColumnDirective
                  field="vacationFrom"
                  headerText={t("vacationFrom")}
                  width={110}
                  editTemplate={this.editTemplateFrom.bind(this)}
                  validationRules={this.validationFrom}
                  template={this.viewTemplateFrom.bind(this)}
                />
                <ColumnDirective
                  field="vacationTo"
                  headerText={t("vacationTo")}
                  width={110}
                  editTemplate={this.editTemplateTo.bind(this)}
                  validationRules={this.validationTo}
                  template={this.viewTemplateTo.bind(this)}
                />
                <ColumnDirective
                  field="vacationNote"
                  headerText={t("vacationNote")}
                  allowEditing={true}
                />
                <ColumnDirective
                  field="anaAgencyId"
                  headerText={t("anaAgencyId")}
                  allowEditing={false}
                  visible={isCicass}
                  width="100"
                />
                <ColumnDirective
                  field="createdAt"
                  headerText={t("createdAt")}
                  visible={isCicass}
                  allowEditing={false}
                  width={120}
                  type="date"
                  format={"dd/MM/yyyy"}
                />
                <ColumnDirective
                  field="updatedAt"
                  headerText={t("updatedAt")}
                  visible={isCicass}
                  allowEditing={false}
                  width={120}
                  type="date"
                  format={"dd/MM/yyyy"}
                />
                <ColumnDirective
                  headerText={t("operationOnGrid")}
                  width="160"
                  textAlign="left"
                  commands={commands}
                />
              </ColumnsDirective>
              <Inject
                services={[
                  Resize,
                  Filter,
                  Page,
                  Toolbar,
                  Edit,
                  ForeignKey,
                  CommandColumn,
                  ExcelExport,
                  PdfExport,
                  Sort,
                ]}
              />
            </GridComponent>
          )}
          <ToastComponent
            ref={(toast) => {
              this.toastObj = toast;
            }}
            id="toast_default"
            position={this.position}
            title={toastInfo.title}
            content={toastInfo.content}
            cssClass={toastInfo.cssClass}
            icon={toastInfo.icon}
          />
        </div>
      </>
    );
  }
}
export default withTranslation()(Vacation);
