import axios from "axios";

axios.interceptors.response.use(null, error => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status <= 500;

  if (expectedError) {

    //toast.error("Attenzione: " + error.response.data, error);
    alert("Attenzione: " + error.response.data);
    if (error.response.data == ("Licenza SCADUTA contattare info@cicass.it")) window.location.href = "/license";;
  }
  return Promise.reject(error);
});

function setJwt(jwt) {
  axios.defaults.headers.common["Authorization"] = jwt;
  //console.log(jwt);
}

function checkHeader(jwt,clientInfo) {
  if (axios.defaults.headers.common["Authorization"] == undefined) axios.defaults.headers.common["Authorization"] = jwt;
  if (axios.defaults.headers.common["From"] == undefined) axios.defaults.headers.common["From"] = clientInfo;
}

function setClientInfo(clientInfo){
  if (axios.defaults.headers.common["From"] == undefined) {
    axios.defaults.headers.common["From"] = clientInfo;
  }
}


export default {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
  setJwt, checkHeader, setClientInfo
};
