import React from "react";
import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Page,
  Inject,
  Resize,
  Edit,
  CommandColumn,
  ForeignKey,
  Filter,
  Toolbar,
  ExcelExport,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import { auth, cicassService } from "../../../../_services";
import { withTranslation } from "react-i18next";

const editSettings = {
  allowEditing: false,
  allowAdding: true,
  allowDeleting: true,
  mode: "Dialog",
  dialog: { enableResize: true },
  showDeleteConfirmDialog: true,
};
const filterSettings = { type: "CheckBox" }; // Menu, Excel
const commands = [
  {
    type: "Delete",
    buttonOption: { iconCss: "e-icons e-delete", cssClass: "e-flat" },
  },
  {
    type: "Save",
    buttonOption: { iconCss: "e-icons e-update", cssClass: "e-flat" },
  },
  {
    type: "Cancel",
    buttonOption: { iconCss: "e-icons e-cancel-icon", cssClass: "e-flat" },
  },
];
const toolbarOptions = [
  "Search",
  "Add",
  "ExcelExport",
  "Print",
  {
    text: "Refresh",
    tooltipText: "Refresh",
    prefixIcon: "e-MT_reload",
    id: "forceRefresh",
  },
  {
    text: "Back",
    tooltipText: "Back",
    prefixIcon: "e-MT_Backward",
    id: "backToUserList",
  },
];
const apiUrl = "user_role";
const apiUrlAnaUserRole = "ana_user_role";

class UserRole extends React.Component {
  _isMounted = false;
  grid = null;

  constructor(props) {
    super(props);
    this.position = { X: "Right", Y: "Bottom" };
    this.state = {
      myData: [],
      visibleOnGrid: false,
      didMountCompleted: false,
      isCicass: false,
      roleData: [],
      anaUserIdSelected: 0,
      toastInfo: {
        title: "",
        content: "Succesfully",
        cssClass: "e-toast-success",
        icon: "e-success toast-icons",
      },
    };
    this.validationRules = { required: true };
    this.actionComplete = this.actionComplete.bind(this);
    this.actionBegin = this.actionBegin.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    const myRole = auth.getUserRole();
    const anaUserIdSelected = this.props.match.params.id;
    let { data: myData } = await cicassService.getAll(
      apiUrl + "/user/" + anaUserIdSelected
    );
    let { data: roleData } = await cicassService.getAll(apiUrlAnaUserRole);
    if (this._isMounted) {
      this.setState({
        didMountCompleted: true,
        myData,
        roleData,
        isCicass: myRole === "SuperUser" ? true : false,
        anaUserIdSelected,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toolbarClick(args) {
    switch (args.item.properties.id) {
      case "gridcomp_excelexport":
        this.gridInstance.excelExport();
        break;
      default:
        break;
    }
    if (args.item.id === "forceRefresh") {
      this.refreshGrid();
    }
    if (args.item.id === "backToUserList") {
      //window.location.href = "/app/user";
      this.props.history.push(`/app/user/`);
    }
  }

  refreshGrid() {
    const { anaUserIdSelected } = this.state;
    cicassService
      .getAll(apiUrl + "/user/" + anaUserIdSelected)
      .then((response) => {
        this.setState({ myData: response.data });
      });
  }

  actionBegin(state) {
    if (state.requestType === "beginEdit" || state.requestType === "add") {
      if (state.requestType === "add") {
        const { anaUserIdSelected } = this.state;
        state.data.id = -1;
        state.data.anaUserId = Number(anaUserIdSelected);
      }
      this.setState({ visibleOnGrid: true });
    }
  }

  actionComplete(state) {
    if (state.requestType === "add" || state.requestType === "beginEdit") {
      state.dialog.enableResize = true;
      //state.dialog.element.style.maxHeight = 900;
      state.dialog.width = 600;
      state.dialog.height = 600;
    }
    if (state.action === "add") {
      state.data.id = -1;
      cicassService
        .saveOp(apiUrl, state.data)
        .then((response) => {
          this.refreshGrid();
          this.setToastInfo("Success", this.props.t("toastSuccess"));
        })
        .catch((err) => {
          this.setToastInfo("Error", "I: " + err.message);
          this.refreshGrid();
        });
    }
    if (state.requestType === "delete") {
      cicassService
        .deleteOp(apiUrl, state.data[0].id)
        .then(() => {
          this.refreshGrid();
          this.setToastInfo("Success", this.props.t("toastSuccess"));
        })
        .catch((err) => {
          this.setToastInfo("Error", "D:" + err.message);
          this.refreshGrid();
        });
    }

    this.setState({ visibleOnGrid: false });
  }

  setToastInfo(title, errorMsg) {
    let { toastInfo } = this.state;
    switch (title) {
      case "Warning":
        toastInfo.title = "Warning";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-warning";
        toastInfo.icon = "e-warning toast-icons";
        break;
      case "Success":
        toastInfo.title = "Success";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-success";
        toastInfo.icon = "e-success toast-icons";
        break;
      case "Error":
        toastInfo.title = "Error";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-danger";
        toastInfo.icon = "e-error toast-icons";
        break;
      case "Information":
        toastInfo.title = "Information";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-info";
        toastInfo.icon = "e-info toast-icons";
        break;
      default:
        break;
    }
    this.setState({ toastInfo });
    this.toastObj.show();
    return;
  }

  render() {
    const { myData, didMountCompleted, roleData, isCicass, toastInfo } =
      this.state;
    const { t } = this.props;
    return (
      <>
        <div style={{ margin: "1%", marginTop: "1%" }}>
          <h2 className="text-gray-800 text-xl font-semibold">
            {t("titleUserRoleJs")} - {this.props.location.state}
          </h2>
        </div>
        <div className="p-4 flex-auto">
          {didMountCompleted && (
            <GridComponent
              id="gridcomp"
              ref={(grid) => (this.gridInstance = grid)}
              dataSource={myData}
              toolbar={toolbarOptions}
              editSettings={editSettings}
              allowResizing={true}
              allowFiltering={true}
              filterSettings={filterSettings}
              allowPaging={true}
              pageSettings={{ pageSize: 10, pageCount: 5 }}
              allowExcelExport={true}
              actionComplete={this.actionComplete}
              actionBegin={this.actionBegin}
              toolbarClick={this.toolbarClick.bind(this)}
              locale="it"
              allowSorting={true}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="id"
                  headerText={t("id")}
                  textAlign="Right"
                  isPrimaryKey={true}
                  defaultValue="-1"
                  allowEditing={false}
                  visible={isCicass}
                />
                <ColumnDirective
                  field="anaUserId"
                  headerText={t("anaUserId")}
                  validationRules={this.validationRules}
                  visible={isCicass}
                />
                <ColumnDirective
                  field="anaUserRoleId"
                  headerText={t("anaUserRoleId")}
                  foreignKeyValue="name"
                  foreignKeyField="id"
                  dataSource={roleData}
                  width="150"
                  validationRules={this.validationRules}
                />
                <ColumnDirective
                  field="createdAt"
                  headerText={t("createdAt")}
                  type="date"
                  format={"dd/MM/yyyy HH:mm:ss"}
                  visible={isCicass}
                  allowEditing={false}
                />
                <ColumnDirective
                  headerText={t("operationOnGrid")}
                  width="160"
                  commands={commands}
                ></ColumnDirective>
              </ColumnsDirective>
              <Inject
                services={[
                  Resize,
                  Filter,
                  Page,
                  Toolbar,
                  Edit,
                  ForeignKey,
                  CommandColumn,
                  ExcelExport,
                  Sort,
                ]}
              />
            </GridComponent>
          )}
          <ToastComponent
            ref={(toast) => {
              this.toastObj = toast;
            }}
            id="toast_default"
            position={this.position}
            title={toastInfo.title}
            content={toastInfo.content}
            cssClass={toastInfo.cssClass}
            icon={toastInfo.icon}
          />
        </div>
      </>
    );
  }
}
export default withTranslation()(UserRole);
