import React from "react";
import {
	Dialog,
	DialogContent,
	DialogTitle,
	IconButton,
	useTheme,
	useMediaQuery,
} from "@mui/material";
import {
	ColumnDirective,
	ColumnsDirective,
	GridComponent,
	Inject,
	Filter,
	Page,
	InfiniteScroll,
	CommandColumn,
	Resize
} from "@syncfusion/ej2-react-grids";
import CloseIcon from "@mui/icons-material/Close";

const SentAndRecievedRequestsDialog = (props) => {
	const {
		outRequestDataSource,
		inRequestDataSource,
		onCommandClick,
		senderRowDataBound,
		recieverRowDataBound,
		handleClose,
		isOpen,
		translate,
	} = props;
	const theme = useTheme();
	const isBreakpointReached = useMediaQuery(theme.breakpoints.down("md"));

	const sendCommands = [
		{
			type: "Delete",
			buttonOption: { iconCss: "e-icons e-delete", cssClass: "e-flat" },
		},
	];
	const receiveCommands = [
		{
			type: "Yes",
			buttonOption: { iconCss: "e-icons e-check-mark-01", cssClass: "e-flat" },
		},
		{
			type: "No",
			buttonOption: { iconCss: "e-icons e-close", cssClass: "e-flat" },
		},
	];

	function confirmRefuseTemplate(args) {
		console.log(args);
		return args;
	}

	return (
		<Dialog
			open={isOpen}
			fullWidth={true}
			fullScreen={isBreakpointReached}
			// maxWidth={"lg"}
			onClose={handleClose}
		>
			<DialogTitle
				id="responsive-dialog-title"
				sx={{
					display: "flex",
				}}
			>
				{translate("calendarMonthUserChangeList")}

				{isBreakpointReached && (
					<IconButton
						onClick={() => handleClose()}
						sx={{
							marginLeft: "auto",
						}}
					>
						<CloseIcon />
					</IconButton>
				)}
			</DialogTitle>
			<DialogContent>
				{outRequestDataSource && (
					<>
						<h1>{translate("sentReqs")}</h1>
						<GridComponent
							id="gridcompS"
							dataSource={outRequestDataSource}
							pageSettings={{ pageSize: 50 }}
							commandClick={onCommandClick}
							enableInfiniteScrolling={true}
							infiniteScrollSettings={{ initialBlocks: 1 }}
							rowDataBound={senderRowDataBound}
							locale="it"
							allowResizing={true}
						>
							<ColumnsDirective>
								<ColumnDirective
									field="fromDescription"
									headerText={translate("fromMyDescription")}
									autoFit={true}
									textAlign="center"
								/>
								<ColumnDirective
									field="toDescription"
									headerText={translate("toMyDescription")}
									autoFit={true}
									textAlign="center"
								/>
								<ColumnDirective
									field="confirmedAt"
									headerText={translate("confirmedAt")}
									type="date"
									format={"dd/MM/yyyy"}
									autoFit={true}
									textAlign="center"
								/>
								<ColumnDirective
									field="refusedAt"
									headerText={translate("refusedAt")}
									type="date"
									format={"dd/MM/yyyy"}
									autoFit={true}
									textAlign="center"
								/>

								{/* 
								<ColumnDirective
									field="confirmedAt"
									headerText={
										translate("confirmedAt") + "/" + translate("refusedAt")
									}
									type="date"
									format={"dd/MM/yyyy"}
									template={confirmRefuseTemplate}
								/>
								 */}
								<ColumnDirective
									headerText={translate("cancelCmd")}
									commands={sendCommands}
									autoFit={true}
									textAlign="center"
								/>
							</ColumnsDirective>
							<Inject
								services={[Resize,Filter, Page, InfiniteScroll, CommandColumn]}
							/>
						</GridComponent>
					</>
				)}
				<br />
				{inRequestDataSource && (
					<div>
						<h1>{translate("recievedReqs")}</h1>
						<GridComponent
							id="gridcompR"
							dataSource={inRequestDataSource}
							pageSettings={{ pageSize: 50 }}
							commandClick={onCommandClick}
							rowDataBound={recieverRowDataBound}
							enableInfiniteScrolling={true}
							infiniteScrollSettings={{ initialBlocks: 1 }}
							locale="it"
							allowResizing={true}
						>
							<ColumnsDirective>
								<ColumnDirective
									field="fromDescription"
									headerText={translate("fromOtherDescription")}
									autoFit={true}
									textAlign="center"
								/>
								<ColumnDirective
									field="toDescription"
									headerText={translate("toOtherDescription")}
									autoFit={true}
									textAlign="center"
								/>
								<ColumnDirective
									field="confirmedAt"
									headerText={translate("confirmedAt")}
									type="date"
									format={"dd/MM/yyyy"}
									autoFit={true}
									textAlign="center"
								/>
								<ColumnDirective
									field="refusedAt"
									headerText={translate("refusedAt")}
									type="date"
									format={"dd/MM/yyyy"}
									autoFit={true}
									textAlign="center"
								/>
								<ColumnDirective
									headerText={translate("confirmCmd")}
									commands={receiveCommands}
									autoFit={true}
									textAlign="center"
								/>
							</ColumnsDirective>
							<Inject services={[Resize,Filter, Page, InfiniteScroll]} />
						</GridComponent>
					</div>
				)}
			</DialogContent>
		</Dialog>
	);
};

export default SentAndRecievedRequestsDialog;
