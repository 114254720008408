var baseURL = "";
var root = window.location.protocol + "//" + window.location.host;
if (process.env.NODE_ENV !== "production") {
  baseURL = "http://localhost:5000";
} else {
  baseURL = root;
}
var apiURL = baseURL + "/api/";
var welcomeURL = "welcome";

export const config = {
  apiUrl: apiURL,
  root: root,
  baseUrl: baseURL,
  welcomeURL: welcomeURL,
  pageSize: 8,
  iconSize: 32, //,
  //autoCloseToast: 2000
};
