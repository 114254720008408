import React, { useEffect } from "react";
import TokenContext from "components/TokenContext";
import { auth } from "../../_services/authService";
import { Translation, withTranslation } from "react-i18next";
import Background from "../../assets/img/register_bg_2.png";
import Navbar from "components/Navbars/AuthNavbar.js";
import FooterSmall from "components/Footers/FooterSmall.js";

function Login(props) {
  const [denied, setDenied] = React.useState(true);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [wronguser, setWronguser] = React.useState(false); //inserito wrong username nella password dimenticata
  const [wrongcode, setWrongcode] = React.useState(false); //inserito wrong code
  const [forgot, setForgot] = React.useState(false); //setta la visione della page di pwd dimenticata
  const [showcode, setShowcode] = React.useState(false); //mostra text per inserimento code
  const [controlcode, setControlcode] = React.useState("");
  const useCounterContext = () => React.useContext(TokenContext);
  let { assignJwt } = useCounterContext();

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    const at = await auth.AuthCheckAccessToken();
    if (at != null) {
      setDenied(false);
      assignJwt(at);
      props.history.push("/app/home");
    }
  }

  async function handleSubmit() {
    try {
      auth
        .AuthLogin(username, password)
        .then((response) => {
          assignJwt(response);
          //console.log("After Login",jwt);
          setDenied(false);
          props.history.push("/app/home");
        })
        .catch(() => {
          setDenied(true);
        });
    } catch (e) {
      setDenied(true);
    }
  }

  async function handleGetCode() {
    try {
      auth
        .AuthSendResetCode(username)
        .then((response) => {
          setShowcode(true);
          setWronguser(false);
        })
        .catch(() => {
          setWronguser(true);
          setShowcode(false);
        });
    } catch (e) {
      setWronguser(true);
      setShowcode(false);
    }
  }

  async function handleSendCode() {
    try {
      auth
        .AuthResetPasswordByCode(username, controlcode)
        .then((response) => {
          //console.log("codice spedito");
          setForgot(false);
          setShowcode(false);
          setPassword("");
          //props.history.push("/auth/login");
          //props.history.push("/");
        })
        .catch(() => {
          setWrongcode(true);
        });
    } catch (e) {
      setWrongcode(true);
    }
  }

  async function handleKeyPress(event) {
    if (event.key === "Enter") {
      handleSubmit();
    }
  }

  const loginChanged = (e) => {
    //console.log(e.target.value);
    setUsername(e.target.value);
    //this.setState({ denied: '', username:  });
  };

  const passwordChanged = (e) => {
    setPassword(e.target.value);
    //this.setState({ denied: '', password: e.target.value });
  };

  const handleChange = (event) => {
    setUsername(event.target.value);
    setDenied(false);
  };
  const handleChangeCode = (event) => {
    setControlcode(event.target.value);
  };

  return (
    <Translation>
      {(t, { i18n }) => (
        <main>
          <Navbar transparent />
          <section className="relative w-full h-full py-40 min-h-screen">
            <div
              className="absolute top-0 w-full h-full bg-gray-900 bg-no-repeat bg-full"
              style={{ backgroundImage: `url(${Background})` }}
            >
              <div className="container mx-auto px-4 h-full">
                <div className="flex content-center items-center justify-center h-full">
                  <div className="w-full lg:w-4/12 px-4">
                    <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
                      <div className="rounded-t mb-0 px-6 py-6">
                        <div className="text-center mb-3">
                          <h6 className="text-gray-600 text-sm font-bold">
                            VoluntHUB | {t("Login")}
                          </h6>
                        </div>
                        <hr className="mt-6 border-b-1 border-gray-400" />
                      </div>
                      {!forgot && (
                        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                          <div className="text-gray-500 text-center mb-3 font-bold">
                            <small>{t("credentials")}</small>
                          </div>
                          <form>
                            <div className="relative w-full mb-3">
                              <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                                {t("email")}
                              </label>
                              <input
                                type="email"
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                value={username}
                                onChange={loginChanged}
                                onKeyUp={handleKeyPress}
                                placeholder={t("email")}
                              />
                            </div>

                            <div className="relative w-full mb-3">
                              <label
                                className="block uppercase text-gray-700 text-xs font-bold mb-2"
                                htmlFor="grid-password"
                              >
                                {t("password")}
                              </label>
                              <input
                                type="password"
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                mode={"password"}
                                value={password}
                                onChange={passwordChanged}
                                onKeyUp={handleKeyPress}
                                placeholder={t("password")}
                              />
                            </div>

                            <div className="text-center mt-6">
                              <button
                                className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                type="button"
                                onClick={handleSubmit}
                              >
                                {t("Login")}
                              </button>
                            </div>
                          </form>
                        </div>
                      )}
                      {forgot && (
                        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                          <form>
                            <div className="relative w-full mb-3">
                              <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                {t("introForgotPwd")}
                              </label>
                              <input
                                type="email"
                                className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                value={username}
                                onChange={handleChange}
                                placeholder={t("email")}
                              />
                            </div>
                            <div className="text-center mt-6">
                              <button
                                className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                type="button"
                                onClick={handleGetCode}
                              >
                                {t("sendCode")}
                              </button>
                            </div>
                            {showcode && (
                              <>
                                <div className="relative w-full mb-3">
                                  <label className="block uppercase text-gray-700 text-xs font-bold mb-2">
                                    {t("pleaseconfirmCode")}
                                  </label>
                                  <input
                                    type="text"
                                    className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full ease-linear transition-all duration-150"
                                    value={controlcode}
                                    onChange={handleChangeCode}
                                    placeholder={t("controlCode")}
                                  />
                                </div>
                                <button
                                  className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                                  type="button"
                                  onClick={handleSendCode}
                                >
                                  {t("confirmCode")}
                                </button>
                              </>
                            )}

                            {wronguser && (
                              <div className="relative w-full mb-3">
                                &nbsp;{t("wrongUser")}.
                              </div>
                            )}
                            {wrongcode && (
                              <div className="relative w-full mb-3">
                                &nbsp;{t("wrongCode")}.
                              </div>
                            )}
                          </form>
                        </div>
                      )}
                    </div>
                    <div className="flex flex-wrap mt-6 relative">
                      <button
                        className="bg-gray-900 text-white text-sm font-bold uppercase px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setForgot(!forgot)}
                      >
                        {!forgot && <small>{t("forgotPassword")}?</small>}
                        {forgot && <small>{t("Login")}</small>}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FooterSmall absolute />
          </section>
        </main>
      )}
    </Translation>
  );
}
export default withTranslation()(Login);
