import React from "react";
import { withTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Link from '@mui/material/Link';
import { IsMobile, useStyles, IsTablet } from '../../_services/materialService';

export default function FooterAdmin() {

  function Copyright({ ismobile }) {
    //let href = ls.getSupplierDescription("SUPPLIERLINK");
    const version = "Rel. " + global.appVersion;
    return (
      <Typography variant="body2" align="center">
        <b><Link className={classes.linknone} color="inherit" href="https://www.volunthub.com/">Volunthub.com</Link></b>
        {ismobile && <br />}
        {!ismobile && ' | '}
        {'Copyright © '} {new Date().getFullYear() + ' '}<Link className={classes.linknone} color="inherit" href="https://www.cicass79.com/" target="_blank">CICASS79 S.L.</Link> - <small>{version}</small>
      </Typography>
    );
  }

  const ismobile = IsMobile();
  const classes = useStyles();
  return (
    <React.Fragment>
      <div className='footer-ghost'></div>
      <footer className={classes.footer}>
        {/* <Typography variant="h6" align="center" gutterBottom>Volunthub.com</Typography> */}
        <Copyright ismobile={ismobile}/>
      </footer>
    </React.Fragment>

  );
}
