import http from "./httpService";
import { config } from "../_helpers";
import { saveAs } from "file-saver";

export const cicassService = {
	getAll,
	getSearch,
	getSubset,
	getDefault,
	getById,
	getYesNo,
	getWithCustomUrl,
	deleteOp,
	saveOp,
	postArray,
	simplePost,
	put,
	putArray,
	returnDataToUpdate,
	returnDataToInsert,
	uploadFile,
	devExtremeUploadFile,
	downloadFile,
	downloadCsvFile,
	downloadFilePost,
	downloadZip,
	downloadBlob64,
};

export function downloadCsvFile(url, name) {
	return http
	  .get(config.apiUrl + url, {
		responseType: "blob",
	  })
	  .then((response) => {
		let typeF = "text/csv";
		var newBlob = new Blob([response.data], { type: typeF });
		name = name.replace(/\./g, "");
		saveAs(newBlob, name + ".csv");
	  });
  }

export const yesNoArray = [
	{ id: 0, name: "NO" },
	{ id: 1, name: "SI" },
];

export function getYesNo() {
	return yesNoArray.filter((g) => g);
}

function singleDataUrl(url, id) {
	return `${config.apiUrl}${url}/${id}`;
}

export function getAll(url) {
	return http.get(config.apiUrl + url);
}

export function getSearch(url, searchadd, searchsub, job, typeD, choice) {
	return http.get(
		config.apiUrl +
			url +
			"?searchadd=" +
			encodeURIComponent(searchadd) +
			"&searchsub=" +
			encodeURIComponent(searchsub) +
			"&job=" +
			encodeURIComponent(job) +
			"&typeD=" +
			encodeURIComponent(typeD) +
			"&choice=" +
			choice
	);
}

export function getSubset(url, fromS, toS) {
	return http.get(`${config.apiUrl}${url}?fromS=${fromS}&toS=${toS}`);
}

export function getDefault(url) {
	return http.get(config.apiUrl + url + "/default");
}

export function getById(url, id) {
	return http.get(singleDataUrl(url, id));
}

export function getWithCustomUrl(url) {
	return http.get(config.apiUrl + url);
}

export function deleteOp(url, id) {
	return http.delete(singleDataUrl(url, id));
}

export function saveOp(url, data) {
	const copyData = { ...data };
	if (data.id !== -1) {
		//console.log("ID",data.id, "Data",data);
		return http.put(singleDataUrl(url, data.id), copyData);
	}
	delete copyData.id;
	return http.post(config.apiUrl + url, copyData);
}

export function simplePost(url, data) {
	return http.post(config.apiUrl + url, data);
}

export function postArray(url, data) {
	const copyData = data.slice(0, data.length);
	for (var i = 0; i < copyData.length; i++) {
		delete copyData[i].id;
	}
	//console.log(copyData);
	return http.post(config.apiUrl + url, copyData);
}

export function put(url, data) {
	return http.put(config.apiUrl + url, data);
}

export function putArray(url, data) {
	//console.log(copyData);
	return http.put(config.apiUrl + url, data);
}

export function returnDataToUpdate(fieldList, oldData, newData) {
	let dataToSave = {};
	for (let k = 0; k < fieldList.length; k++) {
		// Assegno tutti i campi della tabella
		dataToSave[fieldList[k]] = oldData[fieldList[k]];
	}
	for (var myKey in newData) {
		// Riscrivo i campi cambiati
		dataToSave[myKey] = newData[myKey];
	}
	return dataToSave;
}

export function returnDataToInsert(fieldList, newData) {
	let dataToSave = {};
	dataToSave["id"] = -1;
	for (var myKey in newData) {
		// Riscrivo i campi cambiati
		dataToSave[myKey] = newData[myKey];
	}
	return dataToSave;
}

export function uploadFile(myUrl, fileUploaded) {
	//console.log(config.apiUrl + myUrl, fileUploaded);
	return http.post(config.apiUrl + myUrl, fileUploaded, {
		headers: { "Content-Type": "multipart/form-data" },
	});
	//return http.post(config.apiUrl + 'upload', fileUploaded,{ headers: {'Content-Type': undefined}});
	//   axios.post("http://localhost:8000/upload", data, {
	//       // receive two    parameter endpoint url ,form data
	//   })
	// .then(res => { // then print response status
	//     console.log(res.statusText)
	//  })
}

export function devExtremeUploadFile(url, param) {
	return (
		config.apiUrl + url + "/uploaddevext" + (param == "" ? "" : "/" + param)
	);
}

export function downloadFile(url, name, ext) {
	return http
		.get(config.apiUrl + url, {
			responseType: "blob",
		})
		.then((response) => {
			// gestire estensione ext from Mozilla.org MIME TYPES
			let typeF = "";
			switch (ext.toUpperCase()) {
				case "PDF":
					typeF = "application/pdf";
					break;
				case "DOC":
					typeF = "application/msword";
					break;
				case "DOCX":
					typeF =
						"application/vnd.openxmlformats-officedocument.wordprocessingml.document";
					break;
				case "XLS":
					typeF = "application/vnd.ms-excel";
					break;
				case "XLSX":
					typeF =
						"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
					break;
			}

			var newBlob = new Blob([response.data], { type: typeF });
			//saveAs(newBlob, name + "." + ext);
		});
}

export function downloadFilePost(url, data, ext) {
	const copyData = { ...data };
	delete copyData.id;
	return http
		.post(config.apiUrl + url, copyData, {
			responseType: "blob",
		})
		.then((response) => {
			// gestire estensione ext from Mozilla.org MIME TYPES
			let typeF = "";
			switch (ext.toUpperCase()) {
				case "PDF":
					typeF = "application/pdf";
					break;
				case "DOC":
					typeF = "application/msword";
					break;
				case "DOCX":
					typeF =
						"application/vnd.openxmlformats-officedocument.wordprocessingml.document";
					break;
				case "XLS":
					typeF = "application/vnd.ms-excel";
					break;
				case "XLSX":
					typeF =
						"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
					break;
			}

			var newBlob = new Blob([response.data], { type: typeF });
			saveAs(newBlob, "download." + ext);
		});
}

export function downloadZip(url, id, name) {
	return http
		.get(singleDataUrl(url, id), {
			responseType: "arraybuffer",
		})
		.then((response) => {
			//console.log(response.data);
			var newBlob = new Blob([response.data], { type: "application/zip" });
			// saveAs(newBlob, name + ".zip");
		});
}

export function downloadBlob64(response, name) {
	var newBlob = new Blob([response], { type: "application/pdf" });
	//  saveAs(newBlob, name);
}
