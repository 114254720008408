import { useTheme, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const IsMobile = () => {
	//is mobile
	const theme = useTheme();
	// const matches = useMediaQuery(theme.breakpoints.down("sm")); //breakpoints: 'xs' 0 | 'sm' 600 | 'md' 960 | 'lg' 1280 | 'xl' 1920
	const matches = useMediaQuery("(max-width:700px)"); //breakpoints: 'xs' 0 | 'sm' 600 | 'md' 960 | 'lg' 1280 | 'xl' 1920
	return matches;
};

export const IsTablet = () => {
	//is tablet
	const theme = useTheme();
	const matches = useMediaQuery(theme.breakpoints.down("md")); //breakpoints: 'xs' 0 | 'sm' 600 | 'md' 960 | 'lg' 1280 | 'xl' 1920
	return matches;
};

export const useStyles = makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(8),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	txtF: {
		margin: theme.spacing(1),
		width: "100%",
	},
	link: {
		margin: theme.spacing(1, 1.5, "!important"),
		color: "white !important",
		textDecoration: "none  !important",
		"&:hover": {
			textDecoration: "underline  !important",
		},
		cursor: "pointer !important",
	},
	linknone: {
		textDecoration: "none  !important",
	},
	icon: {
		marginRight: theme.spacing(2),
	},
	icondel: {
		marginRight: theme.spacing(2),
		color: "#ffffff",
	},
	heroContent: {
		//backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(4, 0, 0),
	},
	heroButtons: {
		marginTop: theme.spacing(4),
	},
	check: {
		color: theme.palette.error.light,
	},
	button: {
		marginLeft: "10px",
		top: "16px",
		//color: 'white'
	},
	perimeter: {
		borderLeftWidth: "1px",
		borderRightWidth: "1px",
		marginLeft: "1px",
		//border: '1px solid rgba(0, 0, 0, 0.12)',
		padding: "12px",
	},
	container: {
		display: "flex",
		flexWrap: "wrap",
	},
	contenuto: {
		padding: "10px",
		//width: '90%'
	},
	pieceofpaper: {
		padding: "16px",
		margin: "10px",
	},
	selectLabel: {
		//marginLeft: theme.spacing(1),
		marginTop: -theme.spacing(2),
		width: "100%",
	},
	activityAvatar: {
		height: theme.spacing(24),
		width: theme.spacing(24),
	},
	webCamera: {
		border: "1px solid #a285b6",
		margin: "4px",
	},
	webCameraOverlay: {
		position: "absolute",
		top: -64,
		bottom: 0,
		left: 0,
		right: 0,
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		color: "white",
	},
	imageList: {
		width: 500,
		height: 450,
	},
	addContent: {
		marginLeft: "10px !important",
	},
	pointer: {
		cursor: "pointer",
	},
	submit: {
		margin: theme.spacing(3, 0, 2),
	},
	toolbar: {
		flexWrap: "wrap",
	},
	logo: {
		paddingRight: "8px",
	},
	toolbarTitle: {
		flexGrow: 0.1,
		cursor: "pointer",
		maxWidth: "fit-content",
		marginRight: "2rem !important",
	},
	rightToolbar: {
		marginLeft: "auto",
		marginRight: -12,
	},
	footer: {
		backgroundColor: theme.palette.background.paper,
		padding: theme.spacing(1),
		height: "50px",
		position: "absolute",
		bottom: "0px",
		width: "100%",
	},
}));
