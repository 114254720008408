import React, { useEffect, useState, useMemo } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Box,
  Typography,
} from "@mui/material";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
// import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  TextBoxComponent,
  NumericTextBoxComponent,
} from "@syncfusion/ej2-react-inputs";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { auth, cicassService } from "../../../../_services";
import { ColumnDirective } from "@syncfusion/ej2-react-grids";

const UserRoleDialog = props => {
  const {
    dataSource,
    onOpen,
    onSubmit,
    translate,
    handleClose,
    isOpen,
    userId,
    currentUserRoles,
  } = props;
  const [roleOptions, setRoleOptions] = useState([]);
  // const [currentUserRoles, setCurrentUserRoles] = useState([]);
  const apiEndpointRoles = "user_role";
  const apiEndpointGetRoles = "ana_user_role";

  // useEffect(() => {
  //   fetchData();
  // }, []);

  useMemo(() => {
    fetchData();
  }, []);

  async function fetchData() {
    // console.log(dataSource);
    let { data } = await cicassService.getAll(apiEndpointGetRoles);
    setRoleOptions(data);
    // console.log(currentUserRoles);
  }

  async function handleSwitchChange(event, roleId, selectedRole) {
    try {
      if (!event.checked) {
        // console.log(roleData);
        await cicassService.deleteOp(apiEndpointRoles, selectedRole.id);
        return;
      }

      const dataToSave = {
        id: -1,
        anaUserId: dataSource.id,
        anaUserRoleId: roleId,
      };

      await cicassService.saveOp(apiEndpointRoles, dataToSave);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <>
      {!!roleOptions.length && (
        <Dialog
          open={isOpen}
          onClose={handleClose}>
          <DialogTitle
            id="responsive-dialog-title"
            sx={{
              mb: 1,
            }}>
            {translate("titleUserRoleJs") +
              ` ${dataSource.name} ${dataSource.surname}`}
          </DialogTitle>
          <DialogContent>
            {roleOptions.map(role => {
              const userRoleData = currentUserRoles.find(
                currentUserRole => currentUserRole.anaUserRoleId === role.id
              );

              const isChecked = !!userRoleData;
              return (
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    mb: "1rem",
                  }}>
                  <SwitchComponent
                    id={role.id}
                    checked={isChecked}
                    change={e => handleSwitchChange(e, role.id, userRoleData)}
                  />
                  <Typography color="black">{role.description}</Typography>
                </Box>
              );
            })}
          </DialogContent>
        </Dialog>
      )}
    </>
  );
};

export default UserRoleDialog;
