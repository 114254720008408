import React from "react";
import { cicassService, auth } from "../../../_services";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { TreeViewComponent } from "@syncfusion/ej2-react-navigations";
import { withTranslation } from "react-i18next";

const apiUrlPagePermission = "authpage/setpermission";
const apiUrlPage = "authpage";
const apiUrlRole = "authrole";
const apiUrlAuthorize = "authauthorizepage";
const paddingLeft = { paddingLeft: "20px" };

class AuthPage extends React.Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      allItems: null,
      allRoles: null,
      pageArray: null,
      selectedPage: 0,
      hideCheckBox: true,
    };
    this.handleItemClickTree = this.handleItemClickTree.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);
  }

  async componentDidMount() {
    const { t, i18n } = this.props;
    this._isMounted = true;
    let { data: fromDB } = await cicassService.getAll(apiUrlPagePermission);
    let { data: allRoles } = await cicassService.getAll(apiUrlRole);
    let { data: pageArray } = await cicassService.getAll(apiUrlPage);
    // Solo 2 livelli
    if (this._isMounted) {
      for (let pa = 0; pa < fromDB.length; pa++) {
        fromDB[pa].Text = t(fromDB[pa].Text);
        if (fromDB[pa].items != undefined) {
          for (let ch = 0; ch < fromDB[pa].items.length; ch++) {
            fromDB[pa].items[ch].Text = t(fromDB[pa].items[ch].Text);
          }
        }
      }
      const allItems = {
        dataSource: fromDB,
        id: "Id",
        text: "Text",
        child: "items",
      };
      for (let i = 0; i < allRoles.length; i++) {
        allRoles[i].checkBoxValue = false;
      }
      this.setState({ allRoles, allItems, pageArray });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async handleItemClickTree(args) {
    //console.log("Selected:",args.node.dataset.uid);
    let { data: authSaved } = await cicassService.getWithCustomUrl(
      apiUrlAuthorize + "/page/" + args.node.dataset.uid
    );
    let { allRoles, pageArray } = this.state;
    for (let i = 0; i < allRoles.length; i++) {
      let findInSave = authSaved.filter((g) => g.authRoleId == allRoles[i].id);
      //console.log("Ruolo: ",allRoles[i].id, "Trovato nei salvataggi:",findInSave);
      if (findInSave.length == 1)
        allRoles[i].checkBoxValue = findInSave[0].enabled == 1 ? true : false;
      else allRoles[i].checkBoxValue = false;
    }
    let hideCheckBox =
      pageArray.filter((q) => q.id == args.node.dataset.uid)[0].isFolder == 0
        ? false
        : true; //e.itemData.is_folder;
    this.setState({
      selectedPage: args.node.dataset.uid,
      allRoles,
      hideCheckBox,
    });
  }

  handleCheckBoxChange(args) {
    //console.log("ID:",args.event.srcElement.id, "Value:", args.checked);
    let { allRoles, selectedPage } = this.state;
    for (let i = 0; i < allRoles.length; i++) {
      if (allRoles[i].id == Number(args.event.srcElement.id)) {
        allRoles[i].checkBoxValue = args.checked;
        let myDataToSave = {
          id: -1,
          authRoleId: Number(args.event.srcElement.id),
          enabled: Number(args.checked),
          authPageId: Number(selectedPage),
        };
        let data = cicassService.saveOp(apiUrlAuthorize, myDataToSave);
      }
    }
    this.setState({ allRoles });
  }
  render() {
    const { allItems, allRoles, hideCheckBox } = this.state; //items={allItems}
    const { t, i18n } = this.props;
    return (
      allItems && (
        <div style={{ padding: "16px" }}>
          <h2 className="text-gray-800 text-xl font-semibold">
            Autorizzazioni Pagine (Cicass79)
          </h2>
          <div className="flex-container">
            <div className="flex flex-row">
              <TreeViewComponent
                fields={allItems}
                nodeClicked={this.handleItemClickTree}
                expandAll={true}
              />
              {allRoles && (
                <div style={{ padding: "30px" }}>
                  {allRoles.map((item, index) => (
                    <div
                      style={{ marginBottom: "10px" }}
                      className="checkbox-control"
                      key={index}
                      hidden={hideCheckBox}
                    >
                      <div className="row">
                        <CheckBoxComponent
                          id={item.id}
                          checked={item.checkBoxValue}
                          label={t(item.description)}
                          change={this.handleCheckBoxChange}
                        ></CheckBoxComponent>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )
    );
  }
}
export default withTranslation()(AuthPage);
