import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Page,
  Inject,
  Resize,
  Edit,
  CommandColumn,
  ForeignKey,
  Filter,
  Toolbar,
  ExcelExport,
  Sort,
  getStateEventArgument,
} from "@syncfusion/ej2-react-grids";
import { L10n, getValue } from "@syncfusion/ej2-base";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { rippleMouseHandler } from "@syncfusion/ej2-buttons";
import EJ2_LOCALE from "../../../../../node_modules/@syncfusion/ej2-locale/src/it.json";
import { auth, cicassService } from "../../../../_services";
import React from "react";
import { withTranslation } from "react-i18next";

const editSettings = {
  allowEditing: false,
  allowAdding: true,
  allowDeleting: true,
  mode: "Dialog",
  dialog: { enableResize: true },
  showDeleteConfirmDialog: true,
};
const filterSettings = { type: "CheckBox" }; // Menu, Excel
const commands = [
  {
    type: "Delete",
    buttonOption: { iconCss: "e-icons e-delete", cssClass: "e-flat" },
  },
  {
    type: "Save",
    buttonOption: { iconCss: "e-icons e-update", cssClass: "e-flat" },
  },
  {
    type: "Cancel",
    buttonOption: { iconCss: "e-icons e-cancel-icon", cssClass: "e-flat" },
  },
  {
    type: "Enable/Disable",
    buttonOption: { iconCss: "e-icons e-M_CheckBox", cssClass: "e-flat" },
  },
];
const toolbarOptions = [
  "Search",
  "Add",
  "ExcelExport",
  "Print",
  {
    text: "Refresh",
    tooltipText: "Refresh",
    prefixIcon: "e-MT_reload",
    id: "forceRefresh",
  },
];
const apiUrl = "attendance_manager";
const apiUrlAllUser = "authlogin/manager";

L10n.load({ it: EJ2_LOCALE.it });

class AttendanceManager extends React.Component {
  _isMounted = false;
  grid = null;

  constructor(props) {
    super(props);
    this.position = { X: "Right", Y: "Bottom" };
    this.state = {
      myData: [],
      visibleOnGrid: false,
      didMountCompleted: false,
      isCicass: false,
      isAdmin: false,
      userData: [],
      enableData: cicassService.getYesNo(),
      toastInfo: {
        title: "",
        content: "Succesfully",
        cssClass: "e-toast-success",
        icon: "e-success toast-icons",
      },
    };
    this.validationRules = { required: true };
    this.actionComplete = this.actionComplete.bind(this);
    this.actionBegin = this.actionBegin.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    const myrole = auth.getUserRole();
    let { data: myData } = await cicassService.getAll(apiUrl);
    let { data: userData } = await cicassService.getAll(apiUrlAllUser);

    if (this._isMounted) {
      this.setState({
        didMountCompleted: true,
        myData,
        userData,
        isCicass: myrole == "SuperUser" ? true : false,
        isAdmin: myrole == "Admin" || myrole == "SuperUser" ? true : false,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toolbarClick(args) {
    switch (args.item.properties.id) {
      case "gridcomp_excelexport":
        this.gridInstance.excelExport();
        break;
    }
    if (args.item.id === "forceRefresh") {
      this.refreshGrid();
    }
  }

  refreshGrid() {
    cicassService.getAll(apiUrl).then((response) => {
      this.setState({ myData: response.data });
    });
  }

  actionBegin(state) {
    if (state.requestType == "beginEdit" || state.requestType == "add") {
      if (state.requestType == "add") {
        state.data.id = -1;
        state.data.enabled = 1;
      }
      this.setState({ visibleOnGrid: true });
    }
  }

  actionComplete(state) {
    if (state.requestType === "add" || state.requestType === "beginEdit") {
      state.dialog.enableResize = true;
      //state.dialog.element.style.maxHeight = 900;
      state.dialog.width = 600;
      state.dialog.height = 600;
      this.rowData = Object.assign({}, state.rowData);
    }
    if (state.requestType === "save") {
      const Enabled = "enabled";
      state.data[Enabled] = this.rowData[Enabled] ? 1 : 0;
    }

    if (state.action === "add") {
      state.data.id = -1;
      state.data.createdAt = new Date();
      cicassService
        .saveOp(apiUrl, state.data)
        .then(() => {
          this.setToastInfo("Success", this.props.t("toastSuccess"));
        })
        .catch((err) => {
          console.error("Error on Add: ", err);
          this.setToastInfo("Error", "I: " + err.message);
        })
        .finally(() => {
          this.refreshGrid();
        });
    }
    if (state.requestType === "delete") {
      cicassService
        .deleteOp(apiUrl, state.data[0].id)
        .then(() => {
          this.setToastInfo("Success", this.props.t("toastSuccess"));
        })
        .catch((err) => {
          console.error("Error on Delete: ", err);
          this.setToastInfo("Error", "D:" + err.message);
        })
        .finally(() => {
          this.refreshGrid();
        });
    }

    this.setState({ visibleOnGrid: false });
  }

  setToastInfo(title, errorMsg) {
    let { toastInfo } = this.state;
    switch (title) {
      case "Warning":
        toastInfo.title = "Warning";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-warning";
        toastInfo.icon = "e-warning toast-icons";
        break;
      case "Success":
        toastInfo.title = "Success";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-success";
        toastInfo.icon = "e-success toast-icons";
        break;
      case "Error":
        toastInfo.title = "Error";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-danger";
        toastInfo.icon = "e-error toast-icons";
        break;
      case "Information":
        toastInfo.title = "Information";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-info";
        toastInfo.icon = "e-info toast-icons";
        break;
    }
    this.setState({ toastInfo });
    this.toastObj.show();
    return;
  }

  async commandClick(args) {
    if (args.commandColumn.type === "Enable/Disable") {
      cicassService
        .getById(apiUrl + "/enable", args.rowData.id)
        .then(() => {
          this.refreshGrid();
          this.setToastInfo("Success", this.props.t("toastSuccess"));
        })
        .catch((err) => {
          this.setToastInfo("Error", "EN.DI: " + err.message);
          this.refreshGrid();
        });
    }
  }

  editTemplateSc(args) {
    let isChecked = getValue("enabled", args);
    const { t } = this.props;
    return (
      <div>
        <label htmlFor="checked" style={{ padding: "10px 72px 10px 0" }}>
          {t("enabled")}
        </label>
        <SwitchComponent
          id="checked"
          checked={isChecked}
          change={this.changeFnSc.bind(this)}
        />
      </div>
    );
  }

  changeFnSc(e) {
    if (this.rowData) {
      this.rowData["enabled"] = e.checked;
    }
  }

  templateSc(args) {
    let isChecked = getValue("enabled", args) == 1 ? true : false;
    if (isChecked) return <i className="far fa-check-square"></i>;
    return <i className="far fa-square"></i>;
  }

  rendereComplete() {
    let elemArray = document.querySelectorAll(".switch-control label");
    for (let i = 0, len = elemArray.length; i < len; i++) {
      elemArray[i].addEventListener("mouseup", rippleHandler);
      elemArray[i].addEventListener("mousedown", rippleHandler);
    }
    function rippleHandler(e) {
      let rippleSpan = this.nextElementSibling.querySelector(
        ".e-ripple-container"
      );
      if (rippleSpan) {
        rippleMouseHandler(e, rippleSpan);
      }
    }
  }

  render() {
    const { myData, didMountCompleted, userData, isCicass, toastInfo } =
      this.state;
    const { t } = this.props;
    return (
      <>
        <div style={{ margin: "1%", marginTop: "1%" }}>
          <h2 className="text-gray-800 text-xl font-semibold">
            {t("titleAttendanceManagerJs")}
          </h2>
        </div>
        <div className="p-4 flex-auto">
          {didMountCompleted && (
            <GridComponent
              id="gridcomp"
              ref={(grid) => (this.gridInstance = grid)}
              dataSource={myData}
              toolbar={toolbarOptions}
              editSettings={editSettings}
              allowResizing={true}
              allowFiltering={true}
              filterSettings={filterSettings}
              allowPaging={true}
              pageSettings={{ pageSize: 10, pageCount: 5 }}
              allowExcelExport={true}
              actionComplete={this.actionComplete}
              actionBegin={this.actionBegin}
              toolbarClick={this.toolbarClick.bind(this)}
              locale="it"
              allowSorting={true}
              commandClick={this.commandClick.bind(this)}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="id"
                  headerText={t("id")}
                  textAlign="Right"
                  isPrimaryKey={true}
                  defaultValue="-1"
                  allowEditing={false}
                  visible={isCicass}
                />
                <ColumnDirective
                  field="anaUserId"
                  headerText={t("anaUserIdManager")}
                  foreignKeyValue="name"
                  foreignKeyField="id"
                  dataSource={userData}
                  width="150"
                  validationRules={this.validationRules}
                />
                <ColumnDirective
                  field="enabled"
                  headerText={t("enabled")}
                  editType="numericedit"
                  editTemplate={this.editTemplateSc.bind(this)}
                  template={this.templateSc.bind(this)}
                />
                <ColumnDirective
                  field="createdAt"
                  headerText={t("createdAt")}
                  type="date"
                  format={"dd/MM/yyyy HH:mm:ss"}
                  visible={isCicass}
                  allowEditing={false}
                />
                <ColumnDirective
                  field="updatedAt"
                  headerText={t("updatedAt")}
                  type="date"
                  format={"dd/MM/yyyy HH:mm:ss"}
                  visible={isCicass}
                  allowEditing={false}
                />
                <ColumnDirective
                  headerText={t("operationOnGrid")}
                  width="160"
                  commands={commands}
                ></ColumnDirective>
              </ColumnsDirective>
              <Inject
                services={[
                  Resize,
                  Filter,
                  Page,
                  Toolbar,
                  Edit,
                  ForeignKey,
                  CommandColumn,
                  ExcelExport,
                  Sort,
                ]}
              />
            </GridComponent>
          )}
          <ToastComponent
            ref={(toast) => {
              this.toastObj = toast;
            }}
            id="toast_default"
            position={this.position}
            title={toastInfo.title}
            content={toastInfo.content}
            cssClass={toastInfo.cssClass}
            icon={toastInfo.icon}
          ></ToastComponent>
        </div>
      </>
    );
  }
}
export default withTranslation()(AttendanceManager);
