import React from 'react';
import './ticket.css';

export function formatDateTime(data) {
  const d = new Date(data);
  const t = d.toLocaleTimeString('it-IT', { hour: '2-digit', minute: '2-digit' });
  const ye = new Intl.DateTimeFormat('it-IT', { year: 'numeric' }).format(d);
  const mo = new Intl.DateTimeFormat('it-IT', { month: '2-digit' }).format(d);
  const da = new Intl.DateTimeFormat('it-IT', { day: '2-digit' }).format(d);
  return `${da}/${mo}/${ye} ${t}`;
}

export function TicketHeader(arrayHeader, renderStatusColor) {
  return (
    <header className="msger-header">
      <div className="msger-header-title">
        {renderStatusColor(arrayHeader[0].value)}
      </div>
      <div className="msger-header-options">
        {arrayHeader[3].name} <i>{formatDateTime(arrayHeader[3].value)}</i> {arrayHeader[4].name} <i>{arrayHeader[4].value}</i>
      </div>
    </header>
  );
}

export function ChatDetail(item, i, ownerLogin) {
  return (
    <div key={i == 0 ? 0 : (i + 1)} className={item.owner == ownerLogin ? "msg right-msg" : "msg left-msg"}>
      <div className="msg-bubble">
        <div className="msg-info">
          <div className="msg-info-name">{item.owner}</div>
          <div className="msg-info-time">{formatDateTime(item.createdAt)}</div>
        </div>
        <div className="msg-text">
          {item.message}
        </div>
      </div>
    </div>);
}

