import React from "react";
import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Page,
  Inject,
  Resize,
  Edit,
  CommandColumn,
  ForeignKey,
  Filter,
  Toolbar,
  ExcelExport,
  Group,
} from "@syncfusion/ej2-react-grids";
import { L10n, getValue } from "@syncfusion/ej2-base";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import { TicketHeader, ChatDetail } from "./ticket-design.js";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
//import * as EJ2_LOCALE from '../../../../node_modules/@syncfusion/ej2-locale/src/it.json'
import { auth, cicassService } from "../../../_services";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DialogComponent } from "@syncfusion/ej2-react-popups";
import { withTranslation } from "react-i18next";
import "./ticket.css";
import TokenContext from "components/TokenContext.js";

const editSettings = {
  allowAdding: true,
  allowDeleting: true,
  mode: "Dialog",
  dialog: { enableResize: true },
  showDeleteConfirmDialog: true,
};
const filterSettings = { type: "CheckBox" }; // Menu, Excel
const groupSettings = { showDropArea: false, columns: ["ticketStatusId"] };
const commands = [
  {
    type: "Delete",
    buttonOption: { iconCss: "e-icons e-delete", cssClass: "e-flat" },
  },
  {
    type: "Details",
    buttonOption: { iconCss: "e-icons e-MT_List", cssClass: "e-flat" },
  },
  {
    type: "Save",
    buttonOption: { iconCss: "e-icons e-update", cssClass: "e-flat" },
  },
  {
    type: "Cancel",
    buttonOption: { iconCss: "e-icons e-cancel-icon", cssClass: "e-flat" },
  },
];
const toolbarOptions = [
  "Search",
  "Add",
  "ExcelExport",
  "Print",
  {
    text: "Refresh",
    tooltipText: "Refresh",
    prefixIcon: "e-MT_reload",
    id: "forceRefresh",
  },
];

const apiUrlLogin = "authlogin";
const apiUrl = "ticket";
const apiUrlDetail = "ticketdetail";
const apiUrlStatus = "ticketstatus/combo";
const apiUrlCategory = "ticketcategory/byrole";
const _msecPage = 300000; // milliseconds of inteval => 5 mins

//L10n.load({ 'it': EJ2_LOCALE.it });

class TicketPage extends React.Component {
  _isMounted = false;
  grid = null;
  tbcSend = null;
  static contextType = TokenContext;
  constructor(props) {
    super(props);
    this.position = { X: "Right", Y: "Bottom" };
    const { t } = this.props;
    this.state = {
      myData: [],
      seeDetail: false,
      ticketId: 0,
      arrayPriority: [
        { id: 1, name: t("high") },
        { id: 2, name: t("normal") },
        { id: 3, name: t("low") },
      ],
      visibleOnGrid: false,
      didMountCompleted: false,
      isCicass: false,
      isClosed: false,
      showConfirmDialog: false,
      toastInfo: {
        title: "",
        content: "Succesfully",
        cssClass: "e-toast-success",
        icon: "e-success toast-icons",
      },
    };
    this.validationIsRequired = { required: true };
    this.validationSubject = { required: true, maxLength: 100 };
    this.validationMain = { required: true, maxLength: 2000 };
    this.actionComplete = this.actionComplete.bind(this);
    this.actionBegin = this.actionBegin.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.handleSeeDetail = this.handleSeeDetail.bind(this);
    this.refreshInterval = setInterval(() => {
      this.refreshGrid();
    }, Number(_msecPage));
    this.onTextAreaValueChanged = this.onTextAreaValueChanged.bind(this);
    this.confirmButton = [
      {
        click: () => {
          this.saveAndCloseModal();
        },
        buttonModel: { content: t("yes"), isPrimary: true },
      },
      {
        click: () => {
          this.setState({ showConfirmDialog: false });
        },
        buttonModel: { content: t("no") },
      },
    ];
  }

  async saveAndCloseModal() {
    await cicassService.getAll(apiUrl + "/close/" + this.state.ticketId);
    this.refreshGrid();
    this.setState({ seeDetail: false, ticketId: 0, showConfirmDialog: false });
  }

  async componentDidMount() {
    this._isMounted = true;
    const myrole = auth.getUserRole();
    const weblang = localStorage.getItem("weblang");
    let allowPrivateMessage = auth.allowPrivateMessage();
    let { data: myData } = await cicassService.getAll(apiUrl);
    const { data: arrayStatus } = await cicassService.getAll(
      apiUrlStatus + "/" + weblang
    );
    const { data: arrayCategory } = await cicassService.getAll(
      apiUrlCategory + "/" + weblang
    );
    let { arrayPrivateLogin } = this.state;
    if (allowPrivateMessage) {
      const { data: myList } = await cicassService.getAll(apiUrlLogin);
      arrayPrivateLogin = myList.slice();
    }

    let isCicass = myrole == "SuperUser" ? true : false;
    const token = this.context;
    if (this._isMounted) {
      this.setState({
        didMountCompleted: true,
        ownerLogin: auth.getUserId(token.jwt),
        arrayStatus,
        arrayCategory,
        allowPrivateMessage,
        arrayPrivateLogin,
        isCicass,
        myData,
      });
    }
  }

  componentWillUnmount() {
    if (this.refreshInterval) clearInterval(this.refreshInterval);
    this._isMounted = false;
  }

  toolbarClick(args) {
    switch (args.item.properties.id) {
      case "gridcomp_excelexport":
        this.gridInstance.excelExport();
        break;
    }
    if (args.item.id === "forceRefresh") {
      this.refreshGrid();
    }
  }

  refreshGrid() {
    cicassService.getAll(apiUrl).then((response) => {
      this.setState({ myData: response.data });
    });
  }

  actionBegin(state) {
    if (state.requestType == "beginEdit" || state.requestType == "add") {
      if (state.requestType == "add") {
        state.data.id = -1;
        state.data.ticketStatusId = 1; // New
        state.data.anaAgencyId = auth.getAgencyId();
      }
      this.setState({ visibleOnGrid: true });
    }
  }

  actionComplete(state) {
    if (state.requestType === "add" || state.requestType === "beginEdit") {
      state.dialog.enableResize = true;
      //state.dialog.element.style.maxHeight = 900;
      state.dialog.width = 600;
      state.dialog.height = 600;
      this.rowData = Object.assign({}, state.rowData);
    }
    if (state.action === "add") {
      state.data.id = -1;
      state.data.createdAt = new Date();
      if (state.data.privateAuthLoginId == null)
        state.data.privateAuthLoginId = 0;
      cicassService
        .saveOp(apiUrl, state.data)
        .then(() => {
          this.refreshGrid();
          this.setToastInfo("Success", this.props.t("toastSuccess"));
        })
        .catch((err) => {
          this.setToastInfo("Error", "I: " + err.message);
          this.refreshGrid();
        });
    }
    if (state.action === "edit") {
      cicassService
        .saveOp(apiUrl, state.data)
        .then(() => {
          this.refreshGrid();
          this.setToastInfo("Success", this.props.t("toastSuccess"));
        })
        .catch((err) => {
          this.setToastInfo("Error", "U: " + err.message);
          this.refreshGrid();
        });
    }
    if (state.requestType === "delete") {
      cicassService
        .deleteOp(apiUrl, state.data[0].id)
        .then(() => {
          this.refreshGrid();
          this.setToastInfo("Success", this.props.t("toastSuccess"));
        })
        .catch((err) => {
          this.setToastInfo("Error", "D:" + err.message);
          this.refreshGrid();
        });
    }

    this.setState({ visibleOnGrid: false });
  }

  async commandClick(args) {
    if (args.commandColumn.type === "Details") {
      //this.setState({ seeDetail: true , ticketId: args.rowData.id});
      this.handleSeeDetail(args, 1);
    }
    if (args.commandColumn.type === "Manager") {
      //window.location.href = "/app/anacalendarmanager/" + args.rowData.id;
      this.props.history.push(`/app/anacalendarmanager/${args.rowData.id}`);
    }
  }

  async handleSendTicketReply(args) {
    let dataToSave = {
      id: -1,
      message: this.state.valueTextArea,
      ticketId: this.state.ticketId,
    };
    try {
      cicassService
        .saveOp(apiUrlDetail, dataToSave)
        .then((res) => {
          this.tbcSendInstance.respectiveElement.value = "";
          document.getElementById("numbercount").textContent = "0/2000";
          this.setState({ arrayDetails: res.data, valueTextArea: "" });
          //this.setToastInfo("Success","");
        })
        .catch((err) => {
          this.setToastInfo("Error", "SD " + err.message);
        });
    } catch (err) {
      console.error(err);
    }
  }
  async handleCloseTicket() {
    this.setState({ showConfirmDialog: true });
  }

  onTextAreaValueChanged() {
    let valueTextArea = this.tbcSendInstance.respectiveElement.value;
    let addressCount = valueTextArea.length;
    document.getElementById("numbercount").textContent = addressCount + "/2000";
    this.setState({ valueTextArea });
  }

  renderStatusColor = (data) => {
    const { arrayHeader } = this.state;
    let selectedStatus = this.state.arrayStatus.filter(function (el) {
      return el.name == data;
    });
    const divColorStyle = {
      width: "16px",
      height: "16px",
      borderRadius: "8px",
      background: selectedStatus[0].color,
      marginRight: "5px",
    };
    const divContainer = {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
    };
    return (
      <div style={divContainer}>
        <div style={divColorStyle}></div>
        <b>{arrayHeader[5].value}&nbsp;</b>
        &nbsp;|&nbsp;{arrayHeader[2].name}&nbsp;<i>{arrayHeader[2].value}</i>
        &nbsp;|&nbsp;{arrayHeader[1].name}&nbsp;<i>{arrayHeader[1].value}</i>
      </div>
    );
  };

  async handleSeeDetail(e, newRow = 0) {
    let {
      seeDetail,
      arrayDetails,
      arrayHeader,
      firstMessage,
      arrayStatus,
      arrayCategory,
      arrayPriority,
    } = this.state;
    const { t } = this.props;
    if (seeDetail == false) {
      if (this.refreshInterval) clearInterval(this.refreshInterval);
    } else
      this.refreshInterval = setInterval(() => {
        this.refreshGrid();
      }, Number(_msecPage));

    let myRow; // da verificare i casi 0 e 2 (new e filtro per Igor)
    if (e.row || newRow > 0) {
      switch (newRow) {
        case 0:
          myRow = e.row.data;
          break;
        case 1:
          myRow = e.rowData;
          break;
        case 2:
          myRow = e.data;
          break;
      }
      // A) Compose Header
      arrayHeader = [];
      let selectedStatus = arrayStatus.filter(function (el) {
        return el.id == myRow.ticketStatusId;
      });
      let selectedCategory = arrayCategory.filter(function (el) {
        return el.id == myRow.ticketCategoryId;
      });
      let selectedPriority = arrayPriority.filter(function (el) {
        return el.id == myRow.priority;
      });
      let { data: userEmail } = await cicassService.getAll(
        apiUrl + "/getowner/" + myRow.id
      );
      arrayHeader.push({
        name: t("ticketStatusId"),
        value: selectedStatus[0].name,
      });
      arrayHeader.push({
        name: t("priority"),
        value: selectedPriority[0].name,
      });
      arrayHeader.push({
        name: t("ticketCategoryId"),
        value: selectedCategory[0].name,
      });
      arrayHeader.push({ name: t("createdAt"), value: myRow.createdAt });
      arrayHeader.push({ name: t("timeFrom"), value: userEmail });
      arrayHeader.push({ name: t("subject"), value: myRow.subject });
      // B) Get Details
      let { data: arrayDetails } = await cicassService.getAll(
        apiUrlDetail + "/ticket_id/" + myRow.id
      );
      arrayDetails.forEach((element) => {
        element.ticketOwner = userEmail;
      });
      firstMessage = {
        owner: userEmail,
        ticketOwner: userEmail,
        createdAt: myRow.createdAt,
        message: myRow.message,
      };
      this.setState({
        arrayDetails: arrayDetails,
        arrayHeader,
        firstMessage,
        userEmail,
        isClosed: myRow.ticketStatusId == 4 ? true : false,
      });
    }
    this.setState({ seeDetail: !seeDetail, ticketId: myRow ? myRow.id : 0 });
  }

  setToastInfo(title, errorMsg) {
    let { toastInfo } = this.state;
    switch (title) {
      case "Warning":
        toastInfo.title = "Warning";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-warning";
        toastInfo.icon = "e-warning toast-icons";
        break;
      case "Success":
        toastInfo.title = "Success";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-success";
        toastInfo.icon = "e-success toast-icons";
        break;
      case "Error":
        toastInfo.title = "Error";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-danger";
        toastInfo.icon = "e-error toast-icons";
        break;
      case "Information":
        toastInfo.title = "Information";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-info";
        toastInfo.icon = "e-info toast-icons";
        break;
    }
    this.setState({ toastInfo });
    this.toastObj.show();
    return;
  }

  editTemplateMain(args) {
    const { t } = this.props;
    return (
      <div className="multiline-wrapper">
        <TextBoxComponent
          id="message"
          value={getValue("message", args)}
          multiline={true}
          floatLabelType="Auto"
          placeholder={t("enterMessage")}
        ></TextBoxComponent>
      </div>
    );
  }

  templateStatusColor = (data) => {
    let selectedStatus = this.state.arrayStatus.filter(function (el) {
      return el.id == data.ticketStatusId;
    });
    const divColorStyle = {
      width: "14px",
      height: "14px",
      borderRadius: "7px",
      background: selectedStatus[0].color,
      marginRight: "5px",
      marginTop: "2px",
    };
    const divContainer = {
      display: "flex",
      flexDirection: "row",
      flexWrap: "nowrap",
    };
    return (
      <div style={divContainer}>
        <div style={divColorStyle}></div>
        <div>{data.id}</div>
      </div>
    );
  };

  dialogClose() {
    // dovrei chiudere a conferma
    this.setState({ showConfirmDialog: false });
  }

  render() {
    const { t } = this.props;
    const {
      myData,
      didMountCompleted,
      isCicass,
      toastInfo,
      seeDetail,
      arrayPriority,
      arrayCategory,
      arrayStatus,
      arrayPrivateLogin,
      visibleOnGrid,
      ticketId,
      isClosed,
      arrayDetails,
      arrayHeader,
      firstMessage,
      valueTextArea,
      ownerLogin,
    } = this.state;
    return (
      <>
        <div style={{ margin: "1%", marginTop: "1%" }}>
          <h2 className="text-gray-800 text-xl font-semibold">
            {t("titleTicketJs")}
            {seeDetail && t("titleTicketDetailJs") + ticketId}{" "}
          </h2>
        </div>
        <div className="p-4 flex-auto">
          {didMountCompleted && !seeDetail && (
            <GridComponent
              id="gridcomp"
              ref={(grid) => (this.gridInstance = grid)}
              dataSource={myData}
              allowResizing={true}
              toolbar={toolbarOptions}
              editSettings={editSettings}
              allowGrouping={true}
              groupSettings={groupSettings}
              commandClick={this.commandClick.bind(this)}
              allowFiltering={true}
              filterSettings={filterSettings}
              allowPaging={true}
              pageSettings={{ pageSize: 10, pageCount: 5 }}
              allowExcelExport={true}
              actionComplete={this.actionComplete}
              actionBegin={this.actionBegin}
              toolbarClick={this.toolbarClick.bind(this)}
              locale="it"
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="id"
                  headerText={t("id")}
                  textAlign="Right"
                  isPrimaryKey={true}
                  defaultValue="-1"
                  allowEditing={false}
                  visible={isCicass}
                  template={this.templateStatusColor.bind(this)}
                />
                <ColumnDirective
                  field="priority"
                  headerText={t("priority")}
                  foreignKeyValue="name"
                  foreignKeyField="id"
                  dataSource={arrayPriority}
                  validationRules={this.validationIsRequired}
                />
                <ColumnDirective
                  field="subject"
                  headerText={t("subject")}
                  allowEditing={true}
                  validationRules={this.validationSubject}
                />
                <ColumnDirective
                  field="ticketCategoryId"
                  headerText={t("ticketCategoryId")}
                  foreignKeyValue="name"
                  foreignKeyField="id"
                  dataSource={arrayCategory}
                  visible={visibleOnGrid}
                  validationRules={this.validationIsRequired}
                />
                <ColumnDirective
                  field="ticketStatusId"
                  headerText={t("ticketStatusId")}
                  foreignKeyValue="name"
                  foreignKeyField="id"
                  dataSource={arrayStatus}
                  validationRules={this.validationIsRequired}
                  allowEditing={false}
                />
                <ColumnDirective
                  field="privateAuthLoginId"
                  headerText={t("privateAuthLoginId")}
                  foreignKeyValue="name"
                  foreignKeyField="id"
                  dataSource={arrayPrivateLogin}
                  visible={visibleOnGrid}
                />
                <ColumnDirective
                  field="message"
                  headerText={t("message")}
                  editTemplate={this.editTemplateMain.bind(this)}
                  allowEditing={true}
                  visible={visibleOnGrid}
                />
                <ColumnDirective
                  field="createdAt"
                  headerText={t("createdAt")}
                  type="date"
                  format="dd/MM/yyyy HH:mm:ss"
                  allowEditing={false}
                />
                <ColumnDirective
                  headerText={t("operationOnGrid")}
                  width="160"
                  commands={commands}
                ></ColumnDirective>
              </ColumnsDirective>
              <Inject
                services={[
                  Resize,
                  Filter,
                  Page,
                  Toolbar,
                  Edit,
                  ForeignKey,
                  CommandColumn,
                  ExcelExport,
                  Group,
                ]}
              />
            </GridComponent>
          )}
          {didMountCompleted && seeDetail && (
            <React.Fragment>
              <div className="flex" id="target">
                <ButtonComponent
                  cssClass="e-small e-round e-info"
                  iconCss="e-icons e-Bottom-icon"
                  onClick={this.handleSeeDetail}
                ></ButtonComponent>
                {!isClosed && (
                  <div
                    className="buttons"
                    style={{ position: "absolute", right: "10px" }}
                  >
                    <ButtonComponent
                      id="closeBtn"
                      cssClass="e-info e-outline button-margin-15"
                      alignment="right"
                      onClick={this.handleCloseTicket.bind(this)}
                    >
                      {t("closeTicket")}
                    </ButtonComponent>
                    <DialogComponent
                      id="confirmDialog"
                      isModal={true}
                      header={t("closeTicket")}
                      visible={this.state.showConfirmDialog}
                      showCloseIcon={true}
                      width="400px"
                      height="350px"
                      content={t("confirmQuestion")}
                      ref={(dialog) => (this.confirmDialogInstance = dialog)}
                      close={this.dialogClose.bind(this)}
                      target="#target"
                      buttons={this.confirmButton}
                    ></DialogComponent>
                  </div>
                )}
              </div>
              {arrayDetails && (
                <div className="list-container">
                  <div className="ticket">
                    <section className="msger">
                      {TicketHeader(arrayHeader, this.renderStatusColor)}
                      <main className="msger-chat">
                        {ChatDetail(firstMessage, 0, ownerLogin)}
                        {arrayDetails.map((item, i) => {
                          return ChatDetail(item, i, ownerLogin);
                        })}
                      </main>
                      {!isClosed && (
                        <div className="msger-inputarea">
                          <div className="multiline-wrapper width90">
                            <TextBoxComponent
                              ref={(tbcSend) =>
                                (this.tbcSendInstance = tbcSend)
                              }
                              multiline={true}
                              floatLabelType="Auto"
                              placeholder={t("enterMessage")}
                              input={this.onTextAreaValueChanged}
                            ></TextBoxComponent>
                            <span id="numbercount" />
                          </div>
                          <div className="buttons">
                            <ButtonComponent
                              cssClass="e-info e-outline button-margin-15 bottomBtn"
                              onClick={this.handleSendTicketReply.bind(this)}
                            >
                              {t("sendMessage")}
                            </ButtonComponent>
                          </div>
                        </div>
                      )}
                    </section>
                  </div>
                </div>
              )}
            </React.Fragment>
          )}
          <ToastComponent
            ref={(toast) => {
              this.toastObj = toast;
            }}
            id="toast_default"
            position={this.position}
            title={toastInfo.title}
            content={toastInfo.content}
            cssClass={toastInfo.cssClass}
            icon={toastInfo.icon}
          ></ToastComponent>
        </div>
      </>
    );
  }
}
export default withTranslation()(TicketPage);
