import React from "react";
import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Page,
  Inject,
  Resize,
  Edit,
  CommandColumn,
  ForeignKey,
  Filter,
  Toolbar,
  ExcelExport,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import { auth, cicassService } from "../../../_services";
import { getValue, Internationalization } from "@syncfusion/ej2-base";
import { DateTimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { withTranslation } from "react-i18next";
import TokenContext from "components/TokenContext";
import { DateTime } from "luxon";
import { Box, TextField, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import itLocale from "date-fns/locale/it";
import AttendanceReportGrid from "./AttendanceReportGrid";
import {
  ButtonComponent,
  SwitchComponent,
} from "@syncfusion/ej2-react-buttons";

const editSettings = {
  allowEditing: true,
  allowAdding: true,
  allowDeleting: true,
  mode: "Dialog",
  dialog: { enableResize: true },
  showDeleteConfirmDialog: true,
};
const editUserSetting = {
  allowEditing: false,
  allowAdding: false,
  allowDeleting: false,
  mode: "Dialog",
  dialog: { enableResize: true },
  showDeleteConfirmDialog: true,
};
const filterSettings = { type: "CheckBox" }; // Menu, Excel

const toolbarUserOptions = [
  // "Search",
  "ExcelExport",
  "Print",
  {
    text: "Refresh",
    tooltipText: "Refresh",
    prefixIcon: "e-MT_reload",
    id: "forceRefresh",
  },
];
const commands = [
  {
    type: "Edit",
    buttonOption: { iconCss: " e-icons e-edit", cssClass: "e-flat" },
  },
  {
    type: "Delete",
    buttonOption: { iconCss: "e-icons e-delete", cssClass: "e-flat" },
  },
  {
    type: "Save",
    buttonOption: { iconCss: "e-icons e-update", cssClass: "e-flat" },
  },
  {
    type: "Cancel",
    buttonOption: { iconCss: "e-icons e-cancel-icon", cssClass: "e-flat" },
  },
];

const apiUrl = "attendance";
const apiUrlUserCombo = "authlogin";
const apiEndpointGetAttendanceType = "ana_attendance_type";
const apiEndpointAttendanceMonthly = "attendance/month";

const currentYear = new Date().getFullYear();
const maxDate = new Date(currentYear + 1 + "-12-31");
const minDate = new Date(2022, 0, 1);

class Attendance extends React.Component {
  _isMounted = false;
  grid = null;
  static contextType = TokenContext;
  constructor(props) {
    super(props);
    this.position = { X: "Right", Y: "Bottom" };
    this.state = {
      myData: [],
      myDataCopy: [],
      visibleOnGrid: false,
      didMountCompleted: false,
      isCicass: false,
      editEnabled: false,
      userData: [],
      toastInfo: {
        title: "",
        content: "Succesfully",
        cssClass: "e-toast-success",
        icon: "e-success toast-icons",
      },
      isAttendanceReportGridVisible: false,
      datePickerValue: new Date(),
      selectedYear: "",
      isCalculationDone: false,
      datePickerViews: ["year", "month"],
      isExpiredFilterSelected: false,
      isInvalidFilterSelected: false,
      isUncompletedFilterSelected: false,
      totalTime: "",
    };
    this.validationUser = {
      required: [this.notBlank.bind(this), "Questo campo è obbligatorio"],
    };

    this.validationAttendanceCause = {
      required: [
        this.notNullOrUndefined.bind(this),
        "Questo campo è obbligatorio",
      ],
    };
    this.validationFrom = {
      required: true,
      min: [
        this.lsTo.bind(this),
        '"Data Da" deve essere inferiore di "Data A"',
      ],
    };
    this.validationTo = {
      required: true,
      min: [
        this.gtFrom.bind(this),
        '"Data A" deve essere maggiore o uguale a "Data Da"',
      ],
    };
    this.actionComplete = this.actionComplete.bind(this);
    this.actionBegin = this.actionBegin.bind(this);
    this.onMonthChange = this.onMonthChange.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.filterAttendance = this.filterAttendance.bind(this);
    this.isValidDate = this.isValidDate.bind(this);
  }

  toolbarOptions = [
    // "Search",
    "Add",
    "ExcelExport",
    "Print",
    {
      text: "Refresh",
      tooltipText: "Refresh",
      prefixIcon: "e-MT_reload",
      id: "forceRefresh",
    },
    {
      text: "- " + this.props.t("filterExpiredAttendance"),
      tooltipText: this.props.t("filterExpiredAttendance"),
      id: "filterExpired",
      align: "center",
    },
    {
      text: "- " + this.props.t("filterInvalidAttendance"),
      tooltipText: this.props.t("filterInvalidAttendance"),
      id: "filterInvalid",
      align: "center",
    },
    {
      text: "- " + this.props.t("filterUncompletedAttendance"),
      tooltipText: this.props.t("filterUncompletedAttendance"),
      id: "filterUncompleted",
      align: "center",
    },
  ];

  notBlank(args) {
    return args.value !== "1"; // blank user
  }

  notNullOrUndefined(args) {
    return !!args.value !== false;
  }

  lsTo(args) {
    let valueRead = getValue("value", args); // from
    let fromDate =
      valueRead.substring(6, 10) +
      "-" +
      valueRead.substring(3, 5) +
      "-" +
      valueRead.substring(0, 2) +
      valueRead.substring(11, 16);
    let valueReadTo = document.getElementById("toDt").value;
    let toDate =
      valueReadTo.substring(6, 10) +
      "-" +
      valueReadTo.substring(3, 5) +
      "-" +
      valueReadTo.substring(0, 2) +
      valueReadTo.substring(11, 16);
    return toDate == "--" ? fromDate : fromDate <= toDate;
  }

  gtFrom(args) {
    let valueRead = getValue("value", args); // to
    // console.log(valueRead);
    let toDate =
      valueRead.substring(6, 10) +
      "-" +
      valueRead.substring(3, 5) +
      "-" +
      valueRead.substring(0, 2) +
      valueRead.substring(11, 16);
    let valueReadFrom = document.getElementById("fromDt").value;
    let fromDate =
      valueReadFrom.substring(6, 10) +
      "-" +
      valueReadFrom.substring(3, 5) +
      "-" +
      valueReadFrom.substring(0, 2) +
      valueReadFrom.substring(11, 16);
    return fromDate == "--" ? toDate : toDate >= fromDate;
  }

  async componentDidMount() {
    this._isMounted = true;
    const myrole = auth.getUserRole();
    // const weblang = localStorage.getItem("weblang");
    await this.refreshGrid();

    let { data: userData } = await cicassService.getAll(apiUrlUserCombo);
    // devo fare lo stesso check di calendar.js
    let { data: iAmManagerOfAttendance } = await cicassService.getAll(
      apiUrl + "/ismanager"
    );
    let editEnabled =
      myrole === "Admin" ||
      myrole === "SuperUser" ||
      (myrole === "Manager" && iAmManagerOfAttendance);

    let isCicass = myrole === "SuperUser";
    if (this._isMounted) {
      const attendanceCauseOptions = await cicassService.getAll(
        apiEndpointGetAttendanceType
      );

      this.setState({
        didMountCompleted: true,
        userData,
        isCicass,
        editEnabled,
        attendanceCauseOptions: attendanceCauseOptions.data,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onMonthChange(newValue) {
    this.refreshGrid(newValue.getFullYear(), newValue.getMonth() + 1, true);
    this.gridInstance.clearFiltering();
  }

  filterAttendance(type) {
    const attendanceData = this.state.myDataCopy;
    const filteredAttendance = attendanceData.filter((item) => {
      const toDt = DateTime.fromJSDate(item.toDt);
      const fromDt = DateTime.fromJSDate(item.fromDt);
      const { hours, minutes } = toDt
        .diff(fromDt, ["hours", "minutes"])
        .toObject();

      if (type === "invalid") {
        return minutes <= 5 && hours === 0;
      }
      if (type === "expired") {
        return hours >= 12;
      }
      if (type === "uncompleted") {
        return item.toDt === null || !this.isValidDate(item.toDt);
      }
      return null;
    });

    let totalTime = 0;
    filteredAttendance.map((item) => {
      const toDt = Math.floor(new Date(item.toDt).getTime() / 1000)
      const fromDt = Math.floor(new Date(item.fromDt).getTime() / 1000)
      if (!toDt.invalid)
        totalTime = totalTime + (toDt - fromDt);
    })
    const totalTimeAsDate = new Date(totalTime * 1000)
    if (totalTime)
      totalTime = Math.floor(totalTime / 60 / 60) + " " + this.props.t("h") + " " + totalTimeAsDate.getMinutes() + " " + this.props.t("m") + " " + totalTimeAsDate.getSeconds() + " " + this.props.t("s");
    else
      totalTime = "---"

    this.setState({ myData: filteredAttendance, totalTime: totalTime });
  }

  isValidDate(date) {
    return date instanceof Date && !isNaN(date);
  }

  toolbarClick(args) {
    const gridId = this.gridInstance.htmlattributes.id;
    switch (args.item.properties.id) {
      case `${gridId}_excelexport`:
        this.gridInstance.excelExport();
        break;
      case "filterExpired":
        this.filterAttendance("expired");
        this.setState({ isExpiredFilterSelected: true });
        break;
      case "filterInvalid":
        this.filterAttendance("invalid");
        this.setState({ isInvalidFilterSelected: true });
        break;
      case "filterUncompleted":
        this.filterAttendance("uncompleted");
        this.setState({ isUncompletedFilterSelected: true });
        break;
      default:
        break;
    }
    if (args.item.id === "forceRefresh") {
      this.refreshGrid(null, null, true);
      this.gridInstance.clearFiltering();
    }
  }

  async getYearDetails() {
    const year = this.state.datePickerValue.getFullYear();
    await cicassService.downloadCsvFile(
      apiUrl + `/report/download/${year}`,
      "presenze"
    );
  }
  async refreshGrid(newYear, newMonth, isToolbarRefreshClicked) {
    try {
      //this.gridInstance.clearFiltering();
      if (this.state.isExpiredFilterSelected && !isToolbarRefreshClicked) {
        this.filterAttendance("expired");
        return;
      }
      if (this.state.isInvalidFilterSelected && !isToolbarRefreshClicked) {
        this.filterAttendance("invalid");
        return;
      }
      if (this.state.isUncompletedFilterSelected && !isToolbarRefreshClicked) {
        this.filterAttendance("uncompleted");
        return;
      }
      const year = newYear ?? this.state.datePickerValue.getFullYear();
      const month = newMonth ?? this.state.datePickerValue.getMonth() + 1;
      const { data } = await cicassService.getAll(
        apiEndpointAttendanceMonthly + `/${year}/${month}`
      );

      let totalTime = 0;

      const myData = data.map((item) => {
        const toDt = DateTime.fromISO(item.toDt);
        const fromDt = DateTime.fromISO(item.fromDt);

        let builtTimeSpent;
        if (!toDt.invalid) {
          const timeDiff = toDt.ts - fromDt.ts;
          builtTimeSpent = new Date(timeDiff).toLocaleTimeString([], { timeZone: "utc" })
          totalTime = totalTime + timeDiff / 1000;
        }

        return {
          ...item,
          fromDt: fromDt.toJSDate(),
          toDt: toDt.toJSDate(),
          timeSpent: builtTimeSpent,
          // toDateString: toDt.toISO(),
        };
      });

      const totalTimeAsDate = new Date(totalTime * 1000)
      if (totalTime)
        totalTime = Math.floor(totalTime / 60 / 60) + " " + this.props.t("h") + " " + totalTimeAsDate.getMinutes() + " " + this.props.t("m") + " " + totalTimeAsDate.getSeconds() + " " + this.props.t("s");
      else
        totalTime = "---"
      this.setState({ myData, myDataCopy: myData, totalTime: totalTime });
    } catch (error) {
      console.error("Error in refreshGrid:", error);
    }
  }

  actionBegin(state) {
    if (state.requestType === "beginEdit" || state.requestType === "add") {
      if (state.requestType === "add") {
        state.id = -1;
        state.data.anaAgencyId = auth.getAgencyId();
        const token = this.context;
        state.data.anaUserId = auth.getUserId(token.jwt);
      }
      this.setState({ visibleOnGrid: true });
    }
  }

  timeSpentTemplate(args) {
    let styles = {};
    const jsxElement = <p style={styles}>{args.timeSpent}</p>;

    const toDt = DateTime.fromJSDate(args.toDt);
    const fromDt = DateTime.fromJSDate(args.fromDt);
    const { hours, minutes } = toDt
      .diff(fromDt, ["hours", "minutes"])
      .toObject();

    // If less than 5 minutes have passed
    if (minutes <= 5 && hours === 0) {
      styles.color = "orange";
      // If 13 or more hours have passed
    } else if (hours >= 12) {
      styles.color = "red";
    }

    return jsxElement;
  }

  getUsernameById(id) {
    return this.state.userData.find((user) => user.id === id).name;
  }

  formatDateToISO(dateTime) {
    const format = "yyyy-MM-dd'T'HH:mm:ss";
    return dateTime.toFormat(format);
  }

  actionComplete(state) {
    if (state.action === "filter" || state.action === "clear-filter") {
      const filteredAttendance = this.gridInstance.getFilteredRecords();
      let totalTime = 0;
      filteredAttendance.map((item) => {
        const toDt = Math.floor(new Date(item.toDt).getTime() / 1000)
        const fromDt = Math.floor(new Date(item.fromDt).getTime() / 1000)
        if (!toDt.invalid && !isNaN(toDt))
          totalTime = totalTime + (toDt - fromDt);
      })
      const totalTimeAsDate = new Date(totalTime * 1000)
      if (totalTime)
        totalTime = Math.floor(totalTime / 60 / 60) + " " + this.props.t("h") + " " + totalTimeAsDate.getMinutes() + " " + this.props.t("m") + " " + totalTimeAsDate.getSeconds() + " " + this.props.t("s");
      else
        totalTime = "---"
      this.setState({ totalTime: totalTime });
    }
    if (state.requestType === "add" || state.requestType === "beginEdit") {
      state.dialog.enableResize = true;
      //state.dialog.element.style.maxHeight = 900;
      state.dialog.width = 600;
      state.dialog.height = 600;
      if (state.requestType === "beginEdit") {
        const name = this.getUsernameById(state.rowData.anaUserId);
        state.dialog.header = `Dettagli di ${name}`;
      }
    }
    if (state.action === "add") {
      state.data.id = -1;
      state.data.createdAt = new Date();
      delete state.data.anaAgencyId;
      delete state.data.userRoleList;
      const data = {
        ...state.data,
        fromDt: this.formatDateToISO(DateTime.fromJSDate(state.data.fromDt)),
        toDt: this.formatDateToISO(DateTime.fromJSDate(state.data.toDt)),
      };
      cicassService
        .saveOp(apiUrl, data)
        .then(() => {
          this.setToastInfo("Success", this.props.t("toastSuccess"));
        })
        .catch((err) => {
          console.error("Error on add:", err);
          this.setToastInfo("Error", "I: " + err.message);
        })
        .finally(() => {
          this.refreshGrid();
        });
    }
    if (state.action === "edit") {
      delete state.data.anaAgencyId;
      delete state.data.userRoleList;
      let data = {
        ...state.data,
        fromDt: this.formatDateToISO(DateTime.fromJSDate(state.data.fromDt)),
        toDt: this.formatDateToISO(DateTime.fromJSDate(state.data.toDt)),
      };

      cicassService
        .saveOp(apiUrl, data)
        .then(() => {
          this.setToastInfo("Success", this.props.t("toastSuccess"));
        })
        .catch((err) => {
          console.error("Error on edit:", err);
          this.setToastInfo("Error", "U: " + err.message);
        })
        .finally(() => {
          this.refreshGrid();
        });
    }
    if (state.requestType === "delete") {
      cicassService
        .deleteOp(apiUrl, state.data[0].id)
        .then(() => {
          this.setToastInfo("Success", this.props.t("toastSuccess"));
        })
        .catch((err) => {
          console.error("Error on delete:", err);
          this.setToastInfo("Error", "D:" + err.message);
        })
        .finally(() => {
          this.refreshGrid();
        });
    }

    this.setState({ visibleOnGrid: false });
  }

  setToastInfo(title, msg) {
    let toastInfo = {};
    switch (title) {
      case "Warning":
        toastInfo.title = "Warning";
        toastInfo.content = msg;
        toastInfo.cssClass = "e-toast-warning";
        toastInfo.icon = "e-warning toast-icons";
        break;
      case "Success":
        toastInfo.title = "Success";
        toastInfo.content = msg;
        toastInfo.cssClass = "e-toast-success";
        toastInfo.icon = "e-success toast-icons";
        break;
      case "Error":
        toastInfo.title = "Error";
        toastInfo.content = msg;
        toastInfo.cssClass = "e-toast-danger";
        toastInfo.icon = "e-error toast-icons";
        break;
      case "Information":
        toastInfo.title = "Information";
        toastInfo.content = msg;
        toastInfo.cssClass = "e-toast-info";
        toastInfo.icon = "e-info toast-icons";
        break;
      default:
        break;
    }
    this.setState({ toastInfo });
    this.toastObj.show();
  }

  exitDateTemplate(args) {
    try {
      const parsedDate = DateTime.fromJSDate(args.toDt).toISO() ?? "";
      return parsedDate;
    } catch (err) {
      return "";
    }
  }

  editTemplateFrom(args) {
    return (
      <DateTimePickerComponent
        value={getValue("fromDt", args)}
        id="fromDt"
        placeholder="From"
        floatLabelType="Always"
        format={"dd/MM/yyyy HH:mm"}
        locale="it"
      />
    );
  }

  editTemplateTo(args) {
    return (
      <DateTimePickerComponent
        value={getValue("toDt", args)}
        id="toDt"
        placeholder="To"
        floatLabelType="Always"
        format={"dd/MM/yyyy HH:mm"}
        locale="it"
      />
    );
  }

  excelQueryCellInfo(args) {
    if (args.column.field === "toDt" || args.column.field === "fromDt") {
      const intl = new Internationalization();
      const dFormatter = intl.getDateFormat({ format: "dd/MM/yyyy HH:mm:ss" });
      const formattedDate = dFormatter(args.value);
      args.value = formattedDate;
    }
  }

  toggleYearlyAttendanceReport() {
    this.setState({
      isAttendanceReportGridVisible: !this.state.isAttendanceReportGridVisible,
      datePickerViews: !this.state.isAttendanceReportGridVisible
        ? ["year"]
        : ["year", "month"],
    });
  }

  render() {
    const {
      myData,
      didMountCompleted,
      userData,
      isCicass,
      toastInfo,
      editEnabled,
      attendanceCauseOptions,
      isAttendanceReportGridVisible,
      totalTime
    } = this.state;
    const { t } = this.props;

    return (
      <main>
        <div style={{ margin: "1.2rem 0 .3rem 1.2rem" }}>
          <Box
            sx={{
              display: "flex",
              mr: 2,
              mt: 2,
              alignItems: "center",
            }}
          >
            <h2 className="text-gray-800 text-xl font-semibold">
              {t("titleAttendanceJs")}
            </h2>
            <Box
              sx={{
                ml: "auto",
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={itLocale}
                sx={{
                  ml: "auto",
                }}
              >
                <DatePicker
                  views={this.state.datePickerViews}
                  minDate={minDate}
                  maxDate={maxDate}
                  value={this.state.datePickerValue}
                  onChange={(newVal) =>
                    this.setState({ datePickerValue: newVal })
                  }
                  onMonthChange={this.onMonthChange}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Box>

          <Box
            component="aside"
            sx={{
              display: "flex",
              alignItems: "center",
              gap: ".5rem",
            }}
          >
            <Typography component="h3" color="black">
              {t("showAttendanceReport")}
            </Typography>
            <SwitchComponent
              checked={this.state.isAttendanceReportGridVisible}
              onChange={() => this.toggleYearlyAttendanceReport()}
            />
            {!isAttendanceReportGridVisible && (
              <ButtonComponent
                style={{ marginLeft: 16 }}
                cssClass="e-info e-outline e-small e-round button-margin-5"
                iconCss="fas fa-download"
                title={t("allYear")}
                onClick={() => {
                  this.getYearDetails();
                }}
              />
            )}
            {((!isAttendanceReportGridVisible) && totalTime )
              ? <>
                <Typography component="h3" color="black">
                  {t("totalTimeSpent")}: {totalTime}
                </Typography>
              </>
              : null}
          </Box>
        </div>
        <div className="p-4 flex-auto">
          {didMountCompleted && !isAttendanceReportGridVisible && (
            <GridComponent
              id="attendanceGrid"
              ref={(grid) => (this.gridInstance = grid)}
              dataSource={myData}
              allowResizing={true}
              toolbar={editEnabled ? this.toolbarOptions : toolbarUserOptions}
              editSettings={editEnabled ? editSettings : editUserSetting}
              allowFiltering={true}
              filterSettings={filterSettings}
              allowPaging={true}
              pageSettings={{ pageSize: 10, pageCount: 5 }}
              allowExcelExport={true}
              actionComplete={this.actionComplete}
              actionBegin={this.actionBegin}
              toolbarClick={this.toolbarClick.bind(this)}
              excelQueryCellInfo={this.excelQueryCellInfo}
              locale="it"
              allowSorting={true}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="id"
                  headerText={t("id")}
                  textAlign="Right"
                  isPrimaryKey={true}
                  defaultValue="-1"
                  allowEditing={false}
                  visible={isCicass}
                  width="100"
                />
                <ColumnDirective
                  field="anaUserId"
                  headerText={t("anaUserId")}
                  foreignKeyValue="name"
                  foreignKeyField="id"
                  dataSource={userData}
                  visible={editEnabled}
                  width="130"
                  validationRules={this.validationUser}
                />
                <ColumnDirective
                  field="anaAttendanceTypeId"
                  headerText={t("anaAttendanceTypeId")}
                  dataSource={attendanceCauseOptions}
                  foreignKeyValue="name"
                  foreignKeyField="id"
                  visible={editEnabled}
                  width="150"
                  validationRules={this.validationAttendanceCause}
                />
                <ColumnDirective
                  field="attendanceNote"
                  headerText={t("attendanceNote")}
                  width="150"
                />
                <ColumnDirective
                  field="fromDt"
                  headerText={t("fromDt")}
                  type="datetime"
                  format="dd/MM/yyyy HH:mm:ss"
                  allowEditing={editEnabled}
                  editTemplate={this.editTemplateFrom.bind(this)}
                  validationRules={this.validationFrom}
                  width="140"
                />
                <ColumnDirective
                  field="toDt"
                  // field="toDateString"
                  allowFiltering={false}
                  headerText={t("toDt")}
                  type="datetime"
                  format="dd/MM/yyyy HH:mm:ss"
                  allowEditing={editEnabled}
                  editTemplate={this.editTemplateTo.bind(this)}
                  // template={this.exitDateTemplate}
                  validationRules={this.validationTo}
                  width="140"
                />
                <ColumnDirective
                  field="timeSpent"
                  headerText={t("timeSpent") +" (hh:mm:ss)"}
                  allowEditing={false}
                  allowFiltering={false}
                  template={this.timeSpentTemplate}
                  width="140"
                  visible={true}
                />
                <ColumnDirective
                  field="anaAgencyId"
                  headerText={t("anaAgencyId")}
                  allowEditing={false}
                  visible={isCicass}
                  width="150"
                />
                <ColumnDirective
                  field="createdAt"
                  headerText={t("createdAt")}
                  visible={isCicass}
                  allowEditing={false}
                  type="dateTime"
                  format="dd/MM/yyyy HH:mm:ss"
                />
                <ColumnDirective
                  field="updatedAt"
                  headerText={t("updatedAt")}
                  visible={isCicass}
                  allowEditing={false}
                  type="dateTime"
                  format="dd/MM/yyyy HH:mm:ss"
                />
                <ColumnDirective
                  headerText={t("operationOnGrid")}
                  width="100"
                  textAlign="left"
                  commands={commands}
                />
              </ColumnsDirective>
              <Inject
                services={[
                  Resize,
                  Filter,
                  Page,
                  Toolbar,
                  Edit,
                  ForeignKey,
                  CommandColumn,
                  ExcelExport,
                  Sort,
                ]}
              />
            </GridComponent>
          )}
          {this.state.isAttendanceReportGridVisible && (
            <AttendanceReportGrid
              isVisible={this.state.isAttendanceReportGridVisible}
              handleHide={() =>
                this.setState({ isAttendanceReportGridVisible: false })
              }
              selectedYear={this.state.datePickerValue.getFullYear()}
            />
          )}
        </div>
        <ToastComponent
          ref={(toast) => {
            this.toastObj = toast;
          }}
          id="toast_default"
          position={this.position}
          title={toastInfo.title}
          content={toastInfo.content}
          cssClass={toastInfo.cssClass}
          icon={toastInfo.icon}
        />
      </main>
    );
  }
}
export default withTranslation()(Attendance);
