import { uniqueID } from "@syncfusion/ej2-base";
import React from "react";
import { Route, Redirect } from "react-router-dom";
import { auth } from "../../_services/authService";

export const PrivateRoute = ({
    path,
    component: Component,
    //layout: Layout,
    render,
    ...rest
}) => {
    const user = auth.AuthGetCurrentUser();
    return (
        <Route
            {...rest}

            render={props => {
                //console.log("render: ", props);
                if (!user) { return (<Redirect to={{ pathname: "/", state: { from: props.location } }} />); }
                if (rest.policy && !auth.checkRole(rest.policy, user)) { // !auth.checkPage(rest.location.pathname, user), 
                    return (<Redirect to={{ pathname: "/", state: { from: props.location } }} />);
                }
                return Component ? <Component {...props} userLogged={user} key={uniqueID()} /> : render(props);
            }}
        />
    );
};
