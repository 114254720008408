import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Page,
  Inject,
  Resize,
  Edit,
  CommandColumn,
  ForeignKey,
  Filter,
  Toolbar,
  ExcelExport,
  getStateEventArgument,
} from "@syncfusion/ej2-react-grids";
import { L10n, getValue } from "@syncfusion/ej2-base";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
//import * as EJ2_LOCALE from '../../../../node_modules/@syncfusion/ej2-locale/src/it.json'
import { auth, cicassService } from "../../../_services";
import React from "react";
import { withTranslation } from "react-i18next";

const editSettings = {
  allowEditing: false,
  allowAdding: false,
  allowDeleting: true,
  mode: "Dialog",
  dialog: { enableResize: true },
  showDeleteConfirmDialog: true,
};
const filterSettings = { type: "CheckBox" }; // Menu, Excel
const toolbarOptions = ["Search", "ExcelExport", "Print"];
const apiUrl = "ticketcategory/combo";

//L10n.load({ 'it': EJ2_LOCALE.it });

class TicketCategory extends React.Component {
  _isMounted = false;
  grid = null;

  constructor(props) {
    super(props);
    this.position = { X: "Right", Y: "Bottom" };
    this.state = {
      myData: [],
      visibleOnGrid: false,
      didMountCompleted: false,
      isCicass: false,
      enableData: cicassService.getYesNo(),
      toastInfo: {
        title: "",
        content: "Succesfully",
        cssClass: "e-toast-success",
        icon: "e-success toast-icons",
      },
    };
    this.validationRules = { required: true };
    this.refreshGrid = this.refreshGrid.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    const myRole = auth.getUserRole();
    const weblang = localStorage.getItem("weblang");
    let { data: myData } = await cicassService.getAll(apiUrl + "/" + weblang);
    if (this._isMounted) {
      this.setState({
        didMountCompleted: true,
        myData,
        isCicass: myRole == "SuperUser" ? true : false,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toolbarClick(args) {
    switch (args.item.properties.id) {
      case "gridcomp_excelexport":
        this.gridInstance.excelExport();
        break;
    }
    if (args.item.id === "forceRefresh") {
      this.refreshGrid();
    }
  }

  refreshGrid() {
    cicassService.getAll(apiUrl).then((response) => {
      this.setState({ myData: response.data });
    });
  }

  setToastInfo(title, errorMsg) {
    let { toastInfo } = this.state;
    switch (title) {
      case "Warning":
        toastInfo.title = "Warning";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-warning";
        toastInfo.icon = "e-warning toast-icons";
        break;
      case "Success":
        toastInfo.title = "Success";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-success";
        toastInfo.icon = "e-success toast-icons";
        break;
      case "Error":
        toastInfo.title = "Error";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-danger";
        toastInfo.icon = "e-error toast-icons";
        break;
      case "Information":
        toastInfo.title = "Information";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-info";
        toastInfo.icon = "e-info toast-icons";
        break;
    }
    this.setState({ toastInfo });
    this.toastObj.show();
    return;
  }

  editTemplateSc(args) {
    let isChecked = getValue("visible", args);
    const { t } = this.props;
    return (
      <div>
        <label htmlFor="visible" style={{ padding: "10px 72px 10px 0" }}>
          {t("visible")}
        </label>
        <SwitchComponent
          id="visible"
          checked={isChecked}
          change={this.changeFnSc.bind(this)}
        />
      </div>
    );
  }

  changeFnSc(e) {
    if (this.rowData) {
      this.rowData["visible"] = e.checked;
    }
  }

  templateSc(args) {
    let isChecked = getValue("visible", args) == 1 ? true : false;
    if (isChecked) return <i className="far fa-check-square"></i>;
    return <i className="far fa-square"></i>;
  }

  render() {
    const { myData, didMountCompleted, isCicass, toastInfo } = this.state;
    const { t } = this.props;
    return (
      <>
        <div style={{ margin: "1%", marginTop: "1%" }}>
          <h2 className="text-gray-800 text-xl font-semibold">
            {t("titleTicketCategoryJs")}
          </h2>
        </div>
        <div className="p-4 flex-auto">
          {didMountCompleted && (
            <GridComponent
              id="gridcomp"
              ref={(grid) => (this.gridInstance = grid)}
              dataSource={myData}
              toolbar={toolbarOptions}
              editSettings={editSettings}
              allowResizing={true}
              allowFiltering={true}
              filterSettings={filterSettings}
              allowPaging={true}
              pageSettings={{ pageSize: 10, pageCount: 5 }}
              allowExcelExport={true}
              toolbarClick={this.toolbarClick.bind(this)}
              locale="it"
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="id"
                  headerText={t("id")}
                  textAlign="Right"
                  isPrimaryKey={true}
                  defaultValue="-1"
                  visible={isCicass}
                />
                <ColumnDirective
                  field="name"
                  headerText={t("name")}
                  width="150"
                />
                <ColumnDirective
                  field="description"
                  headerText={t("description")}
                />
                <ColumnDirective
                  field="viewOrder"
                  headerText={t("viewOrder")}
                />
                <ColumnDirective
                  field="visible"
                  headerText={t("visible")}
                  editType="numericedit"
                  editTemplate={this.editTemplateSc.bind(this)}
                  template={this.templateSc.bind(this)}
                />
                <ColumnDirective
                  field="createdAt"
                  headerText={t("createdAt")}
                  type="date"
                  format={"dd/MM/yyyy HH:mm:ss"}
                  visible={isCicass}
                />
                <ColumnDirective
                  field="updatedAt"
                  headerText={t("updatedAt")}
                  type="date"
                  format={"dd/MM/yyyy HH:mm:ss"}
                  visible={isCicass}
                />
              </ColumnsDirective>
              <Inject
                services={[
                  Resize,
                  Filter,
                  Page,
                  Toolbar,
                  Edit,
                  ForeignKey,
                  CommandColumn,
                  ExcelExport,
                ]}
              />
            </GridComponent>
          )}
          <ToastComponent
            ref={(toast) => {
              this.toastObj = toast;
            }}
            id="toast_default"
            position={this.position}
            title={toastInfo.title}
            content={toastInfo.content}
            cssClass={toastInfo.cssClass}
            icon={toastInfo.icon}
          ></ToastComponent>
        </div>
      </>
    );
  }
}
export default withTranslation()(TicketCategory);
