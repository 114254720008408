import { Dialog, DialogContent, DialogTitle, Paper } from "@mui/material";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import React from "react";
import Draggable from "react-draggable";

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const ChangeShiftRequestConfirmDialog = props => {
  const {
    dataSource,
    isOpen,
    isEditEnabled,
    handleDropdownChange,
    handleConfirmation,
    handleClose,
    translate,
  } = props;
  const fields = { value: "anaUserId", text: "nameSurname" };

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title">
      <DialogTitle
        sx={{
          cursor: "move",
        }}>
        {translate("peopleAvailableForShiftRequest")}
      </DialogTitle>
      <DialogContent>
        <ComboBoxComponent
          id={"reqShiftChange"}
          dataSource={dataSource}
          fields={fields}
          placeholder="..."
          autofill={true}
          readOnly={isEditEnabled}
          allowFiltering={true}
          change={event => handleDropdownChange(event)}
        />
        <ButtonComponent
          style={{ marginLeft: "1rem" }}
          cssClass="e-control e-btn e-lib e-primary e-flat"
          onClick={handleConfirmation}>
          {translate("okBtn")}
        </ButtonComponent>
        <ButtonComponent
          style={{ marginLeft: "1rem" }}
          cssClass="e-control e-btn e-lib e-flat"
          onClick={handleClose}>
          {translate("cancelBtn")}
        </ButtonComponent>
      </DialogContent>
    </Dialog>
  );
};

export default ChangeShiftRequestConfirmDialog;
