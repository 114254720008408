import React, { useState } from "react";

/*const TokenContext = React.createContext();
export default TokenContext;
*/
const TokenContext = React.createContext({
  token: null,
  setToken: () => {},
});

export default TokenContext;

export const TokenContextProvider = props => {
  const [jwt, setJwt] = React.useState(null);

  const assignJwt = pValue => setJwt(pValue);
  return (
    <TokenContext.Provider value={{ jwt, assignJwt }}>
      {props.children}
    </TokenContext.Provider>
  );
};
