import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { TextField } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import itLocale from "date-fns/locale/it";
import { cicassService } from "../../../_services";
import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Inject,
  Resize,
  Sort,
  Filter,
  Toolbar,
  VirtualScroll,
  ExcelExport,
} from "@syncfusion/ej2-react-grids";
import { Box } from "@mui/system";

const ShiftHistory = (props) => {
  const apiEndpointShiftHistory = "shift/allrequest/1";
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getUTCMonth() + 1;
  const maxDate = new Date(currentYear + "-12-31");
  const { t } = useTranslation();
  const shiftHistoryGridRef = useRef(null);

  const [shiftHistory, setShiftHistory] = useState(null);
  const [datePickerValue, setDatePickerValue] = useState(currentDate);

  const filterSettings = { type: "CheckBox" }; // Menu, Excel, Checkbox
  const toolbarOptions = ["Search", "ExcelExport", "Print"];

  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    refreshGrid();
    return;
  }

  async function refreshGrid(year = currentYear, month = currentMonth) {
    const shiftHistory = await cicassService.getAll(
      apiEndpointShiftHistory + `/${year}/${month}`
    );
    setShiftHistory(shiftHistory.data);
  }

  function onMonthChange(e) {
    setDatePickerValue(e);
    refreshGrid(e.getFullYear(), e.getMonth() + 1);
  }

  function toolbarClick(args) {
    switch (args.item.properties.id) {
      case "tickets_excelexport":
        shiftHistoryGridRef.current.excelExport();
        break;
      default:
        break;
    }
  }

  const rowDataBound = (args) => {
    return;
  };

  return (
    <>
      <div style={{ margin: "1.2rem 0 .3rem 1.2rem" }}>
        <Box
          sx={{
            display: "flex",
            mr: 2,
            mt: 2,
            alignItems: "center",
          }}
        >
          <h2 className="text-gray-800 text-xl font-semibold">
            {t("ShiftChangeHistory")}
          </h2>
          <Box
            sx={{
              ml: "auto",
            }}
          >
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              locale={itLocale}
              sx={{
                ml: "auto",
              }}
            >
              <DatePicker
                views={["year", "month"]}
                minDate={new Date(new Date().getFullYear(), 0, 1)}
                maxDate={maxDate}
                value={datePickerValue}
                onChange={onMonthChange}
                renderInput={(params) => (
                  <TextField {...params} helperText={null} />
                )}
              />
            </LocalizationProvider>
          </Box>
        </Box>
      </div>

      <Box
        sx={{
          m: 2,
        }}
      >
        {shiftHistory && (
          <GridComponent
            id={"tickets"}
            ref={(g) => (shiftHistoryGridRef.current = g)}
            dataSource={shiftHistory}
            toolbar={toolbarOptions}
            allowResizing={true}
            allowFiltering={true}
            filterSettings={filterSettings}
            allowSorting={true}
            pageSettings={{ pageSize: 30 }}
            allowExcelExport={true}
            locale={localStorage.getItem("weblang")}
            enableVirtualization={true}
            // height={800}
            // actionComplete={e => actionComplete(e, props)}
            // actionBegin={actionBegin}
            // commandClick={e => commandClick(e)}
            toolbarClick={(e) => toolbarClick(e)}
            rowDataBound={(e) => rowDataBound(e)}
          >
            <ColumnsDirective>
              <ColumnDirective
                field="fromDescription"
                headerText={t("fromDescription")}
              />
              <ColumnDirective
                field="toDescription"
                headerText={t("toDescription")}
              />
              <ColumnDirective
                field="confirmedAt"
                type="date"
                format={"dd/MM/yyyy"}
                headerText={t("confirmedAt")}
                allowFiltering={false}
                width="90"
              />
              <ColumnDirective
                field="refusedAt"
                type="date"
                format={"dd/MM/yyyy"}
                headerText={t("refusedAt")}
                allowFiltering={false}
                width="90"
              />
              <ColumnDirective
                field="deletedAt"
                type="date"
                format={"dd/MM/yyyy"}
                headerText={t("deletedAt")}
                allowFiltering={false}
                width="90"
              />
            </ColumnsDirective>
            <Inject
              services={[
                Resize,
                Sort,
                Filter,
                Toolbar,
                ExcelExport,
                // ForeignKey,
                VirtualScroll,
              ]}
            />
          </GridComponent>
        )}
      </Box>
    </>
  );
};

export default ShiftHistory;
