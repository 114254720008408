import React from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";

const AssignShiftDialog = props => {
  const {
    dataSource,
    titleContent,
    onChange,
    onOpen,
    onSubmit,
    translate,
    placeholder = "...",
    handleClose,
    isOpen,
  } = props;

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}>
      <DialogTitle id="responsive-dialog-title">{titleContent}</DialogTitle>
      <DialogContent>
        <>
          <ComboBoxComponent
            id={"cbcForce"}
            dataSource={dataSource}
            fields={{ value: "anaUserId", text: "nameSurname" }}
            placeholder={placeholder}
            autofill={true}
            change={onChange}
            open={onOpen}
          />
          <br />
          <ButtonComponent
            style={{ marginLeft: "1rem" }}
            cssClass="e-control e-btn e-lib e-primary e-flat"
            onClick={() => onSubmit()}>
            {translate("okBtn")}
          </ButtonComponent>
          <ButtonComponent
            style={{ marginLeft: "1rem" }}
            cssClass="e-control e-btn e-lib e-flat"
            onClick={handleClose}>
            {translate("cancelBtn")}
          </ButtonComponent>
          <br />
        </>
      </DialogContent>
    </Dialog>
  );
};

export default AssignShiftDialog;
