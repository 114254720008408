import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import XHR from "i18next-xhr-backend";
import { TokenContextProvider } from "./components/TokenContext";
import { config } from "./_helpers";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";
var language = i18next.language || "it";

const backendOptions = {
	type: "backend",
	crossDomain: true,
	allowMultiLoading: false,
	loadPath: config.apiUrl + `getlang/{{lng}}`,
};

const options = {
	interpolation: {
		escapeValue: false, // not needed for react!!
	},
	initImmediate: false,
	debug: false,
	lng: language,
	fallbackLng: language,
	ns: ["translations"],
	defaultNS: "translations",
	react: {
		wait: false,
		bindI18n: "languageChanged loaded",
		bindStore: "added removed",
		nsMode: "default",
		defaultTransParent: "div",
		useSuspense: true,
	},
};

options["backend"] = backendOptions;

i18next.use(XHR).init(options);

if (localStorage.getItem("weblang") == null)
	localStorage.setItem("weblang", "it");

ReactDOM.render(
	<React.StrictMode>
		<I18nextProvider i18n={i18next}>
			<TokenContextProvider>
				<App />
			</TokenContextProvider>
		</I18nextProvider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
