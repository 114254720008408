import React from "react";
import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Page,
  Inject,
  Resize,
  Edit,
  CommandColumn,
  ForeignKey,
  Filter,
  Toolbar,
  ExcelExport,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { L10n, getValue } from "@syncfusion/ej2-base";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { rippleMouseHandler } from "@syncfusion/ej2-buttons";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
// import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
//import * as EJ2_LOCALE from '../../../../../node_modules/@syncfusion/ej2-locale/src/it.json'
import { auth, cicassService } from "../../../../_services";
import { DateTime } from "luxon";
import { withTranslation } from "react-i18next";
import TokenContext from "components/TokenContext";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import { Box, TextField } from "@mui/material";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import itLocale from "date-fns/locale/it";

const editSettings = {
  allowEditing: true,
  allowAdding: true,
  allowDeleting: true,
  mode: "Dialog",
  dialog: { enableResize: true },
  showDeleteConfirmDialog: true,
};
const filterSettings = { type: "CheckBox" }; // Menu, Excel
const commands = [
  {
    type: "Edit",
    buttonOption: { iconCss: " e-icons e-edit", cssClass: "e-flat" },
  },
  {
    type: "Delete",
    buttonOption: { iconCss: "e-icons e-delete", cssClass: "e-flat" },
  },
  {
    type: "Save",
    buttonOption: { iconCss: "e-icons e-update", cssClass: "e-flat" },
  },
  {
    type: "Cancel",
    buttonOption: { iconCss: "e-icons e-cancel-icon", cssClass: "e-flat" },
  },
];
const toolbarOptions = [
  "Search",
  "Add",
  "ExcelExport",
  "Print",
  {
    text: "Refresh",
    tooltipText: "Refresh",
    prefixIcon: "e-MT_reload",
    id: "forceRefresh",
  },
];
const apiUrl = "board";
const apiUrlAllUser = "authlogin";

const maxDate = new Date(new Date().getFullYear() + 1 + "-12-31");
const minDate = new Date(2022, 0, 1);

L10n.load("it");

class Board extends React.Component {
  _isMounted = false;
  grid = null;
  static contextType = TokenContext;

  constructor(props) {
    super(props);
    this.position = { X: "Right", Y: "Bottom" };
    this.state = {
      myData: [],
      yearData: [],
      userData: [],
      visibleOnGrid: false,
      didMountCompleted: false,
      isCicass: false,
      toastInfo: {
        title: "",
        content: "Succesfully",
        cssClass: "e-toast-success",
        icon: "e-success toast-icons",
      },
      yearFields: { value: "boardYear", text: "boardYear" },
      userFields: { text: "nameSurname", value: "anaUserId" },
      datePickerValue: new Date(),
    };
    this.validationIsRequired = { required: true };
    this.validationSubject = { required: true, maxLength: 100 };
    this.validationMain = { required: true };
    this.validationVisibleAt = { required: true }; // , min: [this.gtToday.bind(this), 'Greater than today']
    this.actionComplete = this.actionComplete.bind(this);
    this.actionBegin = this.actionBegin.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
    this.onYearChange = this.onYearChange.bind(this);
  }

  // Currently not being used
  gtToday(args) {
    const tomorrow = DateTime.now().plus({ day: 1 }).toFormat("yyyy-MM-dd");
    let valueRead = getValue("value", args);
    let insertDate =
      valueRead.substring(6, 10) +
      "-" +
      valueRead.substring(3, 5) +
      "-" +
      valueRead.substring(0, 2);
    return insertDate >= tomorrow;
  }

  async componentDidMount() {
    this._isMounted = true;
    const myrole = auth.getUserRole();
    // const weblang = localStorage.getItem("weblang");
    let { data: yearData } = await cicassService.getAll(apiUrl + "/year");
    let { data: myData } = await cicassService.getAll(
      apiUrl + "/" + yearData[0].boardYear
    );
    let { data: userData } = await cicassService.getAll(apiUrlAllUser);
    let isCicass = myrole === "SuperUser";
    if (this._isMounted) {
      this.setState({
        didMountCompleted: true,
        myData,
        yearData,
        userData,
        isCicass,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toolbarClick(args) {
    switch (args.item.properties.id) {
      case "gridcomp_excelexport":
        this.gridInstance.excelExport();
        break;
      default:
        break;
    }
    if (args.item.id === "forceRefresh") {
      this.refreshGrid();
    }
  }

  refreshGrid() {
    const selectedYear = this.state.datePickerValue.getFullYear();
    cicassService.getAll(apiUrl + "/" + selectedYear).then((response) => {
      this.setState({ myData: response.data });
    });
  }

  actionBegin(state) {
    if (state.requestType === "beginEdit" || state.requestType === "add") {
      if (state.requestType === "add") {
        state.id = -1;
        state.data.anaAgencyId = auth.getAgencyId();
        const token = this.context;
        state.data.anaUserId = auth.getUserId(token.jwt);
        state.data.isVisible = 1;
      }
      this.setState({ visibleOnGrid: true });
    }
  }

  actionComplete(state) {
    if (state.requestType === "add" || state.requestType === "beginEdit") {
      state.dialog.enableResize = true;
      //state.dialog.element.style.maxHeight = 900;
      state.dialog.width = 600;
      state.dialog.height = 600;
      this.rowData = Object.assign({}, state.rowData);
    }
    if (state.requestType === "save") {
      const isVisible = "isVisible";
      state.data[isVisible] = this.rowData[isVisible] ? 1 : 0;
    }
    if (state.action === "add") {
      state.data.id = -1;
      state.data.createdAt = new Date();
      const token = this.context;
      state.data.anaUserId = auth.getUserId(token.jwt);
      delete state.data.anaAgencyId;
      cicassService
        .saveOp(apiUrl, state.data)
        .then(() => {
          this.refreshGrid();
          this.setToastInfo("Success", this.props.t("toastSuccess"));
        })
        .catch((err) => {
          this.setToastInfo("Error", "I: " + err.message);
          this.refreshGrid();
        });
    }
    if (state.action === "edit") {
      delete state.data.anaAgencyId;
      cicassService
        .saveOp(apiUrl, state.data)
        .then(() => {
          this.refreshGrid();
          this.setToastInfo("Success", this.props.t("toastSuccess"));
        })
        .catch((err) => {
          this.setToastInfo("Error", "U: " + err.message);
          this.refreshGrid();
        });
    }
    if (state.requestType === "delete") {
      cicassService
        .deleteOp(apiUrl, state.data[0].id)
        .then(() => {
          this.refreshGrid();
          this.setToastInfo("Success", this.props.t("toastSuccess"));
        })
        .catch((err) => {
          this.setToastInfo("Error", "D:" + err.message);
          this.refreshGrid();
        });
    }

    this.setState({ visibleOnGrid: false });
  }

  setToastInfo(title, errorMsg) {
    let { toastInfo } = this.state;
    switch (title) {
      case "Warning":
        toastInfo.title = "Warning";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-warning";
        toastInfo.icon = "e-warning toast-icons";
        break;
      case "Success":
        toastInfo.title = "Success";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-success";
        toastInfo.icon = "e-success toast-icons";
        break;
      case "Error":
        toastInfo.title = "Error";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-danger";
        toastInfo.icon = "e-error toast-icons";
        break;
      case "Information":
        toastInfo.title = "Information";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-info";
        toastInfo.icon = "e-info toast-icons";
        break;
      default:
        break;
    }
    this.setState({ toastInfo });
    this.toastObj.show();
    return;
  }

  editTemplateVisibleAt(args) {
    return (
      <DatePickerComponent
        value={getValue("visibleAt", args)}
        id="visibleAt"
        placeholder={this.props.t("visibleAt")}
        floatLabelType="Always"
        format={"dd/MM/yyyy"}
        locale="it"
      />
    );
  }

  editTemplateMain(args) {
    return (
      <div>
        <TextBoxComponent
          id="main"
          value={getValue("main", args)}
          multiline={true}
          floatLabelType="Auto"
          placeholder={this.props.t("main")}
        ></TextBoxComponent>
      </div>
    );
  }

  editTemplateSc(args) {
    let isChecked = getValue("isVisible", args);
    return (
      <div>
        <label htmlFor="checked" style={{ padding: "10px 72px 10px 0" }}>
          {this.props.t("isVisible")}
        </label>
        <SwitchComponent
          id="checked"
          checked={isChecked}
          change={this.changeFnSc.bind(this)}
        />
      </div>
    );
  }

  changeFnSc(e) {
    if (this.rowData) {
      this.rowData["isVisible"] = e.checked;
    }
  }

  templateSc(args) {
    let isChecked = getValue("isVisible", args) == 1 ? true : false;
    if (isChecked) return <i className="far fa-check-square"></i>;
    return <i className="far fa-square"></i>;
  }

  renderComplete() {
    let elemArray = document.querySelectorAll(".switch-control label");
    for (let i = 0, len = elemArray.length; i < len; i++) {
      elemArray[i].addEventListener("mouseup", rippleHandler);
      elemArray[i].addEventListener("mousedown", rippleHandler);
    }
    function rippleHandler(e) {
      let rippleSpan = this.nextElementSibling.querySelector(
        ".e-ripple-container"
      );
      if (rippleSpan) {
        rippleMouseHandler(e, rippleSpan);
      }
    }
  }

  onYearChange(newValue) {
    if (newValue.getFullYear() === this.state.datePickerValue.getFullYear())
      return;
    cicassService
      .getAll(apiUrl + "/" + newValue.getFullYear())
      .then((response) => {
        this.setState({ myData: response.data });
      });
  }

  render() {
    const { t } = this.props;
    const {
      myData,
      didMountCompleted,
      isCicass,
      toastInfo,
      userData,
      // yearData,
      // yearFields,
    } = this.state;
    return (
      <>
        <div style={{ margin: "1.2rem 0 .3rem 1.1rem" }}>
          <Box
            sx={{
              display: "flex",
              mr: 2,
              mt: 2,
              alignItems: "center",
            }}
          >
            <h2 className="text-gray-800 text-xl font-semibold">
              {t("titleBoardJs")}
            </h2>
            <Box
              sx={{
                ml: "auto",
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                locale={itLocale}
                sx={{
                  ml: "auto",
                }}
              >
                <DatePicker
                  views={["year"]}
                  minDate={minDate}
                  maxDate={maxDate}
                  value={this.state.datePickerValue}
                  onYearChange={this.onYearChange}
                  onChange={(newValue) => {
                    this.setState({ datePickerValue: newValue });
                  }}
                  renderInput={(params) => (
                    <TextField {...params} helperText={null} />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Box>
        </div>
        <div className="p-4 flex-auto">
          {/* {yearData[0] && (
            <DropDownListComponent
              id="ddlYear"
              ref={scope => {
                this.dropDownListObject = scope;
              }}
              dataSource={yearData}
              fields={yearFields}
              placeholder="Select a year"
              value={yearData[0].boardYear}
              change={this.test}
            />
          )} */}
          {didMountCompleted && (
            <div className="mt-2">
              <GridComponent
                id="gridcomp"
                ref={(grid) => (this.gridInstance = grid)}
                dataSource={myData}
                allowResizing={true}
                toolbar={toolbarOptions}
                editSettings={editSettings}
                allowSorting={true}
                allowFiltering={true}
                filterSettings={filterSettings}
                allowPaging={true}
                pageSettings={{ pageSize: 10, pageCount: 5 }}
                allowExcelExport={true}
                actionComplete={this.actionComplete}
                actionBegin={this.actionBegin}
                toolbarClick={this.toolbarClick.bind(this)}
                locale="it"
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="id"
                    headerText={t("id")}
                    textAlign="Right"
                    isPrimaryKey={true}
                    defaultValue="-1"
                    allowEditing={false}
                    visible={isCicass}
                  />
                  <ColumnDirective
                    field="anaUserId"
                    headerText={t("anaUserIdManager")}
                    foreignKeyValue="name"
                    foreignKeyField="id"
                    dataSource={userData}
                    width="150"
                    validationRules={this.validationRules}
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="subject"
                    headerText={t("subject")}
                    allowEditing={true}
                    validationRules={this.validationSubject}
                  />
                  <ColumnDirective
                    field="main"
                    headerText={t("main")}
                    editTemplate={this.editTemplateMain.bind(this)}
                    allowEditing={true}
                    validationRules={this.validationMain}
                  />
                  <ColumnDirective
                    field="visibleAt"
                    headerText={t("visibleAt")}
                    editTemplate={this.editTemplateVisibleAt.bind(this)}
                    type="date"
                    format="dd/MM/yyyy"
                    allowEditing={true}
                    validationRules={this.validationVisibleAt}
                  />
                  <ColumnDirective
                    field="isVisible"
                    headerText={t("isVisible")}
                    editType="numericedit"
                    editTemplate={this.editTemplateSc.bind(this)}
                    template={this.templateSc.bind(this)}
                  />
                  <ColumnDirective
                    field="createdAt"
                    headerText={t("createdAt")}
                    type="date"
                    format={"dd/MM/yyyy HH:mm:ss"}
                    visible={isCicass}
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="updatedAt"
                    headerText={t("updatedAt")}
                    type="date"
                    format={"dd/MM/yyyy HH:mm:ss"}
                    visible={isCicass}
                    allowEditing={false}
                  />
                  <ColumnDirective
                    field="anaAgencyId"
                    headerText={t("anaAgencyId")}
                    allowEditing={false}
                    visible={isCicass}
                  />
                  <ColumnDirective
                    headerText={t("operationOnGrid")}
                    width="160"
                    commands={commands}
                  />
                </ColumnsDirective>
                <Inject
                  services={[
                    Resize,
                    Filter,
                    Page,
                    Toolbar,
                    Edit,
                    ForeignKey,
                    CommandColumn,
                    ExcelExport,
                    Sort,
                  ]}
                />
              </GridComponent>
            </div>
          )}
          <ToastComponent
            ref={(toast) => {
              this.toastObj = toast;
            }}
            id="toast_default"
            position={this.position}
            title={toastInfo.title}
            content={toastInfo.content}
            cssClass={toastInfo.cssClass}
            icon={toastInfo.icon}
          />
        </div>
      </>
    );
  }
}
export default withTranslation()(Board);
