import React from 'react';
import "./loading.css";

class Loading extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
    }


    render() {
        return (
            <React.Fragment>
                <div className="loading"></div>
            </React.Fragment>
        );
    };
};

export default Loading;