import { useState, forwardRef, useImperativeHandle } from "react";
import {
	Box,
	Stepper,
	Step,
	StepLabel,
	StepContent,
	Button,
	Paper,
	Typography,
	Popover,
	IconButton,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const ChangeShiftStepper = forwardRef((props, ref) => {
	const [activeStep, setActiveStep] = useState(0);
	const [anchorEl, setAnchorEl] = useState(null);
	const isPopoverOpen = Boolean(anchorEl);

	const steps = [
		{
			label: "Scegli il tuo turno che vuoi cambiare",
			description: `Clicca su uno dei tuoi turni con data superiore a oggi, quando verrà evidenziato con un contorno verde potrai procedere al passaggio successivo.`,
		},
		{
			label: "Scegli il turno della persona a cui vuoi chiedere il cambio",
			description: `Clicca sul turno di una altra persona, oppure scegli la persona a cui vuoi cedere il turno con la disponibilità per quel turno.
                    N.B.: il cambio può avvenire solo a parità di ruolo.`,
		},
		{
			label: "Conferma",
			description: `La tua richiesta verrà inoltrata alla persona a cui hai indicato.
                N.B.: Il cambio sarà effettivo se e solo se confermato dal destinatario.
                Sono ammesse più richieste di cambio per uno stesso turno.
                Alla prima risposta positiva il cambio verrà confermato e le restanti 
                richieste per lo stesso turno verranno automaticamente eliminate.`,
		},
	];

	useImperativeHandle(ref, () => ({
		reset() {
			setActiveStep(0);
			props.resetStep();
		},
	}));

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		switch (activeStep) {
			case 0:
				props.nextStep();
				break;
			case 1:
				props.nextStep();
				break;
			case 2:
				props.finalStep();
				break;
			default:
				break;
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
		switch (activeStep) {
			case 0:
				props.prevStep();
				break;
			case 1:
				props.prevStep();
				break;
			case 2:
				props.prevStep();
				break;
			default:
				break;
		}
	};

	function handleReset() {
		setActiveStep(0);
		props.resetStep();
	}

	function handlePopoverButtonClick(event) {
		setAnchorEl(event.currentTarget);
	}

	return (
		<Box className="sticky calendarBoxStepper">
			<Stepper
				activeStep={activeStep}
				orientation="vertical"
			>
				{steps.map((step, index) => (
					<Step key={step.label}>
						<StepLabel><div className="calendarStepLabel">{step.label}</div></StepLabel>
						<StepContent>
							<Typography>
								{index === 1 && props.additionalInfo !== null ? (
									<>
										{`Richiesta a ${props.additionalInfo}.`}
										<br />
										<br />
									</>
								) : (
									""
								)}
								<IconButton
									onClick={handlePopoverButtonClick}
									size="small"
									color="primary"
								>
									<InfoIcon />
								</IconButton>
							</Typography>
							<Popover
								key={step.label}
								anchorEl={anchorEl}
								open={isPopoverOpen}
								onClick={() => setAnchorEl(null)}
								anchorOrigin={{
									vertical: "bottom",
									horizontal: "left",
								}}
							>
								<Typography sx={{ p: 2 }}>{step.description}</Typography>
							</Popover>

							<Box sx={{ mb: 2 }}>
								<div>
									<Button
										disabled={
											(props.fromUser === null && index === 0) ||
											(props.toUser === null && index === 1)
										}
										variant="contained"
										onClick={handleNext}
										sx={{ mt: 1, mr: 1, color: "white" }}
									>
										<div className="calendarStepLabel">{index === steps.length - 1 ? "Conferma" : "Continua"}</div>		
									</Button>
									{index === 99 && props.fromUser !== null && (
										<Button
											visible={index === 1}
											sx={{ mt: 1, mr: 1 }}
										>
											Disponibili per questo turno
										</Button>
									)}

									<Button
										disabled={index === 0}
										onClick={handleBack}
										sx={{ mt: 1, mr: 1 }}
									>
										<div className="calendarStepLabel">Precedente</div>
									</Button>
								</div>
							</Box>
						</StepContent>
					</Step>
				))}
			</Stepper>
			{activeStep === steps.length && (
				<Paper
					square
					elevation={0}
					sx={{ p: 3 }}
				>
					<Typography className="calendarStepContent">
						Tutti i passaggio sono stati completati correttamente
					</Typography>
					<Button
						onClick={handleReset}
						sx={{ mt: 1, mr: 1 }}
					>
						Reset
					</Button>
				</Paper>
			)}
		</Box>
	);
});

export default ChangeShiftStepper;
