import React from "react";
import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Page,
  Inject,
  Resize,
  Edit,
  CommandColumn,
  ForeignKey,
  Filter,
  Toolbar,
  ExcelExport,
  PdfExport,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import { auth, cicassService } from "../../../../_services";
import { withTranslation } from "react-i18next";
import "./agency.css";

const editSettings = {
  allowEditing: true,
  allowAdding: true,
  allowDeleting: true,
  mode: "Dialog",
  dialog: { enableResize: true },
  showDeleteConfirmDialog: true,
};
const editSettingsAdmin = {
  allowEditing: true,
  allowAdding: false,
  allowDeleting: false,
  mode: "Dialog",
  dialog: { enableResize: true },
  showDeleteConfirmDialog: true,
};
const filterSettings = { type: "CheckBox" }; // Menu, Excel
const commands = [
  {
    type: "Edit",
    buttonOption: { iconCss: " e-icons e-edit", cssClass: "e-flat" },
  },
  {
    type: "Delete",
    buttonOption: { iconCss: "e-icons e-delete", cssClass: "e-flat" },
  },
  {
    type: "Save",
    buttonOption: { iconCss: "e-icons e-update", cssClass: "e-flat" },
  },
  {
    type: "Cancel",
    buttonOption: { iconCss: "e-icons e-cancel-icon", cssClass: "e-flat" },
  },
];
const commandsAdmin = [
  {
    type: "Edit",
    buttonOption: { iconCss: " e-icons e-edit", cssClass: "e-flat" },
  },
  {
    type: "Save",
    buttonOption: { iconCss: "e-icons e-update", cssClass: "e-flat" },
  },
  {
    type: "Cancel",
    buttonOption: { iconCss: "e-icons e-cancel-icon", cssClass: "e-flat" },
  },
];
const toolbarOptions = [
  "Search",
  "Add",
  "ExcelExport",
  "Print",
  {
    text: "Refresh",
    tooltipText: "Refresh",
    prefixIcon: "e-MT_reload",
    id: "forceRefresh",
  },
];
const toolbarOptionsAdmin = [
  "Search",
  "Print",
  {
    text: "Refresh",
    tooltipText: "Refresh",
    prefixIcon: "e-MT_reload",
    id: "forceRefresh",
  },
];
const apiUrl = "ana_agency";
const apiUrlSubscription = "ana_subscription";

class Agency extends React.Component {
  _isMounted = false;
  grid = null;
  constructor(props) {
    super(props);
    this.position = { X: "Right", Y: "Bottom" };
    this.renderingMode = "Vertical"; //Vertical - Horizontal
    this.state = {
      myData: {},
      subscriptionData: {},
      subscriptionDataCombo: { text: "name", value: "id" },
      isOk: true,
      isCicass: false, // lo predero da JWT poi
      visibleOnGrid: false,
      toastInfo: {
        title: "",
        content: "Succesfully",
        cssClass: "e-toast-success",
        icon: "e-success toast-icons",
      },
    };
    this.validationRules = { required: true };
    this.actionComplete = this.actionComplete.bind(this);
    this.actionBegin = this.actionBegin.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    const myRole = auth.getUserRole();
    const myAgencyId = auth.getAgencyId();
    let { data: myData } = await cicassService.getAll(
      apiUrl + (myRole == "SuperUser" ? "" : "/" + myAgencyId)
    );
    let { data: subscriptionData } = await cicassService.getAll(
      apiUrlSubscription
    );
    if (myRole != "SuperUser") {
      let copyData = myData;
      myData = [];
      if (copyData != null) myData.push(copyData);
    }

    if (this._isMounted) {
      this.setState({
        isOk: true,
        myData,
        subscriptionData,
        isCicass: myRole == "SuperUser" ? true : false,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toolbarClick(args) {
    switch (args.item.properties.id) {
      case "gridcomp_excelexport":
        this.gridInstance.excelExport();
        break;
    }
    if (args.item.id === "forceRefresh") {
      this.refreshGrid();
    }
  }

  refreshGrid = async () => {
    try {
      const response = await cicassService.getAll(
        apiUrl +
          (auth.getUserRole() === "SuperUser" ? "" : "/" + auth.getAgencyId())
      );
      // response.data is returned as an array of objects when role is SuperUser
      // but it is returned as an object if role isn't SuperUser.
      const newData = Array.isArray(response.data)
        ? response.data
        : [response.data];
      this.setState({ myData: newData });
    } catch (error) {
      console.error("Error refreshing grid:", error);
    }
  };

  actionBegin(state) {
    if (state.requestType == "beginEdit" || state.requestType == "add") {
      if (state.requestType == "add") {
        state.data.id = -1;
        state.data.isDefault = 1;
        state.data.isHidden = 0;
        state.data.viewOrder = 0;
      }
      this.setState({ visibleOnGrid: true });
    }
  }

  actionComplete(state) {
    if (state.requestType === "add" || state.requestType === "beginEdit") {
      state.dialog.enableResize = true;
      //state.dialog.element.style.maxHeight = 900;
      state.dialog.width = 600;
      state.dialog.height = 600;
    }
    if (state.action === "add") {
      state.data.id = -1;
      state.data.createdAt = new Date();
      cicassService
        .saveOp(apiUrl, state.data)
        .then(() => {
          this.refreshGrid();
          this.setToastInfo("Success", this.props.t("toastSuccess"));
        })
        .catch((err) => {
          this.setToastInfo("Error", "I: " + err.message);
          this.refreshGrid();
        });
    }
    if (state.action === "edit") {
      cicassService
        .saveOp(apiUrl, state.data)
        .then(() => {
          this.refreshGrid();
          this.setToastInfo("Success", this.props.t("toastSuccess"));
        })
        .catch((err) => {
          this.setToastInfo("Error", "U: " + err.message);
          this.refreshGrid();
        });
    }
    if (state.requestType === "delete") {
      cicassService
        .deleteOp(apiUrl, state.data[0].id)
        .then(() => {
          this.refreshGrid();
          this.setToastInfo("Success", this.props.t("toastSuccess"));
        })
        .catch((err) => {
          this.setToastInfo("Error", "D:" + err.message);
          this.refreshGrid();
        });
    }
    this.setState({ visibleOnGrid: false });
  }

  setToastInfo(title, errorMsg) {
    let { toastInfo } = this.state;
    switch (title) {
      case "Warning":
        toastInfo.title = "Warning";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-warning";
        toastInfo.icon = "e-warning toast-icons";
        break;
      case "Success":
        toastInfo.title = "Success";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-success";
        toastInfo.icon = "e-success toast-icons";
        break;
      case "Error":
        toastInfo.title = "Error";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-danger";
        toastInfo.icon = "e-error toast-icons";
        break;
      case "Information":
        toastInfo.title = "Information";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-info";
        toastInfo.icon = "e-info toast-icons";
        break;
    }
    this.setState({ toastInfo });
    this.toastObj.show();
    return;
  }

  load() {
    this.adaptiveDlgTarget =
      document.getElementsByClassName("e-mobile-content")[0];
  }

  render() {
    const { myData, visibleOnGrid, isCicass, subscriptionData, toastInfo } =
      this.state;
    const { t } = this.props;
    return (
      <>
        <div style={{ margin: "1%", marginTop: "1%" }}>
          <h2 className="text-gray-800 text-xl font-semibold">
            {t("titleAgencyJs")}
          </h2>
        </div>
        <div className="p-4 flex-auto">
          {subscriptionData.length > 0 && (
            <div className="col-md-9 e-bigger e-adaptive-demo">
              <div className="e-mobile-layout">
                <div className="e-mobile-content">
                  <GridComponent
                    id="adaptivebrowser"
                    ref={(grid) => (this.gridInstance = grid)}
                    dataSource={myData}
                    allowResizing={true}
                    allowSorting={true}
                    toolbar={isCicass ? toolbarOptions : toolbarOptionsAdmin}
                    editSettings={isCicass ? editSettings : editSettingsAdmin}
                    allowFiltering={true}
                    filterSettings={filterSettings}
                    allowPaging={true}
                    pageSettings={{ pageSize: 10, pageCount: 5 }}
                    allowExcelExport={true}
                    actionComplete={this.actionComplete}
                    actionBegin={this.actionBegin}
                    toolbarClick={this.toolbarClick.bind(this)}
                    locale="it"
                  >
                    <ColumnsDirective>
                      <ColumnDirective
                        field="id"
                        headerText={t("id")}
                        textAlign="Right"
                        isPrimaryKey={true}
                        defaultValue="-1"
                        visible={isCicass}
                      />
                      <ColumnDirective
                        field="name"
                        headerText={t("name")}
                        width="150"
                        validationRules={this.validationRules}
                      />
                      <ColumnDirective
                        field="description"
                        headerText={t("description")}
                        visible={visibleOnGrid}
                      />
                      <ColumnDirective field="piva" headerText={t("piva")} />
                      <ColumnDirective field="cf" headerText={t("cf")} />
                      <ColumnDirective
                        field="address"
                        headerText={t("address")}
                        visible={visibleOnGrid}
                      />
                      <ColumnDirective field="city" headerText={t("city")} />
                      <ColumnDirective
                        field="postcode"
                        headerText={t("postcode")}
                        visible={visibleOnGrid}
                      />
                      <ColumnDirective
                        field="province"
                        headerText={t("province")}
                        visible={visibleOnGrid}
                      />
                      <ColumnDirective
                        field="state"
                        headerText={t("state")}
                        visible={visibleOnGrid}
                      />
                      <ColumnDirective field="email" headerText={t("email")} />
                      <ColumnDirective field="pec" headerText={t("pec")} />
                      <ColumnDirective
                        field="website"
                        headerText={t("website")}
                        visible={visibleOnGrid}
                      />
                      <ColumnDirective
                        field="reference"
                        headerText={t("reference")}
                        visible={visibleOnGrid}
                      />
                      <ColumnDirective
                        field="isDefault"
                        headerText={t("isDefault")}
                        visible={isCicass}
                      />
                      <ColumnDirective
                        field="isHidden"
                        headerText={t("isHidden")}
                        visible={isCicass}
                      />
                      <ColumnDirective
                        field="viewOrder"
                        headerText={t("viewOrder")}
                        visible={isCicass}
                      />
                      <ColumnDirective
                        field="latitude"
                        headerText={t("latitude")}
                        visible={isCicass}
                      />
                      <ColumnDirective
                        field="longitude"
                        headerText={t("longitude")}
                        visible={isCicass}
                      />
                      <ColumnDirective
                        field="anaSubscriptionId"
                        foreignKeyValue="name"
                        foreignKeyField="id"
                        dataSource={subscriptionData}
                        headerText={t("anaSubscriptionId")}
                        width="150"
                        allowEditing={isCicass}
                      />
                      <ColumnDirective
                        field="anaSubscriptionDate"
                        headerText={t("anaSubscriptionDate")}
                        visible={visibleOnGrid}
                        allowEditing={false}
                      />
                      <ColumnDirective
                        field="createdAt"
                        headerText={t("createdAt")}
                        type="date"
                        format={"dd/MM/yyyy HH:mm:ss"}
                        visible={isCicass}
                        allowEditing={false}
                      />
                      <ColumnDirective
                        field="deletedAt"
                        headerText={t("deletedAt")}
                        type="date"
                        format={"dd/MM/yyyy HH:mm:ss"}
                        visible={false}
                        allowEditing={false}
                      />
                      <ColumnDirective
                        field="updatedAt"
                        headerText={t("updatedAt")}
                        type="date"
                        format={"dd/MM/yyyy HH:mm:ss"}
                        visible={isCicass}
                        allowEditing={false}
                      />
                      <ColumnDirective
                        headerText={t("operationOnGrid")}
                        width="160"
                        commands={isCicass ? commands : commandsAdmin}
                      ></ColumnDirective>
                    </ColumnsDirective>
                    <Inject
                      services={[
                        Resize,
                        Filter,
                        Page,
                        Toolbar,
                        Edit,
                        ForeignKey,
                        CommandColumn,
                        ExcelExport,
                        PdfExport,
                        Sort,
                      ]}
                    />
                  </GridComponent>
                </div>{" "}
              </div>
            </div>
          )}
          <ToastComponent
            ref={(toast) => {
              this.toastObj = toast;
            }}
            id="toast_default"
            position={this.position}
            title={toastInfo.title}
            content={toastInfo.content}
            cssClass={toastInfo.cssClass}
            icon={toastInfo.icon}
          ></ToastComponent>
        </div>
      </>
    );
  }
}
export default withTranslation()(Agency);
