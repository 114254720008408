import { auth, cicassService } from "../../../_services";
import React from "react";
import { withTranslation } from "react-i18next";
import { getValue } from "@syncfusion/ej2-base";
import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Page,
  Inject,
  Resize,
  Edit,
  Sort,
  CommandColumn,
  ForeignKey,
  Filter,
  Toolbar,
  ExcelExport,
} from "@syncfusion/ej2-react-grids";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import { rippleMouseHandler } from "@syncfusion/ej2-buttons";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import TokenContext from "components/TokenContext";

const editSettings = {
  allowEditing: true,
  allowAdding: true,
  allowDeleting: true,
  newRowPosition: "Top",
  showDeleteConfirmDialog: true,
};
const filterSettings = { type: "CheckBox" }; // Menu, Excel
const commands = [
  {
    type: "Edit",
    buttonOption: { iconCss: " e-icons e-edit", cssClass: "e-flat" },
  },
  {
    type: "Delete",
    buttonOption: { iconCss: "e-icons e-delete", cssClass: "e-flat" },
  },
  {
    type: "Save",
    buttonOption: { iconCss: "e-icons e-update", cssClass: "e-flat" },
  },
  {
    type: "Cancel",
    buttonOption: { iconCss: "e-icons e-cancel-icon", cssClass: "e-flat" },
  },
  {
    type: "Enable/Disable",
    buttonOption: { iconCss: "e-icons e-M_CheckBox", cssClass: "e-flat" },
  },
  {
    type: "Shift",
    buttonOption: { iconCss: "e-icons e-MT_List", cssClass: "e-flat" },
  },
  {
    type: "Manager",
    buttonOption: { iconCss: "e-icons e-MT_Resource", cssClass: "e-flat" },
  },
];
const toolbarOptions = [
  "Search",
  "Add",
  "ExcelExport",
  "Print",
  {
    text: "Refresh",
    tooltipText: "Refresh",
    prefixIcon: "e-MT_reload",
    id: "forceRefresh",
  },
];
const apiUrl = "ana_calendar";
const apiUrlCalendarType = "ana_calendar_type/combo";

class AnaCalendar extends React.Component {
  _isMounted = false;
  grid = null;
  static contextType = TokenContext;
  constructor(props) {
    super(props);
    this.position = { X: "Right", Y: "Bottom" };
    this.state = {
      myData: [],
      visibleOnGrid: false,
      didMountCompleted: false,
      isCicass: false,
      isAdmin: false,
      agencyData: [],
      calendarTypeData: [],
      vacationData: cicassService.getYesNo(),
      toastInfo: {
        title: "",
        content: "Succesfully",
        cssClass: "e-toast-success",
        icon: "e-success toast-icons",
      },
    };
    this.validationRules = { required: true };
    this.actionComplete = this.actionComplete.bind(this);
    this.actionBegin = this.actionBegin.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    const myrole = auth.getUserRole();
    const weblang = localStorage.getItem("weblang");
    let { data: myData } = await cicassService.getAll(apiUrl);
    let { data: calendarTypeData } = await cicassService.getAll(
      apiUrlCalendarType + "/" + weblang
    );

    if (this._isMounted) {
      this.setState({
        didMountCompleted: true,
        myData,
        calendarTypeData,
        isCicass: myrole === "SuperUser",
        isAdmin: myrole === "Admin" || myrole === "SuperUser",
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toolbarClick(args) {
    switch (args.item.properties.id) {
      case "gridcomp_excelexport":
        this.gridInstance.excelExport();
        break;
      default:
        break;
    }
    if (args.item.id === "forceRefresh") {
      this.refreshGrid();
    }
  }

  refreshGrid() {
    cicassService.getAll(apiUrl).then((response) => {
      this.setState({ myData: response.data });
    });
  }

  actionBegin(state) {
    if (state.requestType === "beginEdit" || state.requestType === "add") {
      if (state.requestType === "add") {
        state.data.id = -1;
        state.data.createdAt = new Date();
        state.data.anaAgencyId = auth.getAgencyId();
        const token = this.context;
        state.data.anaUserId = auth.getUserId(token.jwt);
        state.data.userNote = "";
      }
      this.setState({ visibleOnGrid: true });
    }
  }
  onRowDataBound(args) {
    if (args.data.anaCalendarTypeId == 2) {
      args.row
        .querySelectorAll(".e-icons.e-MT_List")[0]
        .classList.add("e-disabled");
    }
  }

  async commandClick(args) {
    if (args.commandColumn) {
      if (
        args.commandColumn.type === "Shift" &&
        args.rowData.anaCalendarTypeId == 1
      ) {
        this.props.history.push(
          `/app/anacalendarshift/${args.rowData.id}`,
          `${args.rowData.name}`
        );
      }
      if (args.commandColumn.type === "Manager") {
        this.props.history.push(
          `/app/anacalendarmanager/${args.rowData.id}`,
          `${args.rowData.name}`
        );
      }
      if (args.commandColumn.type === "Enable/Disable") {
        cicassService
          .getAll(apiUrl + "/enabled/" + args.rowData.id)
          .then((response) => {
            this.refreshGrid();
            this.setToastInfo("Success", "");
          })
          .catch((err) => {
            this.setToastInfo("Error", "EN/DI: " + err.message);
            this.refreshGrid();
          });
      }
    }
  }

  actionComplete(state) {
    /* if (state.requestType === "add" || state.requestType === "beginEdit") {
       state.dialog.enableResize = true;
       //state.dialog.element.style.maxHeight = 900;
       state.dialog.width = 600;
       state.dialog.height = 600;
     }*/
    const { t } = this.props;
    if (state.action === "add") {
      state.data.id = -1;
      cicassService
        .saveOp(apiUrl, state.data)
        .then(() => {
          this.refreshGrid();
          this.setToastInfo("Success", t("addToastOK"));
        })
        .catch((err) => {
          this.setToastInfo("Error", "I: " + err.message);
          this.refreshGrid();
        });
    }
    if (state.action === "edit") {
      cicassService
        .saveOp(apiUrl, state.data)
        .then(() => {
          this.refreshGrid();
          this.setToastInfo("Information", t("editToastOK"));
        })
        .catch((err) => {
          this.setToastInfo("Error", "U: " + err.message);
          this.refreshGrid();
        });
    }
    if (state.requestType === "delete") {
      cicassService
        .deleteOp(apiUrl, state.data[0].id)
        .then(() => {
          this.refreshGrid();
          this.setToastInfo("Warning", t("deleteToastOK"));
        })
        .catch((err) => {
          this.setToastInfo("Error", "D:" + err.message);
          this.refreshGrid();
        });
    }

    this.setState({ visibleOnGrid: false });
  }

  setToastInfo(title, errorMsg) {
    let { toastInfo } = this.state;
    switch (title) {
      case "Warning":
        toastInfo.title = "Warning";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-warning";
        toastInfo.icon = "e-warning toast-icons";
        break;
      case "Success":
        toastInfo.title = "Success";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-success";
        toastInfo.icon = "e-success toast-icons";
        break;
      case "Error":
        toastInfo.title = "Error";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-danger";
        toastInfo.icon = "e-error toast-icons";
        break;
      case "Information":
        toastInfo.title = "Information";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-info";
        toastInfo.icon = "e-info toast-icons";
        break;
      default:
        break;
    }
    this.setState({ toastInfo });
    this.toastObj.show();
    return;
  }

  renderComplete() {
    let elemArray = document.querySelectorAll(".switch-control label");
    for (let i = 0, len = elemArray.length; i < len; i++) {
      elemArray[i].addEventListener("mouseup", rippleHandler);
      elemArray[i].addEventListener("mousedown", rippleHandler);
    }
    function rippleHandler(e) {
      let rippleSpan = this.nextElementSibling.querySelector(
        ".e-ripple-container"
      );
      if (rippleSpan) {
        rippleMouseHandler(e, rippleSpan);
      }
    }
  }

  editTemplateEnabled(args) {
    let isChecked = getValue("enabled", args);
    const { t } = this.props;
    return (
      <div>
        <label htmlFor="checked" style={{ padding: "10px 72px 10px 0" }}>
          {t("enabled")}
        </label>
        <SwitchComponent
          id="checked"
          checked={isChecked}
          change={this.changeFnEnabled.bind(this)}
        />
      </div>
    );
  }

  changeFnEnabled(e) {
    if (this.rowData) {
      this.rowData["enabled"] = e.checked;
    }
  }

  templateEnabled(args) {
    let isChecked = getValue("enabled", args) == 1;
    if (isChecked) return <i className="far fa-check-square" />;
    return <i className="far fa-square" />;
  }

  render() {
    const { myData, didMountCompleted, calendarTypeData, isCicass, toastInfo } =
      this.state;
    const { t } = this.props;
    return (
      <>
        <div style={{ margin: "1.2rem 0 .5rem 1.2rem" }}>
          <h2 className="text-gray-800 text-xl font-semibold">
            {t("titleAnaCalendartJs")}
          </h2>
        </div>
        <div className="p-4 flex-auto">
          {didMountCompleted && (
            <GridComponent
              id="gridcomp"
              ref={(grid) => (this.gridInstance = grid)}
              dataSource={myData}
              toolbar={toolbarOptions}
              editSettings={editSettings}
              allowResizing={true}
              allowFiltering={true}
              filterSettings={filterSettings}
              allowPaging={true}
              pageSettings={{ pageSize: 10, pageCount: 5 }}
              allowExcelExport={true}
              actionComplete={this.actionComplete}
              actionBegin={this.actionBegin}
              toolbarClick={this.toolbarClick.bind(this)}
              locale="it"
              allowSorting={true}
              commandClick={this.commandClick.bind(this)}
              rowDataBound={this.onRowDataBound.bind(this)}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="id"
                  headerText={t("id")}
                  textAlign="Right"
                  isPrimaryKey={true}
                  defaultValue="-1"
                  allowEditing={false}
                  visible={isCicass}
                />
                <ColumnDirective
                  field="name"
                  headerText={t("name")}
                  width="150"
                  validationRules={this.validationRules}
                />
                <ColumnDirective
                  field="description"
                  headerText={t("description")}
                  validationRules={this.validationRules}
                />
                <ColumnDirective
                  field="anaCalendarTypeId"
                  headerText={t("anaCalendarTypeId")}
                  foreignKeyValue="name"
                  foreignKeyField="id"
                  dataSource={calendarTypeData}
                  width="150"
                  validationRules={this.validationRules}
                />
                <ColumnDirective
                  field="enabled"
                  headerText={t("enabled")}
                  width="100"
                  editType="numericedit"
                  editTemplate={this.editTemplateEnabled.bind(this)}
                  template={this.templateEnabled.bind(this)}
                />
                <ColumnDirective
                  field="viewOrder"
                  headerText={t("viewOrder")}
                  validationRules={this.validationRules}
                />
                <ColumnDirective
                  field="anaAgencyId"
                  headerText={t("anaAgencyId")}
                  allowEditing={false}
                  visible={isCicass}
                />
                <ColumnDirective
                  field="createdAt"
                  headerText={t("createdAt")}
                  type="date"
                  format={"dd/MM/yyyy HH:mm:ss"}
                  visible={isCicass}
                  allowEditing={false}
                />
                <ColumnDirective
                  field="updatedAt"
                  headerText={t("updatedAt")}
                  type="date"
                  format={"dd/MM/yyyy HH:mm:ss"}
                  visible={isCicass}
                  allowEditing={false}
                />
                <ColumnDirective
                  headerText={t("operationOnGrid")}
                  width="180"
                  commands={commands}
                ></ColumnDirective>
              </ColumnsDirective>
              <Inject
                services={[
                  Resize,
                  Filter,
                  Page,
                  Toolbar,
                  Edit,
                  ForeignKey,
                  CommandColumn,
                  ExcelExport,
                  Sort,
                ]}
              />
            </GridComponent>
          )}
          <ToastComponent
            ref={(toast) => {
              this.toastObj = toast;
            }}
            id="toast_default"
            position={this.position}
            title={toastInfo.title}
            content={toastInfo.content}
            cssClass={toastInfo.cssClass}
            icon={toastInfo.icon}
          ></ToastComponent>
        </div>
      </>
    );
  }
}
export default withTranslation()(AnaCalendar);
