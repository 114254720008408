import { useState, forwardRef, useEffect } from "react";
import {
  Button,
  Dialog,
  List,
  ListItem,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
  TextField,
  MenuItem,
  Grid,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import { cicassService, auth } from "../../_services";
import { withTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import * as yup from "yup";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    paddingLeft: "1rem",
    color: "red",
    fontSize: ".8rem",
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "1.5rem",
  },
}));

const apiEndpointGetCalendarFields = "ana_field";
const apiEndpointGetInputTypes = "ana_field_type";
const apiEndpointGetUsers = "authLogin";
const apiEndpointCalendar = "calendar/fields";

function DynamicCalendar(props) {
  const {
    isVisible = false,
    handleClose,
    calendarRowData,
    refreshGrid,
  } = props;
  const classes = useStyles();
  const [builtInputs, setBuiltInputs] = useState([]);
  const [calendarToSave, setCalendarToSave] = useState(
    structuredClone(calendarRowData)
  );

  const booleanOptions = [
    {
      label: "Si",
      value: "S",
    },
    {
      label: "No",
      value: "N",
    },
  ];
  const andataRitornoOptions = [
    {
      label: "Andata",
      value: "A",
    },
    {
      label: "Ritorno",
      value: "R",
    },
  ];
  const dropdownValues = { "S,N": booleanOptions, "A,R": andataRitornoOptions };
  const continentalTimeRegex = new RegExp(
    /^([0-1][0-9]|2[0-3]|[3-9]):[0-5][0-9]$/
  );

  // const validationSchema = yup.object().shape({
  // 	Servizio: yup.string(),
  // 	Mezzo: yup.string(),
  // 	"Partenza Sede Alle": yup
  // 		.string()
  // 		.matches(
  // 			continentalTimeRegex,
  // 			"Must be in format HH:MM (continental time)"
  // 		),
  // 	Da: yup.string(),
  // 	"Orario in Sede": yup
  // 		.string()
  // 		.matches(
  // 			continentalTimeRegex,
  // 			"Must be in format HH:MM (continental time)"
  // 		),
  // 	A: yup.string(),
  // 	"Andata - Ritorno": yup.string(),
  // 	// Autista: yup.number(),
  // 	["Conf. Autista"]: yup.string(),
  // 	["Conf. Volontario"]: yup.string(),
  // 	// Volontario: yup.string(),
  // 	Note: yup.string(),
  // });

  // {
  // 	resolver: yupResolver(validationSchema),
  // }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    let skip = false;
    async function fetchData() {
      // This could be fetched concurrently to improve performance
      const users = await fetchUsers();
      const inputTypes = await fetchInputTypes();

      const response = await cicassService.getAll(apiEndpointGetCalendarFields);
      const templateFields = response.data.map((field) => {
        return {
          id: field.id,
          name: field.name,
          description: field.description,
          anaFieldTypeId: field.anaFieldTypeId,
          isAnaUserIdCombo: field.isAnaUserIdCombo,
          eligibleValues: field.eligibleValues,
          isHidden: field.isHidden,
          isMandatory: field.isMandatory,
          viewOrder: field.viewOrder,
        };
      });

      handleInputs(templateFields, users, inputTypes);
    }
    fetchData();
  }, [isVisible]);

  // TODO This should be modularized and sintetized
  function handleInputs(data, users, inputTypeList) {
    const inputs = [];
    for (const field of data) {
      let {
        id,
        description,
        name: label,
        anaFieldTypeId,
        isMandatory: isRequired,
        eligibleValues,
        isAnaUserIdCombo: isUserDropdown,
        isHidden,
      } = field;

      if (Boolean(isHidden)) return;

      isRequired = Boolean(isRequired);
      isUserDropdown = Boolean(isUserDropdown);

      let jsxElement;
      let dropdownOptions;
      const calendarRow = calendarRowData.find((c) => c.anaFieldId === id);

      let defaultValue = isUserDropdown
        ? calendarRow.fieldId === 0
          ? ""
          : calendarRow.fieldId
        : calendarRow.fieldValue;

      const onChangeHandler = (e) => {
        let fieldVal = "";
        if (!isUserDropdown) fieldVal = e.target.value;

        const data = {
          ...calendarRow,
          id: calendarRow.id,
          rowNr: calendarRow.rowNr,
          anaFieldId: calendarRow.anaFieldId,
          fieldId: isUserDropdown ? e.target.value : 0,
          fieldValue: fieldVal,
        };

        handleRowUpdate(data);
      };

      const inputTypeName = inputTypeList.find(
        (i) => i.id === anaFieldTypeId
      ).name;
      id = `${id}`;

      switch (inputTypeName) {
        case "Testo - Textbox": {
          jsxElement = getTextboxInput({
            id,
            description,
            label,
            isRequired,
            defaultValue,
            onChangeHandler,
          });
          break;
        }
        case "Testo - Combobox": {
          if (isUserDropdown) dropdownOptions = users;
          if (eligibleValues !== "") {
            dropdownOptions = dropdownValues[eligibleValues];
          }

          jsxElement = getSelectInput({
            id,
            description,
            label,
            isRequired,
            options: dropdownOptions,
            defaultValue,
            onChangeHandler,
          });
          break;
        }
        case "Data": {
          jsxElement = getDateInput({
            id,
            description,
            isRequired,
            defaultValue,
            onChangeHandler,
            label,
          });
          break;
        }
        default:
          break;
      }
      if (jsxElement) inputs.push({ jsxElement, fieldName: label });
    }
    setBuiltInputs(inputs);
  }

  function handleRowUpdate(data) {
    const rowIndex = calendarToSave.findIndex((c) => c.id === data.id);
    const updatedRow = {
      ...data,
      anaUserId: 0,
    };

    calendarToSave[rowIndex] = updatedRow;
  }

  async function submitCalendar(data, event) {
    console.table(calendarToSave);

    try {
      // TODO Try to make it work with Promise.all
      for (const row of calendarToSave) {
        await cicassService.saveOp(`${apiEndpointCalendar}`, row);
      }
      handleReset();
    } catch (error) {
      console.error(error.message);
    }
  }

  function handleReset() {
    setCalendarToSave([]);
    setBuiltInputs([]);
    handleClose();
    refreshGrid();
  }

  function getTextboxInput({
    id,
    description,
    label,
    isRequired,
    defaultValue,
    onChangeHandler,
  }) {
    return (
      <Grid container direction="column">
        <Typography variant="h6" component="h4">
          {description}
        </Typography>

        <TextField
          id={id}
          placeholder={label}
          required={isRequired}
          variant="filled"
          type="text"
          fullWidth
          onChange={onChangeHandler}
          defaultValue={defaultValue}
          {...register(label, {
            onChange: onChangeHandler,
          })}
        />
      </Grid>
    );
  }

  function getSelectInput({
    id,
    description,
    label,
    isRequired,
    options,
    onChangeHandler,
    defaultValue,
  }) {
    return (
      <Grid container direction="column">
        <Typography variant="h6" component="h4">
          {description}
        </Typography>
        <TextField
          id={id}
          label={label}
          select
          variant="filled"
          onChange={onChangeHandler}
          defaultValue={defaultValue}
          required={isRequired}
          fullWidth
          {...register(label, { onChange: onChangeHandler })}
        >
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
    );
  }

  function getDateInput({
    id,
    description,
    isRequired,
    defaultValue,
    onChangeHandler,
    label,
  }) {
    return (
      <Grid container direction="column">
        <Typography variant="h6" component="h4">
          {description}
        </Typography>
        <TextField
          id={id}
          placeholder="HH:MM"
          variant="filled"
          type="text"
          required={isRequired}
          fullWidth
          defaultValue={defaultValue}
          {...register(label, {
            onChange: onChangeHandler,
            pattern: {
              value: continentalTimeRegex,
              message: "Must be in format HH:MM (continental time)",
            },
          })}
        />
      </Grid>
    );
  }

  async function fetchInputTypes() {
    const response = await cicassService.getAll(apiEndpointGetInputTypes);
    const inputTypes = response.data.map((input) => {
      return {
        id: input.id,
        name: input.name,
        description: input.description,
        isDefault: input.isDefault,
      };
    });
    return inputTypes;
  }

  async function fetchUsers() {
    const response = await cicassService.getAll(apiEndpointGetUsers);
    const usersList = response.data.map((user) => {
      return {
        label: user.name,
        value: user.id,
      };
    });
    return usersList;
  }

  return (
    <Dialog
      fullScreen
      open={isVisible}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="secondary"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{ ml: 2, flex: 1 }}
            color="secondary"
            variant="h6"
            component="h4"
          >
            {/* TODO Change */}
            Title
          </Typography>
          <Button color="secondary" type="submit" form="calendar-form">
            save
          </Button>
        </Toolbar>
      </AppBar>

      <form id="calendar-form" onSubmit={handleSubmit(submitCalendar)}>
        <List className={classes.inputContainer}>
          {builtInputs.map(({ jsxElement, fieldName }) => {
            return (
              <>
                <ListItem>{jsxElement}</ListItem>
                <ErrorMessage
                  errors={errors}
                  name={fieldName}
                  render={(obj) => (
                    <p className={classes.errorMessage}>{obj.message}</p>
                  )}
                />
              </>
            );
          })}
        </List>
      </form>
    </Dialog>
  );
}

export default withTranslation()(DynamicCalendar);
