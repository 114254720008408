import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import {
  GridComponent,
  Inject,
  Resize,
  Edit,
  CommandColumn,
  ForeignKey,
  Filter,
  Toolbar,
  ExcelExport,
  Sort,
  RowDD,
} from "@syncfusion/ej2-react-grids";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import itLocale from "date-fns/locale/it";
import { TextField } from "@mui/material";
import { cicassService, auth } from "../../_services";
import { DateTime } from "luxon";
import "./Dashboard.css";
import DynamicCalendar from "./DynamicCalendar";
// components
// import CardLineChart from "components/Cards/CardLineChart.js";
// import CardBarChart from "components/Cards/CardBarChart.js";
// import CardPageVisits from "components/Cards/CardPageVisits.js";
// import CardSocialTraffic from "components/Cards/CardSocialTraffic.js";

// export default function Dashboard() {
//   return (
//     <>
//       <div className="flex flex-wrap">
//         <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
//           <CardLineChart />
//         </div>
//         <div className="w-full xl:w-4/12 px-4">
//           <CardBarChart />
//         </div>
//       </div>
//       <div className="flex flex-wrap mt-4">
//         <div className="w-full xl:w-8/12 mb-12 xl:mb-0 px-4">
//           <CardPageVisits />
//         </div>
//         <div className="w-full xl:w-4/12 px-4">
//           <CardSocialTraffic />
//         </div>
//       </div>
//     </>
//   );
// }

const actualYear = new Date().getFullYear() + 1;
const maxDate = new Date(`${actualYear}-12-31`);
const apiEndpointGetCalendarInfo = "ana_calendar_template";
const apiEndpointGetCalendarData = "calendar/6";
const apiEndpointCalendarCrud = "calendar/fields";

const Dashboard = function (props) {
  const { t } = props;
  // const isCicass = auth.getUserRole() === "SuperUser";
  const gridRef = useRef(null);
  const calendarId = useRef(null);
  const [gridDataSource, setGridDataSource] = useState([]);
  const [calendarDataRow, setCalendarDataRow] = useState({});
  const [datePickerValue, setDatePickerValue] = useState(new Date());
  const [calendarInfo, setCalendarInfo] = useState({});
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [isDynamicCalendarOpen, setIsDynamicCalendarOpen] = useState(false);

  const editSettings = {
    allowEditing: false,
    allowAdding: false,
    allowDeleting: true,
  };
  const filterSettings = { type: "CheckBox" };
  const toolbarOptions = [
    "Search",
    {
      text: "Add",
      tooltipText: "Add",
      prefixIcon: "e-icons e-add",
      id: "addCalendarRow",
    },
    "ExcelExport",
    "Print",
  ];

  useEffect(() => {
    fetchCalendar();
    fetchCalendarInfo();
  }, []);

  async function fetchCalendar(date) {
    const parsedDateTime =
      DateTime.fromJSDate(date ?? datePickerValue).toFormat("yyyy-MM-dd") +
      "T00:00:00";
    const response = await cicassService.getAll(
      `${apiEndpointGetCalendarData}/${parsedDateTime}`
    );

    calendarId.current = response.data.CalendarId;
    if (!response.data || response.data.item.length === 0) {
      setGridDataSource([]);
      return;
    }

    setGridDataSource(response.data.item);
    // This causes first load blink, but it's currently neccessary for the grid to appear correctly
    gridRef.current.refresh();
  }

  async function fetchCalendarInfo() {
    const response = await cicassService.getAll(apiEndpointGetCalendarInfo);
    setCalendarInfo(response.data[0]);
  }

  function onMonthChange(e) {
    fetchCalendar(e);
    setDatePickerValue(e);
  }

  function refreshGrid() {
    fetchCalendar();
  }

  async function toolbarClick(args) {
    switch (args.item.properties.id) {
      case "gridcomp_excelexport":
        gridRef.current.excelExport();
        break;
      default:
        break;
    }
    if (args.item.id === "forceRefresh") refreshGrid();
    if (args.item.id === "addCalendarRow") {
      await cicassService.simplePost(
        `${apiEndpointCalendarCrud}/${calendarId.current}`
      );
      fetchCalendar();
    }
  }

  async function handleDynamicCalendarUpdate(e) {
    const rowObj = gridRef.current.getRowInfo(e.target).rowData;
    // console.log(rowObj);
    const { Nr: rowId } = rowObj;

    const response = await cicassService.getAll(
      `${apiEndpointCalendarCrud}/${calendarId.current}/${rowId}`
    );
    setCalendarDataRow(response.data);
    setIsDynamicCalendarOpen(true);
  }

  async function dataBound() {
    const grid = gridRef.current;

    if (grid && isFirstLoad) {
      setIsFirstLoad(false);
      // We have to define the primaryKey while autogeneration columns, else the data doesn't appear
      const column = grid.getColumnByField("Nr");
      column.isPrimaryKey = true;

      const commandColumn = {
        headerText: props.t("operationOnGrid"),
        commands: [
          {
            type: "Edit",
            buttonOption: {
              click: (e) => handleDynamicCalendarUpdate(e),
              iconCss: " e-icons e-edit",
              cssClass: "e-flat",
            },
          },
          {
            type: "Delete",
            buttonOption: { iconCss: "e-icons e-delete", cssClass: "e-flat" },
          },
          {
            type: "Save",
            buttonOption: { iconCss: "e-icons e-update", cssClass: "e-flat" },
          },
          {
            type: "Cancel",
            buttonOption: {
              iconCss: "e-icons e-cancel-icon",
              cssClass: "e-flat",
            },
          },
        ],
      };

      grid.columns.push(commandColumn);
      grid.refreshColumns();
      fetchCalendar();
    }
  }

  return (
    <>
      <header className="dashboard-header">
        <h2 className="main-title">
          {t("titleAnaFieldJs")}: {calendarInfo.name}
        </h2>
        <div className="flex-data-picker">
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={itLocale}>
            <DatePicker
              views={["day"]}
              minDate={new Date("2023-01-01")}
              maxDate={maxDate}
              value={datePickerValue}
              onChange={onMonthChange}
              renderInput={(params) => (
                <TextField {...params} helperText={null} />
              )}
            />
          </LocalizationProvider>
        </div>
      </header>

      <div className="grid-container">
        <GridComponent
          id="gridcomp"
          ref={(grid) => (gridRef.current = grid)}
          dataSource={gridDataSource}
          toolbar={toolbarOptions}
          editSettings={editSettings}
          allowResizing={true}
          allowFiltering={true}
          allowSorting={true}
          allowPaging={false}
          allowExcelExport={true}
          filterSettings={filterSettings}
          pageSettings={{ pageSize: 10, pageCount: 5 }}
          // actionComplete={actionComplete}
          // actionBegin={actionBegin}
          toolbarClick={toolbarClick}
          locale="it"
          dataBound={dataBound}
        >
          <Inject
            services={[
              RowDD,
              Resize,
              Filter,
              Toolbar,
              Edit,
              ForeignKey,
              CommandColumn,
              ExcelExport,
              Sort,
            ]}
          />
        </GridComponent>
      </div>

      {isDynamicCalendarOpen && (
        <DynamicCalendar
          isVisible={isDynamicCalendarOpen}
          handleClose={() => setIsDynamicCalendarOpen(false)}
          calendarRowData={calendarDataRow}
          refreshGrid={refreshGrid}
        />
      )}
    </>
  );
};

export default withTranslation()(Dashboard);
