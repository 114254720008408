import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Page,
  Inject,
  Resize,
  Edit,
  CommandColumn,
  ForeignKey,
  Filter,
  Toolbar,
  ExcelExport,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { getValue } from "@syncfusion/ej2-base";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { rippleMouseHandler } from "@syncfusion/ej2-buttons";
//import * as EJ2_LOCALE from '../../../../../node_modules/@syncfusion/ej2-locale/src/it.json';
import { auth, cicassService } from "../../../../_services";
import React from "react";
import { withTranslation } from "react-i18next";
import UserRoleDialog from "./UserRoleDialog";

const editSettings = {
  allowEditing: true,
  allowAdding: true,
  allowDeleting: true,
  mode: "Dialog",
  dialog: { enableResize: true },
  showDeleteConfirmDialog: true,
};
const filterSettings = { type: "CheckBox" }; // Menu, Excel
const commands = [
  {
    type: "Edit",
    buttonOption: { iconCss: " e-icons e-edit", cssClass: "e-flat" },
  },
  {
    type: "Delete",
    buttonOption: { iconCss: "e-icons e-delete", cssClass: "e-flat" },
  },
  {
    type: "Save",
    buttonOption: { iconCss: "e-icons e-update", cssClass: "e-flat" },
  },
  {
    type: "Cancel",
    buttonOption: { iconCss: "e-icons e-cancel-icon", cssClass: "e-flat" },
  },
  {
    type: "Enable/Disable",
    buttonOption: { iconCss: "e-icons e-M_CheckBox", cssClass: "e-flat" },
  },
  {
    type: "Reset Password",
    buttonOption: { iconCss: "e-icons e-M_Clearform", cssClass: "e-flat" },
  },
  {
    type: "Roles",
    buttonOption: { iconCss: "e-icons e-MT_List", cssClass: "e-flat" },
  },
  {
    type: "SendMail",
    buttonOption: { iconCss: "e-icons e-M_Export", cssClass: "e-flat" },
  },
];
const toolbarOptions = [
  "Search",
  "Add",
  "ExcelExport",
  "Print",
  {
    text: "Refresh",
    tooltipText: "Refresh",
    prefixIcon: "e-MT_reload",
    id: "forceRefresh",
  },
];
const apiUrl = "v_auth";
const apiUrlRoleCombo = "authrole/combo";

class User extends React.Component {
  _isMounted = false;
  grid = null;

  constructor(props) {
    super(props);
    this.position = { X: "Right", Y: "Bottom" };
    this.state = {
      myData: [],
      visibleOnGrid: false,
      didMountCompleted: false,
      isCicass: false,
      roleData: [],
      toastInfo: {
        title: "",
        content: "Succesfully",
        cssClass: "e-toast-success",
        icon: "e-success toast-icons",
      },
      userRoleDialogData: [],
      isUserRoleDialogOpen: false,
      currentUserRoles: [],
    };
    this.validationRules = { required: true };
    this.actionComplete = this.actionComplete.bind(this);
    this.actionBegin = this.actionBegin.bind(this);
    this.refreshGrid = this.refreshGrid.bind(this);
  }

  async componentDidMount() {
    this._isMounted = true;
    let { data: myData } = await cicassService.getAll(apiUrl);
    let { data: roleData } = await cicassService.getAll(apiUrlRoleCombo);
    if (this._isMounted) {
      this.setState({
        didMountCompleted: true,
        myData,
        roleData,
        isCicass: auth.getUserRole == "SuperUser" ? true : false,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  toolbarClick(args) {
    switch (args.item.properties.id) {
      case "gridcomp_excelexport":
        this.gridInstance.excelExport();
        break;
      default:
        break;
    }
    if (args.item.id === "forceRefresh") {
      this.refreshGrid();
    }
  }

  refreshGrid() {
    cicassService.getAll(apiUrl).then((response) => {
      this.setState({ myData: response.data });
    });
  }

  actionBegin(state) {
    if (state.requestType === "beginEdit" || state.requestType === "add") {
      if (state.requestType === "add") {
        state.data.id = -1;
        state.data.anaAgencyId = auth.getAgencyId();
        state.data.enabled = 1;
        state.data.sendMailComunication = 0;
      }
      this.setState({ visibleOnGrid: true });
    }
  }

  actionComplete(state) {
    if (state.requestType === "add" || state.requestType === "beginEdit") {
      state.dialog.enableResize = true;
      //state.dialog.element.style.maxHeight = 900;
      state.dialog.width = 600;
      state.dialog.height = 600;
      state.dialog.header =
        state.requestType === "beginEdit"
          ? `Dettagli di ${state.rowData.name} ${state.rowData.surname}`
          : "Nuovo utente";
      // console.log(state.rowData);
      this.rowData = Object.assign({}, state.rowData);
    }
    if (state.requestType === "save") {
      const Enabled = "enabled";
      state.data[Enabled] = this.rowData[Enabled] ? 1 : 0;
      state.data["sendMailComunication"] = this.rowData["sendMailComunication"]
        ? 1
        : 0;
    }
    if (state.action === "add") {
      state.data.id = -1;
      auth
        .AuthCreate(state.data)
        .then((response) => {
          if (response.data.result) {
            this.setToastInfo("Success", this.props.t("toastSuccess"));
          } else {
            this.setToastInfo("Error", response.data.message);
          }
          this.refreshGrid();
        })
        .catch((err) => {
          this.setToastInfo("Error", "I: " + err.message);
          this.refreshGrid();
        });
    }
    if (state.action === "edit") {
      auth
        .AuthUpdate(state.data)
        .then((response) => {
          if (response.data.result) {
            this.setToastInfo("Success", this.props.t("toastSuccess"));
          } else {
            this.setToastInfo("Error", response.data.message);
          }
          this.refreshGrid();
        })
        .catch((err) => {
          this.setToastInfo("Error", "U: " + err.message);
          this.refreshGrid();
        });
    }
    if (state.requestType === "delete") {
      auth
        .AuthDelete(state.data[0].id)
        .then((response) => {
          if (response.data.result) {
            this.setToastInfo("Success", this.props.t("toastSuccess"));
          } else {
            this.setToastInfo("Error", response.data.message);
          }
          this.refreshGrid();
        })
        .catch((err) => {
          this.setToastInfo("Error", "D:" + err.message);
          this.refreshGrid();
        });
    }

    this.setState({ visibleOnGrid: false });
  }

  async commandClick(args) {
    if (args.commandColumn.type === "SendMail") {
      auth
        .AuthSendMail(args.rowData.id)
        .then((response) => {
          if (response.data.result) {
            this.refreshGrid();
            this.setToastInfo("Success", this.props.t("toastSuccess"));
          } else {
            this.setToastInfo("Error", response.data.message);
          }
        })
        .catch((err) => {
          this.setToastInfo("Error", "Send Mail: " + err.message);
          this.refreshGrid();
        });
    }
    if (args.commandColumn.type === "Enable/Disable") {
      auth
        .AuthEnable(args.rowData.id)
        .then((response) => {
          if (response.data.result) {
            this.refreshGrid();
            this.setToastInfo("Success", this.props.t("toastSuccess"));
          } else {
            this.setToastInfo("Error", response.data.message);
          }
        })
        .catch((err) => {
          this.setToastInfo("Error", "EN/DI: " + err.message);
          this.refreshGrid();
        });
    }
    if (args.commandColumn.type === "Reset Password") {
      auth
        .AuthResetPassword(args.rowData.id)
        .then(() => {
          this.refreshGrid();
          this.setToastInfo("Success", this.props.t("toastSuccess"));
        })
        .catch((err) => {
          this.setToastInfo("Error", "PWD: " + err.message);
          this.refreshGrid();
        });
    }
    if (args.commandColumn.type === "Roles") {
      const apiEndpointRoles = "user_role";
      let { data } = await cicassService.getAll(
        apiEndpointRoles + "/user/" + args.rowData.id
      );
      // console.log(data);
      this.setState({
        userRoleDialogData: args.rowData,
        isUserRoleDialogOpen: true,
        currentUserRoles: data,
      });
      // console.log(args.rowData);
    }
  }
  setToastInfo(title, errorMsg) {
    let { toastInfo } = this.state;
    switch (title) {
      case "Warning":
        toastInfo.title = "Warning";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-warning";
        toastInfo.icon = "e-warning toast-icons";
        break;
      case "Success":
        toastInfo.title = "Success";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-success";
        toastInfo.icon = "e-success toast-icons";
        break;
      case "Error":
        toastInfo.title = "Error";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-danger";
        toastInfo.icon = "e-error toast-icons";
        break;
      case "Information":
        toastInfo.title = "Information";
        toastInfo.content = errorMsg;
        toastInfo.cssClass = "e-toast-info";
        toastInfo.icon = "e-info toast-icons";
        break;
      default:
        break;
    }
    this.setState({ toastInfo });
    this.toastObj.show();
    return;
  }

  viewTemplateLastLogin(args) {
    try {
      if (args.lastLoginDt == null) return "";
      let lastLoginToPrint =
        args.lastLoginDt.substring(8, 10) +
        "/" +
        args.lastLoginDt.substring(5, 7) +
        "/" +
        args.lastLoginDt.substring(0, 4) +
        " " +
        args.lastLoginDt.substring(11, 19);
      return lastLoginToPrint;
    } catch (err) {
      return "";
    }
  }
  editTemplateSc(args) {
    let isChecked = getValue("enabled", args);
    const { t } = this.props;
    return (
      <div>
        <label htmlFor="checked" style={{ padding: "10px 72px 10px 0" }}>
          {t("enabled")}
        </label>
        <SwitchComponent
          id="checked"
          checked={isChecked}
          change={this.changeFnSc.bind(this)}
        />
      </div>
    );
  }

  changeFnSc(e) {
    if (this.rowData) {
      this.rowData["enabled"] = e.checked;
    }
  }

  templateSc(args) {
    let isChecked = getValue("enabled", args) == 1;
    if (isChecked) return <i className="far fa-check-square" />;
    return <i className="far fa-square" />;
  }

  editTemplateMail(args) {
    let isChecked = getValue("sendMailComunication", args);
    const { t } = this.props;
    return (
      <div>
        <label htmlFor="checkedEmail" style={{ padding: "10px 72px 10px 0" }}>
          {t("sendMailComunication")}
        </label>
        <SwitchComponent
          id="checkedEmail"
          checked={isChecked}
          change={this.changeFnMail.bind(this)}
        />
      </div>
    );
  }

  changeFnMail(e) {
    if (this.rowData) {
      this.rowData["sendMailComunication"] = e.checked;
    }
  }

  templateMail(args) {
    let isChecked = getValue("sendMailComunication", args) == 1;
    if (isChecked) return <i className="far fa-check-square" />;
    return <i className="far fa-square" />;
  }

  renderComplete() {
    let elemArray = document.querySelectorAll(".switch-control label");
    for (let i = 0, len = elemArray.length; i < len; i++) {
      elemArray[i].addEventListener("mouseup", rippleHandler);
      elemArray[i].addEventListener("mousedown", rippleHandler);
    }
    function rippleHandler(e) {
      let rippleSpan = this.nextElementSibling.querySelector(
        ".e-ripple-container"
      );
      if (rippleSpan) {
        rippleMouseHandler(e, rippleSpan);
      }
    }
  }

  render() {
    const { myData, didMountCompleted, roleData, isCicass, toastInfo } =
      this.state;
    const { t } = this.props;
    return (
      <>
        <div style={{ margin: "1%", marginTop: "1%" }}>
          <h2 className="text-gray-800 text-xl font-semibold">
            {t("titleUserJs")}
          </h2>
        </div>
        <div className="p-4 flex-auto">
          {didMountCompleted && (
            <GridComponent
              id="gridcomp"
              ref={(grid) => (this.gridInstance = grid)}
              dataSource={myData}
              toolbar={toolbarOptions}
              editSettings={editSettings}
              allowResizing={true}
              allowFiltering={true}
              filterSettings={filterSettings}
              allowPaging={true}
              pageSettings={{ pageSize: 10, pageCount: 5 }}
              allowExcelExport={true}
              actionComplete={this.actionComplete}
              actionBegin={this.actionBegin}
              toolbarClick={this.toolbarClick.bind(this)}
              locale="it"
              allowSorting={true}
              commandClick={this.commandClick.bind(this)}
            >
              <ColumnsDirective>
                <ColumnDirective
                  field="id"
                  headerText={t("id")}
                  textAlign="Right"
                  isPrimaryKey={true}
                  defaultValue="-1"
                  allowEditing={false}
                  visible={isCicass}
                />
                <ColumnDirective
                  field="name"
                  headerText={t("name")}
                  width="150"
                  validationRules={this.validationRules}
                />
                <ColumnDirective
                  field="surname"
                  headerText={t("surname")}
                  validationRules={this.validationRules}
                  width="130"
                />
                <ColumnDirective
                  field="email"
                  headerText={t("email")}
                  validationRules={this.validationRules}
                />
                <ColumnDirective
                  field="home"
                  headerText={t("homeNr")}
                  defaultValue=""
                  width="100"
                />
                <ColumnDirective
                  field="mobile"
                  headerText={t("mobile")}
                  validationRules={this.validationRules}
                  defaultValue="-"
                  width="120"
                />
                <ColumnDirective
                  field="lastLoginDt"
                  headerText={t("lastLoginDt")}
                  visible={true}
                  allowEditing={false}
                  template={this.viewTemplateLastLogin.bind(this)}
                  width="160"
                />
                <ColumnDirective
                  field="lastUpdatePwdDt"
                  headerText={t("lastUpdatePwdDt")}
                  visible={false}
                  allowEditing={false}
                />
                <ColumnDirective
                  field="authRoleId"
                  headerText={t("authRoleId")}
                  foreignKeyValue="name"
                  foreignKeyField="id"
                  dataSource={roleData}
                  // defaultValue="3"
                  width="100"
                  validationRules={this.validationRules}
                />
                <ColumnDirective
                  field="enabled"
                  headerText={t("enabled")}
                  width="100"
                  editType="numericedit"
                  editTemplate={this.editTemplateSc.bind(this)}
                  template={this.templateSc.bind(this)}
                />
                <ColumnDirective
                  field="sendMailComunication"
                  headerText={t("sendMailComunication")}
                  width="100"
                  editType="numericedit"
                  editTemplate={this.editTemplateMail.bind(this)}
                  template={this.templateMail.bind(this)}
                />
                <ColumnDirective
                  field="createdAt"
                  headerText={t("createdAt")}
                  type="date"
                  format={"dd/MM/yyyy HH:mm:ss"}
                  visible={isCicass}
                  allowEditing={false}
                />
                <ColumnDirective
                  field="updatedAt"
                  headerText={t("updatedAt")}
                  type="date"
                  format={"dd/MM/yyyy HH:mm:ss"}
                  visible={isCicass}
                  allowEditing={false}
                />
                <ColumnDirective
                  field="adminNote"
                  headerText={t("adminNote")}
                  visible={isCicass}
                />
                <ColumnDirective
                  field="viewOrder"
                  headerText={t("viewOrder")}
                  validationRules={this.validationRules}
                  defaultValue="1"
                  visible={isCicass}
                />
                <ColumnDirective
                  field="anaAgencyId"
                  headerText={t("anaAgencyId")}
                  allowEditing={false}
                  visible={isCicass}
                />
                <ColumnDirective
                  headerText={t("operationOnGrid")}
                  width="210"
                  commands={commands}
                />
              </ColumnsDirective>
              <Inject
                services={[
                  Resize,
                  Filter,
                  Page,
                  Toolbar,
                  Edit,
                  ForeignKey,
                  CommandColumn,
                  ExcelExport,
                  Sort,
                ]}
              />
            </GridComponent>
          )}
          <ToastComponent
            ref={(toast) => {
              this.toastObj = toast;
            }}
            id="toast_default"
            position={this.position}
            title={toastInfo.title}
            content={toastInfo.content}
            cssClass={toastInfo.cssClass}
            icon={toastInfo.icon}
          />

          <UserRoleDialog
            dataSource={this.state.userRoleDialogData}
            isOpen={this.state.isUserRoleDialogOpen}
            handleClose={() => this.setState({ isUserRoleDialogOpen: false })}
            translate={this.props.t}
            currentUserRoles={this.state.currentUserRoles}
          />
        </div>
      </>
    );
  }
}
export default withTranslation()(User);
