import React, { useState, useRef, useEffect } from "react";
import {
  GridComponent,
  ColumnDirective,
  ColumnsDirective,
  Page,
  Inject,
  Resize,
  Edit,
  CommandColumn,
  ForeignKey,
  Filter,
  Toolbar,
  ExcelExport,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { ToastComponent } from "@syncfusion/ej2-react-notifications";
import { cicassService } from "../../../../_services";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/system";

const editSettings = {
  allowEditing: true,
  allowAdding: true,
  allowDeleting: true,
  mode: "Dialog",
  dialog: { enableResize: true },
  // showDeleteConfirmDialog: true,
};
const filterSettings = { type: "CheckBox" }; // Menu, Excel
const commands = [
  {
    type: "Edit",
    buttonOption: { iconCss: " e-icons e-edit", cssClass: "e-flat" },
  },
  {
    type: "Delete",
    buttonOption: { iconCss: "e-icons e-delete", cssClass: "e-flat" },
  },
];
const toolbarOptions = [
  "Search",
  "Add",
  "ExcelExport",
  "Print",
  {
    text: "Refresh",
    tooltipText: "Refresh",
    prefixIcon: "e-MT_reload",
    id: "forceRefresh",
  },
];
const apiEndpointAttendanceType = "Ana_attendance_type";

const AnaAttendancePage = props => {
  const { t } = useTranslation();
  const gridRef = useRef(null);
  const toastRef = useRef(null);
  // const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [attendanceTypes, setAttendanceTypes] = useState(null);
  const [toast, setToast] = useState({
    message: "",
    severity: "",
    cssClass: "",
    icon: "",
  });
  
  useEffect(() => {
    fetchData();
  }, []);

  async function fetchData() {
    refreshGrid();
  }

  async function refreshGrid() {
    const attendanceTypes = await cicassService.getAll(
      apiEndpointAttendanceType
    );
    setAttendanceTypes(attendanceTypes.data);
  }

  function toolbarClick(args) {
    switch (args.item.properties.id) {
      case "gridcomp_excelexport":
        gridRef.current.excelExport();
        break;
      default:
        break;
    }
    if (args.item.id === "forceRefresh") {
      refreshGrid();
    }
  }

  async function actionComplete(state) {
    if (state.requestType === "add" || state.requestType === "beginEdit") {
      state.dialog.enableResize = true;
      //state.dialog.element.style.maxHeight = 900;
      state.dialog.width = 600;
      state.dialog.height = 600;
    }
    try {
      if (state.action === "add") {
        const isoDate = new Date().toISOString();
        state.data.id = -1;
        state.data.createdAt = isoDate;
        state.data.updatedAt = isoDate;
        cicassService.saveOp(apiEndpointAttendanceType, state.data);
        showToast("Success");
        refreshGrid();
      }
      if (state.action === "edit") {
        cicassService.saveOp(apiEndpointAttendanceType, state.data);
        showToast("Success");
        refreshGrid();
      }
      if (state.requestType === "delete") {
        
        if (state.data.deletedAt !== undefined) return;
        cicassService.deleteOp(apiEndpointAttendanceType, state.data[0].id);
        showToast("Success");
        refreshGrid();
      }
    } catch (error) {
      showToast("Error", error.message);
      refreshGrid();
    }
    // this.setState({ visibleOnGrid: false });
  }

  function actionBegin(state) {
    if (state.requestType === "add") {
      state.data.id = -1;
    }
    if (state.requestType === "beginEdit") {
      if (state.rowData.deletedAt !== null) state.cancel = true;
    }

    if (state.requestType === "delete") {
      if (state.data[0].deletedAt !== null) state.cancel = true;
    }
  }

  function onRowDataBound(args) {
    if (args.data.deletedAt !== null) {
      args.row
        .querySelectorAll(".e-icons.e-delete")[0]
        .classList.add("e-disabled");
      args.row
        .querySelectorAll(".e-icons.e-edit")[0]
        .classList.add("e-disabled");
    }
  }

  function showToast(title, errorMsg = "") {
    const toast = {};
    switch (title) {
      case "Warning":
        toast.title = "Warning";
        toast.content = errorMsg;
        toast.cssClass = "e-toast-warning";
        toast.icon = "e-warning toast-icons";
        break;
      case "Success":
        toast.title = "Success";
        toast.content = errorMsg;
        toast.cssClass = "e-toast-success";
        toast.icon = "e-success toast-icons";
        break;
      case "Error":
        toast.title = "Error";
        toast.content = errorMsg;
        toast.cssClass = "e-toast-danger";
        toast.icon = "e-error toast-icons";
        break;
      case "Information":
        toast.title = "Information";
        toast.content = errorMsg;
        toast.cssClass = "e-toast-info";
        toast.icon = "e-info toast-icons";
        break;
      default:
        break;
    }
    setToast(toast);
    toastRef.current.show();
  }

  return (
    <Box
      sx={{
        m: 2,
      }}>
        <div style={{ margin: "1%", marginTop: "1%" }}>
					<h2 className="text-gray-800 text-xl font-semibold">
						{t("titleAnaAttendancePageJs")}
					</h2>
				</div>
      {attendanceTypes && (
        <GridComponent
          id={"gridcomp"}
          ref={g => (gridRef.current = g)}
          dataSource={attendanceTypes}
          toolbar={toolbarOptions}
          allowResizing={true}
          allowFiltering={true}
          filterSettings={filterSettings}
          allowSorting={true}
          pageSettings={{ pageSize: 30 }}
          allowExcelExport={true}
          locale={localStorage.getItem("weblang")}
          actionComplete={e => actionComplete(e, props)}
          actionBegin={actionBegin}
          editSettings={editSettings}
          // commandClick={e => commandClick(e)}
          toolbarClick={e => toolbarClick(e)}
          rowDataBound={e => onRowDataBound(e)}>
          <ColumnsDirective>
            {/* Add id and association for admin */}
            <ColumnDirective
              field="name"
              headerText={t("name")}
              validationRules={{
                required: [true, "Questo campo è obbligatorio"],
              }}
            />
            <ColumnDirective
                  field="viewOrder"
                  headerText={t("viewOrder")}
                  width="50"
                  validationRules={{
                    required: [true, "Questo campo è obbligatorio"],
                  }}
                />
            <ColumnDirective
              field="createdAt"
              type="date"
              format={"dd/MM/yyyy"}
              headerText={t("createdAt")}
              allowEditing={false}
              width="90"
            />
            <ColumnDirective
              field="updatedAt"
              type="date"
              format={"dd/MM/yyyy"}
              headerText={t("updatedAt")}
              allowEditing={false}
              width="90"
            />
            <ColumnDirective
              field="deletedAt"
              type="date"
              format={"dd/MM/yyyy"}
              headerText={t("deletedAt")}
              allowEditing={false}
              width="90"
            />
            <ColumnDirective
              headerText={t("operationOnGrid")}
              width="160"
              textAlign="left"
              commands={commands}
            />
          </ColumnsDirective>
          <Inject
            services={[
              Resize,
              Sort,
              Filter,
              Page,
              Toolbar,
              Edit,
              CommandColumn,
              ForeignKey,
              ExcelExport,
            ]}
          />
        </GridComponent>
      )}
      <ToastComponent
        id="toast_default"
        ref={toastRef}
        position={{ X: "Right", Y: "Bottom" }}
        title={toast.title}
        content={toast.content}
        cssClass={toast.cssClass}
        icon={toast.icon}
      />
    </Box>
  );
};

export default AnaAttendancePage;
